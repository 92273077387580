import React from 'react';
import { Button, Group, Select, Stack, Grid, Tooltip, TextInput } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { IconDownload } from '@tabler/icons-react';
import { IBulkImport } from '../../../models/Ticket';
import useStyles from '../TicketExcelImportStyles';
import DropOrUpload from '../DropUploadZone';
import MenuItem from './MenuItem';

const BulkUpdate = ({ importModel }: { importModel: IBulkImport }) => {
  const classes = useStyles();
  const [selectedProcessDefID, setSelectedProcessDefID] = React.useState('');
  const [selectedTaskKey, setSelectedTaskKey] = React.useState('');
  const [customFileName, setCustomFileName] = React.useState('');
  const isDisabled = !selectedProcessDefID || !selectedTaskKey

  React.useEffect(() => {
    importModel.setAllTicketTypes();
  }, [])

  const handleDownloadTemplate = () => {
    const ticketTypeId = selectedProcessDefID || '';
    const fileName = customFileName || `${importModel.allTicketTypes.find(tt => tt.id === selectedProcessDefID)?.name.replace(/\s+/g, "_")}_${importModel.getTasksForDefinition(selectedProcessDefID)?.find(task => task.taskKey === selectedTaskKey)?.taskName.replace(/\s+/g, "_")}.xlsx`
    const taskIds = importModel.getTasksForDefinition(selectedProcessDefID)?.find(task => task.taskKey === selectedTaskKey)?.canUpdateTasks.join(',') || ''
    importModel.downloadTemplateWithTicketType(ticketTypeId, fileName, taskIds);
  }

  return (
    <Stack align="center"
      //  mx="1rem" 
      w="100%" spacing="xs" p=".5rem">
      <Grid w="100%" mx="2rem" align="flex-start">
        <Grid.Col span={12} style={{ marginBottom: '1em' }}>
          <Group className={classes.ticketTypeGroup}>
            <Select
              label="Request Type"
              data={importModel.allTicketTypes.map((tT: any) => ({ value: tT.id, label: tT.name }))}
              value={selectedProcessDefID}
              onChange={(val) => { if (val) { setSelectedProcessDefID(val); setSelectedTaskKey(''); } }}
              placeholder={'Choose a request type'}
              className={classes.dropdown}
            />
            <Select
              label="Tasks"
              data={importModel.getTasksForDefinition(selectedProcessDefID)?.map(tasks => ({ value: tasks.taskKey, label: tasks.taskName, caption: tasks.canUpdateTasks.length === 0 ? `You're not the owner to update this task` : `You can update ${tasks.canUpdateTasks.length} tasks`, disabled: tasks.canUpdateTasks.length === 0 })) || []}
              value={selectedTaskKey}
              itemComponent={MenuItem}
              onChange={(val) => { if (val) { setSelectedTaskKey(val); } }}
              placeholder={'Choose a task'}
              className={classes.dropdown}
              disabled={!selectedProcessDefID}
            />
          </Group>
          <Group className={classes.ticketTypeGroup} style={{ justifyContent: 'flex-start' }}>
            <TextInput
              label={"Filename (optional)"}
              placeholder={(selectedProcessDefID && selectedTaskKey) ? `${importModel.allTicketTypes.find(tt => tt.id === selectedProcessDefID)?.name.replace(/\s+/g, "_")}_${importModel.getTasksForDefinition(selectedProcessDefID)?.find(task => task.taskKey === selectedTaskKey)?.taskName.replace(/\s+/g, "_")}.xlsx` : 'Enter file name here'}
              value={customFileName}
              onChange={(event) => {
                let inputValue = event.target.value
                  .replace(/\s+/g, "_") // Replace spaces with underscores
                  .replace(/[^a-zA-Z0-9_-]/g, ""); // Allow only letters, numbers, "_" and "-"
                setCustomFileName(inputValue);
              }}
              onBlur={() => {
                if (!customFileName.endsWith(".xlsx")) {
                  setCustomFileName((prev) => prev + ".xlsx");
                }
              }}
              style={{ width: '50%' }}
              disabled={!selectedProcessDefID || !selectedTaskKey}
              sx={{
                ["& .mantine-InputWrapper-wrapper"]: { width: '100%' },
                ["& .mantine-TextInput-root"]: { width: '100%' },
                ["& .mantine-TextInput-label"]: { fontSize: '0.8525em', color: '#585858', fontWeight: 600, opacity: 0.8 },
                ["& .mantine-TextInput-input"]: { marginTop: '0.5em', marginBottom: '0.5em', border: 0, padding: 0, borderBottom: '0.0525em solid #005eff', borderRadius: 0, fontSize: "1em", '&[data-disabled]': { backgroundColor: '#FFF', border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed', borderBottom: '0.0525em solid #c9c9c9' }, '&[data-with-icon]': { paddingLeft: 0 } },
                ["& .mantine-TextInput-icon"]: { justifyContent: 'center' }
              }}
            />
            <Tooltip label="Download Template File (Excel)">
              <Button className={classes.dropdownButton} disabled={isDisabled} onClick={handleDownloadTemplate} style={{ width: '25%', flexGrow: 0 }}>
                <span className={classes.downloadText}>Template</span>
                <IconDownload size="1.5em" />
              </Button>
            </Tooltip>
          </Group>
        </Grid.Col>
      </Grid>
      <Grid w="100%" mx="2rem" align="flex-start" mt={'1em'}>
        <Grid.Col span={6} >
          <Group>
            <DropOrUpload
              label="Upload Excel Here"
              model={importModel}
              caption={'Ensure fields are filled as per guidelines'}
              disabled={false}
              actionType={"update"}
            />
          </Group>
        </Grid.Col>
      </Grid>
      <Grid w="100%" mx="2rem" align="flex-start" mt={'1em'}>
        <Grid.Col span={12} >
          <div style={{ height: 210 }}></div>
        </Grid.Col>
      </Grid>
    </Stack>
  );

};

export default observer(BulkUpdate);

