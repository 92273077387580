import { Grid, Tooltip, Text } from '@mantine/core';
import { IconAlertHexagonFilled, IconDownload, IconReload } from '@tabler/icons-react';
import React from 'react'
import { IBulkImportRequest } from '../../models/Ticket';
import { observer } from 'mobx-react-lite';
import { BulkRequestStatuses } from '../../models/enums';


const BulkImportRequestHistoryRow = ({ request }: { request: IBulkImportRequest }) => {
    return (
    <Grid justify="space-around" mx={0} >
      <Grid.Col sx={{ borderLeft: "0.0525em solid #585858", borderBottom: "0.0525em solid #585858", padding: 10, alignSelf: 'end', fontSize: '0.85em' }} span={2}><Tooltip label={request.requestId}><Text truncate>{request.requestId}</Text></Tooltip></Grid.Col>
      <Grid.Col sx={{ borderLeft: "0.0525em solid #585858", borderBottom: "0.0525em solid #585858", padding: 10 , alignSelf: 'end', fontSize: '0.85em'}} span={4} > {request.ticketType}</Grid.Col >
      <Grid.Col sx={{ borderLeft: "0.0525em solid #585858", borderBottom: "0.0525em solid #585858", padding: 10 , alignSelf: 'end', fontSize: '0.85em'}} span={4} > {request.statusText}</Grid.Col >
      <Grid.Col sx={{ borderLeft: '0.0525em solid #585858', borderBottom: "0.0525em solid #585858", borderRight: "0.0525em solid #585858", justifyItems: "center",alignSelf: 'end' }} span={2} >
        <button 
        style={{
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          cursor: request.disabled ? 'default' : 'pointer',
          color: (request.disabled) ? '#585858' : request.status === BulkRequestStatuses.PENDING ? 'orange'  : '#005eff',
          border: 0,
          background: 'transparent',
  
        }} 
        disabled={request.disabled} 
        onClick={() => { request.download(); }}>
        <Tooltip label={request.disabled ? `Failed` : request.status === BulkRequestStatuses.PENDING ? `Click to Check progress` : `Click to Download`}>
        <span style={{ fontSize: '0.85em', marginRight: '0.5em'}}>{ request.disabled ? `Failed` : request.status === BulkRequestStatuses.PENDING ? `Check progress` : `Download`}</span>
        </Tooltip>
        {request.disabled ? <IconAlertHexagonFilled size="1em" /> : request.status === BulkRequestStatuses.PENDING ? <IconReload size="1em" /> : <IconDownload size="1em" />}
        </button>
      </Grid.Col >
    </Grid >
  )};

  export default observer(BulkImportRequestHistoryRow);