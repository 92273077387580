import { Avatar } from '@mantine/core';
import { useClipboard } from '@mantine/hooks';
import { IconClock } from '@tabler/icons-react';
import { IChan } from 'csp-with-ts';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { FunctionComponent, memo, MemoExoticComponent, useEffect, useState } from 'react';
import Copy from '../../../../components/CopyButton';
import FormDropdown, { IFormDropdownInputProps } from '../../../../components/filter/FormDropdown';
import DateTimeInput, { IDateTimeInputProps } from '../../../../components/FormInputs/DateTimeInput';
import GenericInput, { IGenericInputProps } from '../../../../components/FormInputs/GenericInput';
import { IImagePickerProps } from '../../../../components/FormInputs/ImagePicker';
import MantineImageField from '../../../../components/FormInputs/MantineImageField';
import { IMultiSelectDropdownInputProps } from '../../../../components/FormInputs/MultiSelectDropdownInput';
import { ICompletedTask, IFormField, ITicket } from '../../../../models/Ticket';
import { transformCopy, reverseTransformCompleted as reverseTransform, transformMap } from '../../../../utils/constants';
import { transformDateTime, transformNumeric, transformPercent, transformTime } from '../../../../utils/utils';
import NotCurrentAssignee from '../../others/notCurrentAssignee/NotCurrentAssignee';
import useStyles from '../tasks/TasksStyles';
import { useStore } from '../../../../models/ProvideModel';
import { IExpressionInputProps } from '../../../../components/FormInputs/ExpressionInput';


export interface ITicketCompletedTasks {
  customerId: string;
  userId: string;
  ticket: ITicket;
  projectId: string;
  refreshTicket: (id: string) => Promise<any>;
  lg?: boolean;
  onClose?: () => void;
  taskIdValue?: string;
  coreFields: { [K: string]: any; };
  ch: IChan;
  activeTab: string | null;
};

const initializer = (fields: IFormField[]) => ({ isChanged: false, ...fields.reduce((acc, { id, value, placeholder, type, options }) => ({ ...acc, [id]: (type === "multi-line-text" || type === "text") && (!value || !value.length) && placeholder ? placeholder : reverseTransform[type] ? reverseTransform[type](value || "") : value || "" }), {}) });

const TicketCompletedTasks = ({ activeTab, userId, customerId, ticket, lg, onClose, projectId, coreFields, ch, refreshTicket, taskIdValue }: ITicketCompletedTasks) => {
  useEffect(() => {
    ticket.getCompletedTasks();
  }, []);

  useEffect(() => {
    if (activeTab == "completed") {
      const compTask = ticket.completedTasks.find(({ assignee }) => {
        return assignee.includes(userId);
      }) || ticket.completedTasks[0];
      if (compTask) {
        ticket.setCurrentCompletedTask(compTask.taskId);
      }
    }
  }, [activeTab])

  const { copy, copied } = useClipboard({ timeout: 500 });
  const [lastCopied, setLastCopied] = useState<string>("");

  const classes = useStyles({ isLg: !!lg });
  const store = useStore();

  return (
    <div className={classes.mainDiv} >
      <div className={classes.handleContainer} style={{ overflow: 'overlay' }}>
        {ticket?.completedTasks.length ? <div className={classes.taskListDiv}>
          <div className={classes.main}>
            {ticket?.completedTasks.map((task: ICompletedTask) =>
              <div key={task.taskId} onClick={() => ticket.setCurrentCompletedTask(task.taskId)} className={classes.taskListItem} style={{ border: task.taskId == taskIdValue ? '0.01em solid #D0EBFF' : 'none' }}>
                {task.taskId == ticket.currentCompletedTask?.taskId ? <div style={{ width: '0.4em', margin: '-0.3em 0em -0.25em -0.3em', background: '#585858' }}></div> : null}
                <div style={{ padding: '0.2em', display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'flex-start', maxWidth: '33.33%' }} >
                  <span style={{ fontWeight: task.taskId == ticket.currentCompletedTask?.taskId ? 600 : 400, textTransform: "capitalize", marginLeft: '1em' }}>{task.taskName}</span>
                </div>
                <div style={{ padding: '0.2em', display: 'flex', flex: 1, justifyContent: 'flex-start', maxWidth: '33.33%' }}>
                  <Avatar color="cyan" radius="xl" size={"xs"} sx={{ opacity: 0.8, marginRight: '0.5em' }} />
                  <span style={{ fontWeight: task.taskId == ticket.currentCompletedTask?.taskId ? 600 : 400, marginRight: '0.5em' }}>{ticket.processVariables.usersMap[task.assignee && task.assignee[0] || ""]}</span>
                </div>
                <span style={{ fontWeight: task.taskId == ticket.currentCompletedTask?.taskId ? 600 : 400, display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'flex-end', textTransform: "capitalize", marginRight: '1em', maxWidth: '33.33%' }}>
                  <IconClock color="#585858" style={{ width: '1.2em', height: '1.2em', marginRight: '0.5em' }} />
                  {moment(task.taskEndDate || "").format('DD-MMM-YYYY HH:mm A')}
                </span>
              </div>
            )}
          </div>
        </div>

          : null}
        <div className={classes.handleScrollLayer}>
          {ticket.completedTaskFormFields?.length ?
            ticket.completedTaskFormFields?.map((fF) => {
              const { id, name, readOnly, required, type, options, value, placeholder, params, } = fF;
              const tM = transformMap(type);
              if (type === "dropdown" || type === "radio-buttons" || type === "multiselect") {
                const Component: MemoExoticComponent<FunctionComponent<IFormDropdownInputProps | IMultiSelectDropdownInputProps>> = tM.Component;
                return (
                  <div key={id} style={{ display: "flex", width: "100%", flexDirection: "row", alignItems: "flex-end", }}>
                    <div className={classes.fieldData}>
                      <Component
                        field={id}
                        dispatch={() => { }}
                        state={value}
                        label={name}
                        options={options.slice() || []}
                        disabled={true}
                        required={!!required && !readOnly}
                        size='xs'
                        icon={coreFields[id] && coreFields[id].icon || null}
                      />
                    </div>
                    <Copy copy={() => { copy(transformCopy[type][options.find(({ id: idd }) => idd === value)?.name || value]); setLastCopied(() => id); }} copied={copied && (lastCopied === id)} />
                  </div>
                );
              } else if (type === "upload") {
                const Component: MemoExoticComponent<FunctionComponent<IImagePickerProps>> = tM.Component;
                return (
                  <div key={`${id}_${name}`} style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "flex-end", }}>
                    <div className={classes.fieldData}>
                      <Component
                        field={id}
                        dispatch={() => { }}
                        state={value}
                        type={type}
                        label={name}
                        disabled={!!readOnly || (ticket.currentTask?.assignee[0] !== userId)}
                        required={!!required && !readOnly}
                        params={params}
                        // onFileChange={handleFileChange}
                        usersMap={store.ticket.currentTicket?.processVariables.usersMap || {}}
                      />
                    </div>
                    {/* {!!tM.disableCopy ? null : <Copy copy={() => { copy(transformCopy[type][state[id]]); setLastCopied(() => id); }} copied={copied && (lastCopied === id)} />} */}
                  </div>
                );
              } else if (type === "expression") {
                const Component: MemoExoticComponent<FunctionComponent<IExpressionInputProps>> = tM.Component;
                /* const fieldVal = isLiveExpression ? ticket.processVariables[fieldVariable || ""] : value; */

                const fieldVal = fF.isLiveExpression && fF.calculate ? fF.calculate(ticket.processVariables || {}) : value;
                return (
                  <div key={`${id}_${name}`} style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "flex-end", }}>
                    <div className={classes.fieldData}>
                      <Component
                        field={id}
                        dispatch={() => { }}
                        state={fieldVal}
                        type={fF.isLiveExpression ? "live" : type}
                        label={name}
                      />
                    </div>
                    {!!tM.disableCopy ? null : !fF.isLiveExpression ? null : <Copy copy={() => { copy(transformCopy[type][fieldVal]); setLastCopied(() => id); }} copied={copied && (lastCopied === id)} />}
                  </div>
                );

              }
              else {
                const Component: MemoExoticComponent<FunctionComponent<IGenericInputProps | IImagePickerProps | IDateTimeInputProps>> = tM.Component;
                const val = (type === "multi-line-text" || type === "text") && (!value || !value.length) && placeholder ? placeholder : reverseTransform[type] ? reverseTransform[type](value || "") : value || "";
                return (
                  <div key={`${id}_${name}`} style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "flex-end", }}>
                    <div className={classes.fieldData}>
                      <Component
                        field={id}
                        dispatch={() => { }}
                        state={val}
                        // type={type === "datetime" ? "datetime-local" : type}
                        type={type}
                        label={name}
                        disabled={true}
                        required={!!required && !readOnly}
                        isDueDate={false}
                        size='xs'
                        icon={coreFields[id] && coreFields[id].icon || null}
                        params={params}
                      />
                    </div>
                    {!!tM.disableCopy ? null : <Copy copy={() => { copy(transformCopy[type][val]); setLastCopied(() => id); }} copied={copied && (lastCopied === id)} />}
                  </div>
                );
              }
            })
            : (!ticket.completedTasks.length || (ticket.currentCompletedTask && !ticket.currentCompletedTask?.assignee.includes(userId))) ?
              <NotCurrentAssignee
                taskName={ticket.currentCompletedTask?.taskName || ""}
                userName={ticket.processVariables.usersMap[ticket.currentCompletedTask?.assignee[0] || ""]}
                completedTasksLength={ticket.completedTasks.length > 0}
                completedTask /> : null
          }
        </div>
      </div>
    </div>
  );
};
export default observer(TicketCompletedTasks);
