import React, { memo } from 'react';
import { Button, Popover as MantinePopover, Tooltip, Checkbox, Indicator, CloseButton, Tabs } from '@mantine/core';
import { Paper, Chip } from '@material-ui/core';
import { IconAdjustmentsAlt, IconRefresh } from '@tabler/icons-react';
import { useHistory, useLocation } from 'react-router';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../models/ProvideModel';
import useStyles from './RequestsModuleStyles';
import ProjectSelect from '../../components/projectSelectDD/ProjectSelect';

interface ISpecialFilterButton {
  push(val: any): void;
  location: any;
  id: string;
  label: string;
  search: string;
  isActive: boolean;
  isAlwaysActive: boolean;
};

const SpecialFilterButton = memo(({ push, location, id, label, search, isActive, isAlwaysActive, }: ISpecialFilterButton) => {
  const onClick = () => {
    if (isAlwaysActive) { return; }
    const loc = new URLSearchParams(search);
    if (isActive) {
      loc.delete(id);
    }
    else {
      loc.append(id, "true")
    }
    push({ ...location, search: loc.toString() });
  };
  return (
    // <Button
    //   className={isActive ? classes.sFDisabled : classes.sFApplied}
    //   onClick={onClick}
    // >
    //   {label}
    // </Button>
    <Checkbox
      label={label}
      onChange={onClick}
      checked={isActive}
      radius={'xs'}
      sx={{ ["& .mantine-Checkbox-label"]: { cursor: 'pointer', fontSize: '0.8525em', color: isAlwaysActive ? "#585858" : isActive ? "#005eff" : "#585858", '&:hover': { color: "#005eff" } }, ["& .mantine-Checkbox-input"]: { cursor: 'pointer' } }}
    />
  );
});

const RequestsModuleToolbar = () => {
  const classes = useStyles();
  const store = useStore();
  const [hovered, setHovered] = React.useState(false);
  const { search } = useLocation();
  const { location, push } = useHistory();
  const currentFVProjectsList = store.ticket.filteredViewsMap.get(store.ticket.currentView)?.projectSelection
  const marshalledProjectList = () => {
    const viewProjects = store.ticket.filteredViewsMap.get(store.ticket.currentView)?.projectsOptions?.map(project => project.id) || [];
    return store.projectInfo.projects.filter(proj => proj.id !== store.projectInfo.currentProject?.id).map(project => ({ id: project.id, name: project.name + `${!viewProjects.includes(project.id) ? '(Not available)' : ''}`, valid: (viewProjects.includes(project.id)) })) || [];
  }
  const handleMouseEnter = () => {
    setHovered(true);
  };
  const handleMouseLeave = () => {
    setHovered(false);
  };

  const removeAppliedSF = (filterID, isActive) => {
    const loc = new URLSearchParams(search);
    if (isActive) {
      loc.delete(filterID);
    }
    else {
      loc.append(filterID, "true")
    }
    push({ ...location, search: loc.toString() });
  }
  const newForm = (val: string) => {
    // You might need to revert to url->MST pattern
    store.ticket.setCurrentTicketDefinition(val);
  };
  return (
    <div style={{ display: 'flex', gap: '8px', flexGrow: 9, flexDirection: "column" }} >
      <Tabs
        value={store.ticket.currentView}
        onTabChange={value => { store.ticket.setCurrentView(value); }}
      >
        <Tabs.List>
          {store.ticket.filteredViews.map((fV: any) => (<Tabs.Tab key={fV.id} value={fV.id}>{fV.name}</Tabs.Tab>))}
        </Tabs.List>
      </Tabs>
      <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1, marginRight: '1em' }}>
        <div style={{ display: 'flex', flexGrow: 2 }}>
          <span style={{
            fontSize: "1.7em",
            display: "flex",
            justifyContent: "flex-start",
            fontWeight: 700,
            color: "#2F3E6C",
            // flexGrow: 2,
          }}>{store.ticket.moduleTitle} &nbsp; <span style={{ fontSize: '0.7em', color: '#C9C9C9', alignSelf: 'center' }}>{`(${store.ticket.filteredRowsCount})`}</span></span>

          <Tooltip label={"Refresh tickets"}>
            <IconRefresh className={classes.refreshTasks} onClick={() => store.ticket.getTicketsList()} />
          </Tooltip>

          {(store.ticket.startableTicketTypes.length) ?
            <MantinePopover position="bottom" withArrow shadow="md">
              <MantinePopover.Target>
                <Button
                  className={(store.ticket.loading) ? classes.applyDisabled : classes.newRequestButton}
                  disabled={store.ticket.loading}
                  style={{ borderRadius: 0 }}
                >New Request</Button>
              </MantinePopover.Target>
              <MantinePopover.Dropdown>
                <div>
                  {store.ticket.startableTicketTypes.map((def) => (
                    <div key={def.typeId} >
                      <Paper
                        elevation={3}
                        className={`${classes.paper} ${hovered ? classes.paperHovered : ''}`} onClick={() => { newForm(def.typeId); }} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                        {def.title}
                      </Paper>
                    </div>)
                  )}
                </div>
              </MantinePopover.Dropdown>
            </MantinePopover>
            : null}
          {(store.ticket.startableTicketTypes.length) ?
           <Button
            className={(store.ticket.loading) ? classes.applyDisabled : classes.newRequestButton}
            disabled={store.ticket.loading}
            style={{ borderRadius: 0, marginLeft: "2em" }}
            onClick={() => {store.ticket.bulkImport.setOpen(true);} }
          >Bulk Import</Button> 
          : null}
          { currentFVProjectsList ?  <ProjectSelect options={marshalledProjectList()} label={currentFVProjectsList.label} /> : null }
        </div>
        {/* label={label} */}
        <div style={{ display: 'flex', alignItems: 'center' }}>{store.ticket.activeSpecialFilters.map(({ id, label, isActive, isAlwaysActive }) =>
          isAlwaysActive ?
            <Chip className={classes.sFChip} label={label} key={id} variant="outlined" />
            :
            <Chip className={classes.sFChip} label={label} key={id} variant="outlined" onDelete={() => removeAppliedSF(id, isActive)} deleteIcon={<CloseButton radius={'xl'} size={'xs'} variant='subtle' />} />)
        }
        </div>
        <MantinePopover position="bottom" withArrow shadow="md">
          <MantinePopover.Target>
            <Tooltip label={"Special filters"}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Indicator withBorder inline size={10} disabled={store.ticket.activeSpecialFilters.length ? false : true} color='#005eff' >
                  <IconAdjustmentsAlt className={classes.specialFilter} />
                </Indicator>
              </div>
            </Tooltip>
          </MantinePopover.Target>
          <MantinePopover.Dropdown>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <span style={{ padding: '0.6em', fontSize: '0.8525em', fontWeight: 600, color: "#585858", opacity: 0.7 }}>Filter by </span>
              {store.ticket.allSpecialFilters.map(({ id, isActive, label, isAlwaysActive }) => (
                <div style={{ padding: '0.6em' }} key={id}>
                  <SpecialFilterButton
                    key={id}
                    id={id}
                    push={push}
                    location={location}
                    search={search}
                    isActive={isActive}
                    label={label}
                    isAlwaysActive={isAlwaysActive}
                  />
                </div>
              ))}
            </div>
          </MantinePopover.Dropdown>
        </MantinePopover>
      </div>
    </div >
  );
};

export default observer(RequestsModuleToolbar);
