import { MRT_ColumnDef } from 'mantine-react-table';
import { reaction } from 'mobx';
import { getRoot, Instance, resolveIdentifier, SnapshotIn, types, flow, applySnapshot, getSnapshot, getPath, applyAction, getPathParts, onPatch, resolvePath, ISerializedActionCall, getParent, IAnyStateTreeNode, isStateTreeNode, getType } from 'mobx-state-tree';
import moment from 'moment';
import { compose, toObjectGroupBy, transduce, map, flatten, toSet, toObject, filter } from 'transducist';
import { getDropdownOption, getGeneralForm, getHistory, getStartForm, getTaskDetailByTaskId, getTickets, listCommentsForProcess, updateAssignee, updateCoreFields, getCompletedTasksByTicket, renameTag, addTag, removeTag, listTags, updateWatcherForTicket, checkPermission, deleteTicket, listPermissionedResources, claimOrUnclaimTask, downloadRequestTemplate, importRequestTickets, downloadBulkUploadStatusFile, getProcessDefinitionsByProject, downloadRequestTaskForms, importRequestTaskForms } from '../api/transactionServer';
import NewDateCell from '../components/requests/cells/NewDateCell';
import RichTextCell from '../components/requests/cells/RichText';
import CustomHeader from '../components/requests/cells/CustomHeader';
import File from '../components/requests/cells/File';
import { defaultColumns, RequestTicketDetails } from '../pages/requestsModulePage/gridColumns';
import { GET_TICKET_MODULE_TITLE } from '../utils/queries';
import { comparePrimitiveArrays, evaluateExpression, extractOptionExpressionName, interleave, sortingStrategyPosition } from '../utils/utils';
import { LateStoreModel } from './DataStore';
import { BulkRequestStatuses, ClaimActions, FeatureGranularity, getPrefixForResourceType, PermissionResources, TicketStatusCategories } from './enums';
import { INotification, NotificationState } from './Notifications';

const __stubSpecialFilterParser = ({ param, condition, field }) => ["assignedToMe"];
const __completedKey = "completionForm";
const __ongoingKey = "generalForm";

const __ignorePredicate = /\$\{[^\{\}]+\}/g;
const __matchPredicate = /type:\s*live/g;
const __matchVariable = /variable:\s*([a-zA-Z]+)/;
const __matchExpression = /expr:\s*([^;]+);?/;
const __matchLabel = /label:\s*([^;]+);?/;
const isSpecialExpr = (expr: string) => !expr.match(__ignorePredicate)?.length && !!expr.match(__matchPredicate)?.length;
const __sanitizeExpr = (expr: string) => expr.replace(/[^a-zA-Z]/g, "");
const __mapLiveExprToField = (inp: { [K: string]: any; }) => ({
    ...inp,
    id: inp.expression.match(__matchVariable) ? inp.expression.match(__matchVariable)[1] : __sanitizeExpr(inp.id),
    name: inp.expression.match(__matchLabel) ? inp.expression.match(__matchLabel)[1] : inp.name,
    isLiveExpression: isSpecialExpr(inp.expression),
});


export interface IFeature {
    feature: string;
    pathParam: string;
    configKeys: string[];
    granularity: FeatureGranularity;
};
// export interface IUser {
//     id: string;
//     name: string;
//     email: string;
//     title: string;
// };
export interface IHistory {
    ticketId: string;
    taskId?: string | null;
    source: string | null;
    payload?: { [K: string]: any; } | null;
    createdAt: string;
    eventName: string;
    eventCategory: string;
};

export interface IUploadFile {
    fileId: string;
    file_name: string;
    version: string;
    created_at: string;
};

export interface ICommentDetail {
    id: string;
    taskId: string;
    processInstanceId: string;
    userId: string;
    commentType?: string;
    commentMessage: string;
    createdAt: Date;
    attachments?: IUploadFile[];
};

// export const History = types.model({
//     ticketId: types.string(),
//     taskId: types.maybeNull(types.string()),
//     source: types.maybeNull(types.string());
//     payload: types.maybeNull(types.frozen<{ [K: string]: any; }>());
//     createdAt: types.string();
//     eventName: types.string();
//     eventCategory: types.string();
// });

export const User = types.model({
    id: types.string,
    name: types.string,
    email: types.optional(types.string, ""),
    title: types.maybeNull(types.string),
});

export const FormField = types.model({
    id: types.identifier,
    name: types.string,
    fieldType: types.string,
    layout: types.maybeNull(types.frozen()),
    overrideId: types.boolean,
    placeholder: types.maybeNull(types.string),
    readOnly: types.boolean,
    required: types.boolean,
    //Dropdown options;
    hasEmptyValue: types.maybeNull(types.boolean),
    optionType: types.maybeNull(types.frozen()),
    options: types.array(types.frozen<{ id: string; name?: string; }>()),
    optionsExpression: types.maybeNull(types.string),
    // TODO: Make type a union type of strings?
    type: types.string,
    value: types.maybeNull(types.frozen<any>(null)),
    params: types.maybeNull(types.frozen<{ [K: string]: any }>()),
    expression: types.maybeNull(types.string),
    isLiveExpression: false,
}).views(self => ({
    get isSpecialPlaceHolder() {
        if (!(self.placeholder && (self.type === "dropdown" || self.type === "multiselect" || self.type === "radio-buttons"))) {
            return false;
        }
        return extractOptionExpressionName(self.placeholder);
    },
    /*
      get isLiveExpression() {
      return (self.type === "expression" && self.expression?.length) ? isSpecialExpr(self.expression) : false;
      },
    */
}))
    /*
      .views(self => ({
      get fieldVariable() {
      if (self.isLiveExpression) {
      const variable = self.expression?.match(__matchVariable);
      if (variable && variable[1]) { return variable[1]; }
      console.error("Malformed live expression. Variable not defined or defined improperly");
      return null;
      }
      return null;
      },
      get liveExpression() {
      if (self.isLiveExpression) {
      const variable = self.expression?.match(__matchExpression);
      if (variable && variable[1]) { return variable[1]; }
      console.error("Malformed live expression. Expression not defined or defined improperly");
      return null;
      }
      return null;
      },
      get liveLabel() {
      if (self.isLiveExpression) {
      const variable = self.expression?.match(__matchLabel);
      if (variable && variable[1]) { return variable[1]; }
      console.error("Malformed live expression. Label not defined or defined improperly");
      return null;
      }
      return null;
      },
      }))
    */
    .views(self => ({
        get calculate() {
            if (self.isLiveExpression) {
                // return evaluateExpression(self.liveExpression.replace("+", "/"));
                try {
                    const liveExpression = self.expression!.match(__matchExpression) ? self.expression!.match(__matchExpression)![1] : "";
                    return evaluateExpression(liveExpression);
                }
                catch (err) { console.error(err); return () => 0; }
            }
            return null;
        },
    })).actions(self => ({
        setValue(val: any) {
            if (val !== self.value) {
                self.value = val;
            }
        },
        setOptions(options: { id: string; name?: string; }[]) {
            if (self.isSpecialPlaceHolder) {
                self.options.clear();
                if (!(getRoot(self) as Instance<typeof LateStoreModel>).ticket.dynamicDropdowns.has(self.isSpecialPlaceHolder)) {
                    (getRoot(self) as Instance<typeof LateStoreModel>).ticket.addDynamicDropdown(self.isSpecialPlaceHolder, options);
                }
                self.options.push(...options);
            }
        },
    })).actions(self => ({
        afterAttach() {
            if (self.type === "multiselect" && !Array.isArray(self.value)) {
                self.setValue([]);
            }
            if (self.isSpecialPlaceHolder) {
                if ((getRoot(self) as Instance<typeof LateStoreModel>).ticket.dynamicDropdowns.has(self.isSpecialPlaceHolder)) {
                    self.setOptions((getRoot(self) as Instance<typeof LateStoreModel>).ticket.dynamicDropdowns.get(self.isSpecialPlaceHolder).options);
                }
                else {
                    getDropdownOption({ customerId: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId, variableType: self.isSpecialPlaceHolder, projectId: (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo.currentProject.id, })
                        .then(({ data: { data } }) => {
                            self.setOptions(data);
                        })
                        .catch(err => { console.error(err); })
                }
            }
        }
    }));

export const Task = types.model({
    taskId: types.identifier,
    taskName: types.string,
    formKey: types.string,
    assignee: types.array(types.string),
    taskDefinitionKey: types.string,
    fields: types.array(FormField),
    isChanged: false,
    taskCandidateUsers: types.array(types.string),
}).views(self => ({
    get isMultiUser() {
        return self.taskCandidateUsers.length > 1
    },
})).views(self => ({
    get isClaimable() {
        return self.isMultiUser && (self.assignee.length === self.taskCandidateUsers.length) && self.taskCandidateUsers.includes((getRoot(self) as Instance<typeof LateStoreModel>).auth.userId);
    },
    get isUnclaimable() {
        return self.isMultiUser && (self.assignee.length === 1) && self.assignee.includes((getRoot(self) as Instance<typeof LateStoreModel>).auth.userId);
    },
    get parentTicketId(): string {
        const directParent = getParent<IAnyStateTreeNode>(self, 1);
        if (Array.isArray(directParent)) {
            const ticket = getParent<IAnyStateTreeNode>(self, 2);
            if (isStateTreeNode(ticket) && "id" in ticket) {
                return ticket["id"] as string;
            }
        }
        else if (isStateTreeNode(directParent) && "id" in directParent) {
            return directParent["id"] as string;
        }
        throw new Error(`Parent Ticket is missing for task ${self.taskId}.`);
    },
})).actions(self => ({
    setAssignee(newAssignee: string) {
        self.assignee.clear();
        self.assignee.push(newAssignee);
    },
    setChangeStatus(val: boolean) {
        self.isChanged = val;
    },
    // For eventually tracking state in the model instance itself
    patchFields(val: { [K: string]: any; }) {
        applySnapshot(self.fields, self.fields.map(({ id, value, ...rest }) => ({
            ...rest,
            id,
            value: val[id] !== undefined ? val[id] : value
        })));
    }
})).actions(self => ({
    claim: flow(function* claim() {
        if (self.isClaimable) {
            try {
                const root = (getRoot(self) as Instance<typeof LateStoreModel>);
                yield claimOrUnclaimTask({ action: ClaimActions.CLAIM, tenant: root.auth.customerId, userId: root.auth.userId, projectId: root.projectInfo.currentProject.id, taskId: self.taskId, ticketId: self.parentTicketId, moduleId: __module });
                self.setAssignee(root.auth.userId);
                applyAction(getParent(self, 2), [{ name: "syncAssigneeDataForTicket", }]);
            }
            catch (err) { console.error(err); }
        }
    }),
    unclaim: flow(function* unclaim() {
        if (self.isUnclaimable) {
            try {
                const root = (getRoot(self) as Instance<typeof LateStoreModel>);
                yield claimOrUnclaimTask({ action: ClaimActions.UNCLAIM, tenant: root.auth.customerId, userId: root.auth.userId, projectId: root.projectInfo.currentProject.id, taskId: self.taskId, ticketId: self.parentTicketId, moduleId: __module });
                self.assignee.replace(self.taskCandidateUsers);
                applyAction(getParent(self, 2), [{ name: "syncAssigneeDataForTicket", }]);
            }
            catch (err) { console.error(err); }
        }
    }),
}));

export const CompletedTask = types.model({
    taskId: types.identifier,
    taskName: types.string,
    formKey: types.string,
    assignee: types.array(types.string),
    taskDefinitionKey: types.string,
    fields: types.array(FormField),
    taskStartDate: types.maybeNull(types.string),
    taskEndDate: types.maybeNull(types.string)
})

export const NullTask = Task.create({ taskId: "null", taskName: "null", formKey: "", assignee: [], taskDefinitionKey: "", fields: [] });

// TODO: should we bake it in into different tickets depending on module?
const __watchForFilteredViewChanges = ['tags', 'location', 'priority', 'status', 'assignee', 'dueDate', /*'watchers', 'name', 'taskName/currentTask', 'processVariables',*/];
// TODO: track loading state across the various API calls. Maybe hold active API
// calls in an array?
// TODO: Implement method to patch field values?
export const Ticket = types.model({
    id: types.identifier,
    loading: false,
    historyLoading: false,
    commentsLoading: false,
    tasksLoading: false,
    name: types.string,
    category: types.optional(types.string, ""),
    description: types.optional(types.string, ""),
    location: types.array(types.string),
    priority: types.optional(types.string, ""),
    priorityTitle: types.optional(types.string, ""),
    assignee: types.array(User),
    createdBy: User,
    dueDate: types.maybeNull(types.string),
    propertyId: types.maybeNull(types.string),
    tasks: types.array(Task),
    taskName: types.maybeNull(types.string),
    processVariables: types.frozen<{ [K: string]: any; }>(),
    createdAt: types.string,
    lastUpdated: types.string,
    taskId: types.array(types.string),
    type: types.string,
    outcomes: types.maybeNull(types.frozen<{ [K: string]: any[]; }>()),
    commentDetailList: types.array(types.frozen<ICommentDetail>()),
    status: types.maybeNull(types.string),
    statusCategory: types.maybeNull(types.string),
    statusTitle: types.maybeNull(types.string),
    history: types.array(types.frozen<IHistory>()),
    unread: types.optional(types.boolean, false),
    requestTypeTitle: types.string,
    config: types.maybeNull(types.frozen<{ [K: string]: any; }>()),
    draft: types.optional(types.string, ""),
    generalForm: types.maybeNull(Task),
    currentTask: types.safeReference(Task),
    isStale: false,
    completedTasks: types.array(CompletedTask),
    currentCompletedTask: types.safeReference(CompletedTask),
    tags: types.array(types.string),
    watchers: types.array(types.string),
    permissions: types.frozen<{ [K: string]: boolean; }>({}),
    ticketPermissions: types.frozen<string[]>(['can_delete', 'can_delete_adhoc']),
    filteredViews: types.array(types.string),
    startDate: types.maybeNull(types.string),
}).views(self => ({
    get allowedWatchers() {
        return (getRoot(self) as Instance<typeof LateStoreModel>).ticket.allowedWatchers;
    },
})).views(self => ({
    get watchersWithNames() {
        return self.watchers.map(id => ({ id, name: self.allowedWatchers.has(id) ? (self.allowedWatchers.get(id)?.name || id) : self.processVariables['usersMap'] ? (self.processVariables['usersMap'][id] || id) : id }));
    },
    get popupLoading() {
        return false;
    },
    get historyCommentsPaneLoading() {
        return self.historyLoading || self.commentsLoading;
    },
    get ticketDefinition() {
        return (getRoot(self) as Instance<typeof LateStoreModel>).ticket.ticketTypes.find(({ typeId }) => typeId === self.type)!;
    },
    get files() {
        return self.commentDetailList.reduce((acc: { unique: string[]; data: any[] }, { commentType, attachments, userId, createdAt }) => commentType === "media" && attachments?.length ?
            ({
                unique: [...acc.unique, ...attachments.filter(({ fileId }) => !acc.unique.includes(fileId))],
                data: [...acc.data,
                ...attachments.reduce((ac, att) => acc.unique.includes(att.fileId) ? ac : [...ac, { ...att, createdBy: userId, createdAt: moment(createdAt).format('MMM DD, YYYY hh:mm A') }], [])
                ]
            })
            : acc, { unique: [], data: [] }).data;
    },
    get historyAndComments() {
        if (!self.history.length && !self.commentDetailList.length) {
            return [];
        }
        if (!self.history.length) {
            return self.commentDetailList.map((_, index) => ({ array: 1, index })).slice();
        }
        if (!self.commentDetailList.length) {
            return self.history.map((_, index) => ({ array: 0, index })).slice();
        }
        return interleave({
            array1: self.history.slice(),
            array2: self.commentDetailList.slice(),
            key1: "createdAt",
            key2: "createdAt",
            comparator: (a, b) => moment(a).diff(moment(b)) > 0
        });
    },
    get path() {
        return getPath(self);
    },
    get delayed() {
        return !self.dueDate ? false : self.statusCategory !== TicketStatusCategories.CLOSED ? moment(self.dueDate).isBefore(moment(), 'day') : false
    }
})).views(self => ({
    // TODO: Deprecate this as soon as Permission Service is created
    get canEdit() {
        return !!self.ticketDefinition?.isAdhoc || !!self.assignee.find(assignee => assignee.id === (getRoot(self) as Instance<typeof LateStoreModel>).auth.userId);
    },
    get formFields(): IFormField[] {
        if (!self.currentTask) { return []; }
        // TODO: Remove Core Field filter.
        const coreFields = (getRoot(self) as Instance<typeof LateStoreModel>).ticket.__coreFields;
        if (self.currentTask?.isMultiUser) {
            if (self.currentTask?.isUnclaimable) {
                return [...(self.currentTask?.fields || [])];
            }
            else if (self.currentTask?.isClaimable) {
                return (self.currentTask?.fields || []).map((field: IFormField) => ({ ...getSnapshot(field), readOnly: true, required: false, calculate: field.calculate, } as IFormField));
            }
            return [];
        }
        else {
            if (!self.currentTask?.assignee.includes((getRoot(self) as Instance<typeof LateStoreModel>).auth.userId)) {
                return [];
            }
            return self.currentTask?.fields.filter(({ id }) => !coreFields[id]) || [];
        }
    },
    get completedTaskFormFields() {
        if (!self.currentCompletedTask) { return []; }
        const coreFields = (getRoot(self) as Instance<typeof LateStoreModel>).ticket.__coreFields;
        if (!self.currentCompletedTask?.assignee.includes((getRoot(self) as Instance<typeof LateStoreModel>).auth.userId)) {
            return [];
        }
        return self.currentCompletedTask?.fields.filter(({ id }) => !coreFields[id]) || [];
    },
    get canDelete() {
        return self.ticketDefinition.isAdhoc ? !!self.permissions['can_delete_adhoc'] : !!self.permissions['can_delete'];
    }
})).actions(self => ({
    refreshCore: flow(function* refreshCore() {
        yield (getRoot(self) as Instance<typeof LateStoreModel>).ticket.refreshCoreTicket(self.id);
    }),
})).actions(self => ({
    setLoading(val: boolean) {
        self.loading = val;
    },
    setStale(val: boolean) { self.isStale = val; },
    setDraft(val: string) {
        // Draft comments
        self.draft = val;
    },
    mark(val?: boolean) {
        self.unread = val || false;
    },
    addTagToTicket: flow(function* addTagToTicket(tag: string) {
        if (!self.tags.includes(tag)) {
            try {
                const { data } = yield addTag({
                    tenant: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId,
                    payload: {
                        userId: (getRoot(self) as Instance<typeof LateStoreModel>).auth.userId,
                        moduleId: self.ticketDefinition.moduleId,
                        ticketId: self.id,
                        tagId: tag
                    }
                });
                self.tags.push(tag);
                self.refreshCore();
                if (!(getRoot(self) as Instance<typeof LateStoreModel>).ticket.tags.includes(tag)) {
                    yield (getRoot(self) as Instance<typeof LateStoreModel>).ticket.getTags(true);
                }
            }
            catch (err) {
                console.error(err);
                if (self.tags.includes(tag)) {
                    self.tags.remove(tag)
                }
            }
        }
    }),
    removeTagFromTicket: flow(function* removeTagFromTicket(tag: string) {
        if (self.tags.includes(tag)) {
            try {
                const { data } = yield removeTag({
                    tenant: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId,
                    payload: {
                        userId: (getRoot(self) as Instance<typeof LateStoreModel>).auth.userId,
                        moduleId: self.ticketDefinition.moduleId,
                        ticketId: self.id,
                        tagId: tag
                    }
                });

                self.tags.remove(tag);
                self.refreshCore();
            }
            catch (err) {
                console.error(err);
                if (!self.tags.includes(tag)) {
                    self.tags.push(tag);
                }
            }
        }
    }),
    renameTag({ newTag, oldTag }: { newTag: string; oldTag: string }) {
        self.tags.remove(oldTag);
        self.tags.push(newTag);
    },
    refreshHistory: flow(function* refreshHistory() {
        self.historyLoading = true;
        try {
            const { data } = yield getHistory({ tenant: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId, ticketId: self.id });
            // Tried to only push diff. Some bugs
            // if (self.history.length) {
            //     const ts = moment(self.history[self.history.length - 1].createdAt);
            //     let i = data.length - 1;
            //     while (i) {
            //         if (moment(data[i].createdAt).diff(ts) === 0) { break; }
            //         i--;
            //     }
            //     // const ind = data.findIndex(({ createdAt }) => moment(createdAt).diff(ts) > 0);
            //     console.log('yo', i, data.slice(i));
            //     self.history.push(...data.slice(Math.max(i, 0)));
            // }
            // else {
            //     self.history.push(...data);
            // }
            if (data) {
                self.history.clear();
                self.history.push(...data);
            }
        }
        catch (err) { console.error(err); }
        finally { self.historyLoading = false; }
    }),
    refreshComments: flow(function* refreshComments() {
        // TODO: track separate comments loading state?
        // TODO: Maybe just push latest comments?
        self.commentsLoading = true;
        try {
            const { data } = yield listCommentsForProcess({ processInstanceId: self.id });
            if (data && data.length) {
                self.commentDetailList.clear();
                self.commentDetailList.push(...data);
            }
        }
        catch (err) { console.error(err); }
        finally { self.commentsLoading = false; }
    }),
    getTaskDetails: flow(function* getTaskDetails() {
        const taskIds = self.taskId;
        if (!taskIds.length) { return; }
        if (self.tasks.length === taskIds.length && taskIds.every(id => self.tasks.findIndex(({ taskId }) => id === taskId) > -1)) { return; }
        self.tasksLoading = true;
        // self.tasks.clear();
        try {
            if (self.statusCategory === TicketStatusCategories.CLOSED) {
                const customFormRef = self.ticketDefinition.customFormRef;
                if (!customFormRef) { return; }
                const { data: { fields, name } } = yield getGeneralForm({
                    customerId: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId,
                    ticketId: self.id,
                    formKey: customFormRef[__completedKey]
                });
                // TODO: should tDK and formKey be null?
                self.tasks.push({
                    fields: fields.map(v => ({ ...v, readOnly: true })),
                    taskId: "__closed",
                    taskName: name,
                    assignee: [(getRoot(self) as Instance<typeof LateStoreModel>).auth.userId],
                    taskDefinitionKey: "",
                    formKey: customFormRef[__completedKey]
                });
                return;
            }
            const val = yield Promise.all(taskIds.map(taskId =>
                getTaskDetailByTaskId({ customer: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId, taskId }).catch(err => { console.error(err); })));
            val.forEach(v => {
                if (!!v) {
                    const { data: { taskId, taskName, formKey, formFields, outcomes, assignee, commentDetailList, taskDefinitionKey, taskCandidateUsers } } = v;
                    if (!self.commentDetailList.length) {
                        self.commentDetailList.push(...(commentDetailList || []));
                    }
                    self.tasks.push({
                        taskId, taskName, formKey, assignee, taskDefinitionKey, taskCandidateUsers: taskCandidateUsers || [], fields: formFields?.map(({ value, type, ...rest }) =>
                            type === "upload" ?
                                ({ ...rest, type, value: value ? Array.isArray(value) ? value : [value] : [] })
                                : type === "expression" ?
                                    isSpecialExpr(rest.expression) ?
                                        __mapLiveExprToField({ ...rest, value, type })
                                        :
                                        ({ ...rest, type, value, id: __sanitizeExpr(rest.expression || rest.id) })
                                    : ({ ...rest, value, type })
                        ) || [],
                    });
                    self.outcomes = { ...self.outcomes, [taskId]: outcomes };
                }
            });
        }
        // catch(err) { console.error(err);}
        finally { self.tasksLoading = false; }
    }),
    getGeneralForm: flow(function* () {
        try {
            const customFormRef = self.ticketDefinition.features.ticketDefMetadata && self.ticketDefinition.customFormRef;
            if (!customFormRef) { return; }
            self.loading = true;
            const { data: { fields, name } } = yield getGeneralForm({
                customerId: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId,
                ticketId: self.id,
                formKey: customFormRef[__ongoingKey]
            });
            // TODO: should tDK and formKey be null?
            if (!self.generalForm) {
                self.generalForm = Task.create({
                    fields: fields.map(v => ({ ...v, readOnly: true })),
                    taskId: "__default",
                    taskName: name,
                    assignee: [],
                    taskDefinitionKey: "",
                    formKey: customFormRef[__ongoingKey]
                });
            } else {
                applyAction(self.generalForm.fields, fields.map((v, i) => ({ name: 'setValue', path: `/${i}`, args: [v.value] })));
            }
        }
        catch (err) { console.error(err); }
        finally { self.loading = false; }
    }),
    setCoreField(field: string, val: any) {
        if (val !== self[field]) {
            if (__watchForFilteredViewChanges.includes(field)) {
                self.refreshCore();
            }
            else {
                self[field] = val;
            }
            // if (field === "status") {
            //     self.statusTitle = (getRoot(self) as Instance<typeof LateStoreModel>).ticket.statusesByType[self.ticketDefinition.typeId]?.find(({ id }) => id === self.status)?.name || val;
            //     self.statusCategory = (getRoot(self) as Instance<typeof LateStoreModel>).ticket.statusesByType[self.ticketDefinition.typeId]?.find(({ id }) => id === self.status)?.statusCategory || TicketStatusCategories.INVALID;
            // }
            // else if (field === "priority") {
            //     self.priorityTitle = (getRoot(self) as Instance<typeof LateStoreModel>).ticket.priorities.find(({ id }) => id === self.priority)?.name || val;
            // }
        }
    },
    setCurrentTask(taskId?: string) {
        if (self.statusCategory === TicketStatusCategories.CLOSED && !self.currentTask) {
            if (self.tasks.length) {
                self.currentTask = self.tasks[0];
            }
        }
        else {
            self.currentTask = resolveIdentifier(Task, self.tasks, taskId || "");
        }
    },
    setCurrentCompletedTask(taskId?: string) {
        self.currentCompletedTask = resolveIdentifier(CompletedTask, self.completedTasks, taskId || "");
    },
    getCompletedTasks: flow(function* (force: boolean = true) {
        if (!force && self.completedTasks.length) { return; }
        try {
            self.completedTasks.clear();
            const { data } = yield getCompletedTasksByTicket({ tenant: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId, ticketId: self.id });
            data.forEach(v => {
                if (!!v) {
                    const { taskId, taskName, formKey, formFields, assignee, taskDefinitionKey, taskStartDate, taskEndDate } = v;
                    self.completedTasks.push({
                        taskId, taskName, formKey, assignee, taskDefinitionKey, fields: formFields.map(({ value, type, ...rest }) =>
                            type === "upload" ?
                                ({ ...rest, type, value: value ? Array.isArray(value) ? value : [value] : [] })
                                : type === "expression" ?
                                    isSpecialExpr(rest.expression) ?
                                        __mapLiveExprToField({ ...rest, value, type })
                                        :
                                        ({ ...rest, type, value, id: __sanitizeExpr(rest.expression || rest.id) })

                                    : ({ ...rest, value, type })
                        ),
                        taskStartDate, taskEndDate
                    });
                }
            });
        } catch (err) { console.error(err); }
    }),
    /* Hack to show last updated based on user action without actually fetching the db value*/
    setLastUpdated(date?: string) {
        let dat = moment();
        if (date) {
            dat = moment(date);
        }
        self.lastUpdated = dat.toISOString();
    },
})).actions(self => ({
    syncAssigneeDataForTicket: flow(function* syncAssigneeDataForTicket() {
        try {
            const { data } = yield getTickets({ tenant: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId, moduleId: self.ticketDefinition.moduleId, ticketId: self.id, projectId: (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo.currentProject.id });
            self.assignee.clear();
            self.assignee.push(...(data.assignee || []));
            const replaceViewsFlag = comparePrimitiveArrays(self.filteredViews, (data.filteredViews || []));
            if (replaceViewsFlag) {
                self.filteredViews.replace(data.filteredViews || []);
            }
            yield self.refreshHistory();
        }
        catch (err) { console.error(err); }
    }),
})).actions(self => ({
    replaceAssigneeForTask: flow(function* replaceAssigneeForTask(taskId: string, assignee: string) {
        const task = self.tasks.find(({ taskId: tId }) => taskId === tId);
        if (!task) { return; }
        task.setAssignee(assignee);
        const { data } = yield getTickets({ tenant: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId, moduleId: self.ticketDefinition.moduleId, ticketId: self.id, projectId: (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo.currentProject.id });
        self.assignee.clear();
        self.assignee.push(...(data.assignee || []));
        const replaceViewsFlag = comparePrimitiveArrays(self.filteredViews, (data.filteredViews || []));
        if (replaceViewsFlag) {
            self.filteredViews.replace(data.filteredViews || []);
        }
        yield self.refreshHistory();
    }),
})).actions(self => ({
    updateCoreField: flow(function* updateCoreFieldValue(field: string, val: any) {
        const coreField = (getRoot(self) as Instance<typeof LateStoreModel>).ticket.__coreFields[field];
        const fld = coreField.key || field;
        const fallback = self[fld];
        try {
            const { data } = yield updateCoreFields({
                ticketId: self.id,
                userId: (getRoot(self) as Instance<typeof LateStoreModel>).auth.userId,
                moduleId: self.ticketDefinition.moduleId,
                tenant: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId,
                requestModuleMainForm: { [field]: val }
            });
            self.setCoreField(fld, val);
            yield self.refreshHistory();
        }
        catch (err) {
            console.error(err);
            self.setCoreField(fld, fallback);
        }
    }),
    updateAssignee: flow(function* updateAssigneeField({ taskId, newAssignee, oldAssignee, userId }: { taskId: string, newAssignee: string, oldAssignee: string; userId: string; }) {
        try {
            yield updateAssignee({
                tenant: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId,
                moduleId: self.ticketDefinition.moduleId,
                ticketId: self.id,
                taskId,
                newAssignee,
                oldAssignee,
                userId
            });
            yield self.replaceAssigneeForTask(taskId, newAssignee);
        }
        catch (err) {
            console.error(err);
            const task = self.tasks.find(({ taskId: tId }) => taskId === tId);
            task?.setAssignee(oldAssignee);
        }
    }),

})).actions(self => ({
    addOrRemoveWatcher: flow(function* addOrRemoveWatcher({ op, watcher }: { op: 'add' | 'remove', watcher?: string; }) {
        try {
            const watcherId = watcher || (getRoot(self) as Instance<typeof LateStoreModel>).auth.userId;
            if (op === 'add' && self.watchers.includes(watcherId)) { return; }
            if (op === 'remove' && !self.watchers.includes(watcherId)) { return; }
            yield updateWatcherForTicket({
                op,
                tenant: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId,
                moduleId: self.ticketDefinition.moduleId,
                ticketId: self.id,
                userId: (getRoot(self) as Instance<typeof LateStoreModel>).auth.userId,
                watcherId,
            });
            self.refreshCore();
            // if (op === 'add') { self.watchers.push(watcherId); }
            // else if (op === 'remove') { self.watchers.remove(watcherId); }
        }
        catch (err) { console.error(err); }
    })
})).actions(self => ({
    setPermissions: flow(function* setPermissions() {
        try {
            // if (self.permissions && Object.keys(self.permissions).length) { return; }
            // A simple optimization that removes an extra API call
            const perms = self.ticketPermissions.filter(permission => self.ticketDefinition.isAdhoc ? (permission !== "can_delete") : (permission !== "can_delete_adhoc"));
            const prefix = getPrefixForResourceType(PermissionResources.TICKET, {});
            const data = yield Promise.all(perms.map(permission =>
                checkPermission({
                    customerId: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId,
                    projectId: (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo.currentProject.id,
                    moduleId: self.ticketDefinition.moduleId,
                    objectType: "ticket",
                    objectId: prefix + self.id,
                    subjectType: "user",
                    subjectId: (getRoot(self) as Instance<typeof LateStoreModel>).auth.userId,
                    permission
                }).then(({ data }) => ({ [permission]: !!data }))))
            self.permissions = data.reduce((acc, obj) => ({ ...acc, ...obj }), {});
        }
        catch (err) { console.error(err); }
    }),
    delete: flow(function* deleteT() {
        try {
            yield deleteTicket({
                tenant: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId,
                ticketId: self.id,
                moduleId: self.ticketDefinition.moduleId,
                isAdhoc: self.ticketDefinition.isAdhoc,
            });
            return (getRoot(self) as Instance<typeof LateStoreModel>).ticket.removeTicket(self);
        }
        catch (err) {
            console.error(err);
        }
    }),
}));

export const TicketType = types.model({
    typeId: types.identifier,
    loading: false,
    allowedToInitiate: types.optional(types.boolean, false),
    moduleEnabled: types.optional(types.boolean, false),
    moduleId: types.string,
    moduleName: types.string,
    title: types.string,
    startForm: types.array(FormField),
    featureConfig: types.frozen<{ [K: string]: IFeature; }>({}),
    features: types.frozen<{ [K: string]: any; }>({})
}).views(self => ({
    get hasStartForm() {
        if (!self.allowedToInitiate) { return false; }
        if (self.features.ticketDefMetadata && self.featureConfig["ticketDefMetadata"]) {
            return self.features["ticketDefMetadata"][self.featureConfig["ticketDefMetadata"].configKeys[0]] ? !!self.features["ticketDefMetadata"][self.featureConfig["ticketDefMetadata"].configKeys[0]]["hasStartForm"] : false;
        }
        return false
    },
    get isAdhoc() {
        return !!self.features.ticketDefMetadata && !!self.features.ticketDefMetadata[self.featureConfig["ticketDefMetadata"].configKeys[0]] && !!self.features.ticketDefMetadata[self.featureConfig["ticketDefMetadata"].configKeys[0]].isAdhoc;
    },
    get customFormRef() {
        if (!!self.features.ticketDefMetadata && !!self.featureConfig["ticketDefMetadata"] && !!self.features.ticketDefMetadata[self.featureConfig["ticketDefMetadata"].configKeys[0]]) {
            return self.features.ticketDefMetadata[self.featureConfig["ticketDefMetadata"].configKeys[0]].customFormRef;
        }
        return null;
    }
})).actions(self => ({
    setLoading(val: boolean) {
        self.loading = val;
    },
    setFeatures(val: { [K: string]: any; }) {
        self.features = Object.keys(self.featureConfig).reduce((acc, feature) => val[self.featureConfig[feature]?.pathParam || ""] ? ({ ...acc, [feature]: val[self.featureConfig[feature]?.pathParam || ""] }) : acc, {});
    },
    setStartForm: flow(function* setStartForm() {
        if (!self.startForm.length && self.hasStartForm) {
            self.loading = true;
            try {
                const { data } = yield getStartForm({ customerId: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId, processDefKey: self.typeId });
                self.startForm.push(...data);
            }
            catch (err) { console.error(err); }
            finally { self.loading = false; }
        }
    })
}))// .actions(self => ({
//     getFeatures: flow(function* getFeatures() {
//     })
// }));

const DynamicDropdownOptions = types.model({
    id: types.identifier,
    options: types.array(types.frozen<{ id: string; name?: string; }>())
});

const GridFilter = types.model({
    id: types.identifier,
    label: types.string,
    filterIds: types.array(types.string),
    value: types.array(types.frozen<{ id: string; value: any; }>()),
    isActive: false,
    alwaysActive: types.array(types.string),
}).views(self => ({
    get isActiveForCurrentView() {
        const isAlwaysActive = self.alwaysActive.includes((getRoot(self) as Instance<typeof LateStoreModel>).ticket.currentView);
        return isAlwaysActive || self.isActive;
    },
})).actions(self => ({
    setActive(val: boolean) {
        if (val !== self.isActiveForCurrentView) {
            self.isActive = val;
        }
    },
    pushAlwaysActive(val: string) {
        self.alwaysActive.push(val);
    },
    // TODO: use here when value can be dynamic
    // setValue() {
    // }
}));
const __displays = ["default", "dropdown", "date", "rich-text", "numeric", "file"];
const __displayCells = {
    "rich-text": {
        Cell: RichTextCell,
    },
    "date": {
        filterVariant: 'date-range',
        sortingFn: 'datetime',
        Cell: NewDateCell,
    },
    "numeric": {
        filterVariant: 'range',
    },
    "file": {
        Cell: File,
    },
};
// Column Overrides
export const ColumnOverride = types.model({
    id: types.identifier,
    name: types.optional(types.string, ""),
    type: types.optional(types.enumeration(["core", "processVariable"]), "core"),
    display: types.optional(types.enumeration(__displays), "default"), /*TODO: Add other cells to support here */
    metadata: types.frozen<{ [K: string]: any; }>({}), /*TODO: Formalize into its own interface when the metadata details are finalized */
    isVisible: types.optional(types.boolean, true),
    overrideLabel: types.maybeNull(types.string),
}).views(self => ({
    accessorFn(row): any {
        const idVal = self.type === "core" ? row[self.id] : self.type === "processVariable" ? (row?.processVariables && row?.processVariables[self.id] || "") : "";
        if (self.display === "dropdown") {
            return row[self.metadata.variable || ""]?.find(elt => elt.id === idVal)?.name || "";
        }
        if (self.display === "date") {
            return idVal.length ? new Date(idVal) : null;
        }
        return idVal;
    },
})).views(self => ({
    get colDef(): MRT_ColumnDef<RequestTicketDetails> | null {
        return (self.isVisible && !self.overrideLabel) ?
            __displayCells[self.display] ?
                {
                    id: self.id,
                    header: self.name,
                    enableColumnFilterModes: false,
                    accessorFn: self.accessorFn,
                    Header: CustomHeader,
                    ...self.metadata,
                    ...__displayCells[self.display],
                } :
                {
                    id: self.id,
                    header: self.name,
                    Header: CustomHeader,
                    // lol without this as 'multi-select' typing, typescript was throwing an type error
                    filterVariant: 'multi-select' as 'multi-select',
                    enableColumnFilterModes: false,
                    accessorFn: self.accessorFn,
                    ...self.metadata,
                } : null;
    },
})).actions(self => ({
    populateDynamicVariable() {
        if (self.display === "dropdown" && self.metadata.variable && !(getRoot(self) as Instance<typeof LateStoreModel>).ticket.dynamicDropdowns.has(self.metadata.variable)) {
            const variable = self.metadata.variable;
            getDropdownOption({ customerId: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId, variableType: variable, projectId: (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo.currentProject.id, })
                .then(({ data: { data } }) => {
                    (getRoot(self) as Instance<typeof LateStoreModel>).ticket.addDynamicDropdown(variable, data);
                })
                .catch(err => { console.error(err); })
        }
    }
}));

export const ProjectSelection = types.model({
    options: types.frozen<string[]>([]),
    label: types.string
})

// Filtered View holder
export const FilteredView = types.model({
    id: types.identifier,
    tickets: types.array(types.safeReference(Ticket)),
    columnOverrides: types.array(ColumnOverride),
    projectSelection: types.maybeNull(ProjectSelection)
}).views(self => ({
    get positionsInArray() {
        return self.tickets.map(t => Number.parseInt(getPathParts(t).slice(-1)[0] || "-1"));
    },
    get hideColumns() {
        return self.columnOverrides.length ? self.columnOverrides.reduce((acc, col) => ({ ...acc, [col.id]: col.isVisible }), {}) : null;
    },
    get newGridKeys(): Set<string> {
        return transduce(Array.from(self.columnOverrides),
            compose(
                map(colOv =>
                    colOv.display === "dropdown" ?
                        { variable: `dropdown:${colOv.metadata.variable || ""}`, orig: colOv }
                        :
                        { orig: colOv }
                ),
                map(colOv =>
                    colOv.orig.type === "core" ?
                        colOv.variable ? [colOv.variable, colOv.orig.id] : [colOv.orig.id]
                        : colOv.orig.type === "processVariable" ?
                            colOv.variable ? [colOv.variable, "processVariables"] : ["processVariables"]
                            :
                            colOv.variable ? [colOv.variable, colOv.orig.id] : [colOv.orig.id]
                ),
                flatten(),
            ),
            toSet());
    },
    get overrideLabels() {
        return self.columnOverrides.length ? self.columnOverrides.reduce((acc, col) => col.overrideLabel ? ({ ...acc, [col.id]: col.overrideLabel }) : acc, {}) : {};
    },
    get projectsOptions() {
        return self.projectSelection?.options.map(id => ({ id, name: (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo.projects.find(pr => pr.id === id)?.name || id })) || []
    }
})).actions(self => ({
    addTicket(t: ITicket) {
        if (!self.tickets.includes(t)) {
            const num = Number.parseInt(getPathParts(t).slice(-1)[0] || "-1");
            if (num === -1) { self.tickets.push(t); }
            else {
                const ind = self.positionsInArray.findIndex(n => n === -1 ? false : n > num);
                if (ind === -1) { self.tickets.push(t); }
                else {
                    self.tickets.splice(Math.max(0, ind - 1), 0, t);
                }
            }
        }
    },
    removeTicket(t: ITicket) {
        if (self.tickets.includes(t)) {
            self.tickets.remove(t);
        }
    },
    addTickets(ts: ITicket[]) {
        if (self.tickets.length) { self.tickets.clear(); }
        self.tickets.push(...ts);
    },
    addColumnOverrides(cols: IColumnOverride[]) {
        if (self.columnOverrides.length) { self.columnOverrides.clear(); }
        self.columnOverrides.push(...cols);
        const actions: ISerializedActionCall[] = self.columnOverrides.reduce((acc, col, i) => col.type === "dropdown" ? [...acc, { name: "populateDynamicVariable", path: `/${i}`, args: [] }] : acc, []);
        applyAction(self.columnOverrides, actions);
    },
})).actions(self => ({
    afterAttach() {
        if (self.columnOverrides.length) {
            const actions: ISerializedActionCall[] = self.columnOverrides.reduce((acc, col, i) => col.display === "dropdown" ? [...acc, { name: "populateDynamicVariable", path: `/${i}`, args: [] }] : acc, []);
            applyAction(self.columnOverrides, actions);
        }
    }
})).actions(self => ({
    setProjectSelection(options: string[], label?: string) {
        self.projectSelection = {
            options,
            label: label || 'Select Project'
        }
    }
}));

const ColumnOrder = types.model({
    id: types.identifier,
    columnOrder: types.array(types.string),
}).actions(self => ({
    setColumnOrder(vals: string[]) {
        if (vals.length) {
            self.columnOrder.clear();
            self.columnOrder.push(...vals);
        }
    },
}));

const Permission = types.model({
    resourceType: types.identifier,
    permissions: types.frozen<{ [K: string]: string[] }>({}),
});

const BulkImportRequest = types.model({
    requestId: types.identifier,
    loading: false,
    status: types.optional(types.string, BulkRequestStatuses.PENDING as string),
    statusText: types.optional(types.string, "Request Made"),
    originalFilename: types.optional(types.string, ""),
    ticketType: types.string,
}).views(self => ({
    get filename() {
        const root = (getRoot(self) as Instance<typeof LateStoreModel>);
        return `${root.auth.customerName}-${root.projectInfo.currentProject?.name}-${self.ticketType}-${self.requestId}.xlsx`;
    },
    get disabled() {
        return self.status === BulkRequestStatuses.INVALID;
    },

})).actions(self => ({
    setStatus(status: string) {
        switch (status.toLowerCase()) {
            case "ok": self.statusText = "Downloaded Status File"; self.status = BulkRequestStatuses.COMPLETED as string; break;
            case "request is still in progress": self.statusText = status; self.status = BulkRequestStatuses.PENDING as string; break;
            default: self.statusText = status; self.status = BulkRequestStatuses.INVALID as string; break
        }
    },
})).actions(self => ({
    download: flow(function* downloadBulkImportRequestFile() {
        try {
            self.loading = true;
            const data = yield downloadBulkUploadStatusFile({ reqId: self.requestId, filename: self.filename });
            self.setStatus(data);
        }
        catch (err) { console.error(err); self.setStatus(err.response.statusText); }
        finally { self.loading = false; }
    }),
}));

const UserTaskType = types.model({
    id: types.identifier,
    name: types.string,
    tasks: types.array(types.frozen<{ taskKey: string; taskName: string; totalTasks: number; canUpdateTasks: string[] }>())
});

const __historyLimit = 10;
const BulkImport = types.model({
    loading: false,
    selectedTicketType: types.safeReference(TicketType),
    history: types.array(BulkImportRequest),
    isOpen: false,
    allTicketTypes: types.array(UserTaskType)

}).views(self => ({
    get startableTicketTypes(): ITicketType[] {
        return (getRoot(self) as Instance<typeof LateStoreModel>).ticket.startableTicketTypes;
    },
    get disabled() { return !self.selectedTicketType; },
    getTasksForDefinition(defID: string) {
        return self.allTicketTypes?.find(tt => tt.id === defID)?.tasks
    }
})).actions(self => ({
    selectTicketType(tt: string) {
        const ticketT = resolveIdentifier(TicketType, getParent(self), tt || "");
        if (ticketT && self.selectedTicketType?.typeId !== tt) {
            self.selectedTicketType = ticketT;
        }
    },
    setOpen(val: boolean) { self.isOpen = val; if (!val) { self.selectedTicketType = undefined; } },
    addRequest({ requestId, ticketType, originalFilename }: { requestId: string; ticketType: string; originalFilename: string; }) {
        if (self.history.length >= __historyLimit) {
            self.history.shift();
        }
        self.history.push({ requestId, ticketType, originalFilename });
    },
})).actions(self => ({
    downloadTemplate: flow(function* downloadTemplate() {
        if (!self.disabled) {
            try {
                self.loading = true;
                const root = (getRoot(self) as Instance<typeof LateStoreModel>);
                const data = yield downloadRequestTemplate({ tenant: root.auth.customerId, projectId: root.projectInfo.currentProject!.id, moduleId: __module, ticketTypeId: self.selectedTicketType!.typeId });
            }
            catch (err) { console.error("Downloading Template failed for " + self.selectedTicketType!.title); console.error(err); }
            finally { self.loading = false; }
        }
    }),
    makeBulkImportRequest: flow(function* makeBulkImportRequest(file: File) {
        if (!self.disabled) {
            try {
                self.loading = true;
                const root = (getRoot(self) as Instance<typeof LateStoreModel>);
                const data = yield importRequestTickets({ tenant: root.auth.customerId, projectId: root.projectInfo.currentProject!.id, moduleId: __module, ticketTypeId: self.selectedTicketType!.typeId, file });
                if (data.refId) {
                    self.addRequest({ requestId: data.refId, ticketType: self.selectedTicketType!.title, originalFilename: file.name });
                }
            }
            catch (err) { console.error("Bulk Import failed for " + self.selectedTicketType!.title); console.error(err); }
            finally { self.loading = false; }
        }
    }),
    setAllTicketTypes: flow(function* setAllTicketTypes() {
            if(self.allTicketTypes?.length) { self.allTicketTypes?.clear(); } //clear data if exists
            
            try {
                self.loading = true;
                const root = (getRoot(self) as Instance<typeof LateStoreModel>);
                const { data } = yield getProcessDefinitionsByProject({  projectId: root.projectInfo.currentProject!.id, moduleId: __module });
                
                if(data.length) {
                    data.forEach(({id, name, tasks }) => {                   
                        var uniqueTaskKeys = {};
                        tasks.forEach(({taskKey, taskName, taskIds, canUpdate}) => 
                            {
                                if(!uniqueTaskKeys[taskKey]) {
                                    uniqueTaskKeys[taskKey] = { taskKey, taskName, canUpdateTasks: new Set(), allTasks: new Set() }
                                }

                                taskIds.forEach(task => {
                                    uniqueTaskKeys[taskKey].allTasks.add(task);
                                    if(canUpdate) { 
                                        uniqueTaskKeys[taskKey].canUpdateTasks.add(task);
                                    }
                                });

                            });
                            self.allTicketTypes?.push({ id, name, tasks : Object.keys(uniqueTaskKeys).map(taskKey => ({taskKey, taskName: uniqueTaskKeys[taskKey].taskName, totalTasks: uniqueTaskKeys[taskKey].allTasks.size, canUpdateTasks: Array.from(uniqueTaskKeys[taskKey].canUpdateTasks)})) })
                        })
                }
            }
            catch (err) { console.error("Failed to fetch ticket definition data for task updates "); console.error(err); }
            finally { self.loading = false; }
    }),
    downloadTemplateWithTicketType: flow(function* downloadTemplateWithTicketType(ticketTypeId: string, fileName: string, taskIds: string) {
            try {
                self.loading = true;
                const root = (getRoot(self) as Instance<typeof LateStoreModel>);
                const data = yield downloadRequestTaskForms({ tenant: root.auth.customerId, projectId: root.projectInfo.currentProject!.id, moduleId: __module, ticketTypeId, fileName, taskIds });
            }
            catch (err) { console.error("Downloading Template failed for task updates"); console.error(err); }
            finally { self.loading = false; }
    }),
    makeBulkImportRequestForTasks: flow(function* makeBulkImportRequestForTasks(file: File) {
            try {
                self.loading = true;
                const root = (getRoot(self) as Instance<typeof LateStoreModel>);
                const data = yield importRequestTaskForms({ tenant: root.auth.customerId, projectId: root.projectInfo.currentProject!.id, moduleId: __module, file });
            }
            catch (err) { console.error("Bulk Import failed for task updates"); console.error(err); }
            finally { self.loading = false; }
    })
})).actions(self => ({
    afterAttach() {
        const history = localStorage.getItem("ticketBulkUploadHistory");
        try {
            if (history) {
                self.history.replace(JSON.parse(history));
            }
        }
        catch (err) {
            console.error("Bulk Upload Requst History as persisted in local storage is in incorrect format: ", history);
            localStorage.removeItem("ticketBulkUploadHistory");
        }
    }
}));



const allPerms = { "filtered_view": ["can_view"] };
// TODO: Make into a map or array later when modules are fully dynamic in frontend.
const __module = "requests";
export const TicketModule = types.model({
    module: types.optional(types.string, __module),
    moduleTitle: types.optional(types.string, ""),
    loading: false,
    priorities: types.frozen<{ id: string; name: string; position: number; }[]>([]),
    statuses: types.frozen<{ id: string; name: string; ticketType: string; statusCategory: string; }[]>([]),
    featureConfig: types.frozen<{ [K: string]: IFeature; }>({}),
    ticketTypes: types.array(TicketType),
    tickets: types.array(Ticket),
    currentTicket: types.safeReference(Ticket),
    currentTicketDefinition: types.safeReference(TicketType),
    dynamicDropdowns: types.map(DynamicDropdownOptions),
    gridFilters: types.map(GridFilter),
    gridKeys: types.array(types.string),
    filteredRowsCount: types.optional(types.number, 0),
    tags: types.array(types.string),
    allowedWatchers: types.map(User),
    features: types.frozen<{ [K: string]: any; }>({}),
    currentView: types.optional(types.string, "all"),
    filteredViewsMap: types.map(FilteredView),
    resourcePermissions: types.frozen<{ [K: string]: string[] }>(allPerms),
    permissions: types.map(Permission),
    columnOrderMap: types.map(ColumnOrder),
    bulkImport: BulkImport,
    switchedUI: false
}).volatile(_ => ({
    __coreFields: {}
})).views(self => ({
    get filteredViews() {
        const allTickets = !!self.features.filteredViews?.hideAllTicketsView?.hidden ? [] : [{ id: "all", name: "All Tickets" }];
        if (self.features && self.features.filteredViews?.views) {
            // TODO: enable when filtered_views permissions are feature-ready in // the backend.
            // const viewable = self.permissions.get("filtered_view")?.permissions["can_view"]?.map(str => str.split('_').slice(-1)[0]) || [];
            const viewable = self.permissions.get("filtered_view")?.permissions["can_view"] || [];
            // const viewable = Object.keys(self.features.filteredViews?.views || {}) || [];
            return allTickets.concat(Object.keys(self.features.filteredViews?.views || {})
                .reduce((acc, k) => viewable.includes(k) ? [...acc, { id: k, name: (self.features.filteredViews?.views || {})[k]?.name || k }] : acc, []));
        }
        return allTickets;
    },
    get tagsToTicketsMap() {
        return transduce(
            self.tickets,
            compose(
                map(t => t.tags.map(tag => ({
                    id: t.id,
                    tag,
                    path: getPath(t)
                }))),
                flatten()
            ),
            toObjectGroupBy<{ id: string; tag: string; path: string }, string, { id: string; tag: string; path: string }>(({ tag }) => tag)
        );
    },
    get allowedWatchersArray() {
        return Array.from(self.allowedWatchers.values());
    },
    get consolidatedGridKeys() {
        const fV = self.filteredViewsMap.get(self.currentView);
        if (!fV || !fV.columnOverrides.length) {
            return self.gridKeys;
        }
        return self.gridKeys.concat(Array.from(fV.newGridKeys).filter(k => !self.gridKeys.includes(k)));
    },
})).views(self => ({
    get statusesByType() {
        // Introduced a hardcoded status with id complete since that's what
        // seems to be happening in backend too
        return self.statuses.reduce((acc, { ticketType, ...rest }) => ({
            ...acc,
            [ticketType]: [...(acc[ticketType] || [{ ticketType, id: 'complete', name: 'Completed', statusCategory: TicketStatusCategories.CLOSED }]), { ticketType, ...rest }]
        }), {});
    },
    /*    get coreFields() {
          return Object.keys(self.__coreFields).reduce((acc, k) => ({
          ...acc,
          [k]: {
          ...self.__coreFields[k],
          isEditable: self.currentTicket?.ticketDefinition.isAdhoc ?
          self.currentTicket?.tasks?.some(({ fields }) => !!fields?.find(({ id }) => id === k))
          : !!self.currentTicket?.tasks?.find(({ assignee }) => assignee?.includes((getRoot(self) as Instance<typeof LateStoreModel>).auth.userId))?.fields?.find(({ id }) => id === k)
          }
          }), {});
          },
    */
    get coreFields() {
        if (!self.currentTicket && !self.currentTicketDefinition) {
            return Object.keys(self.__coreFields).reduce((acc, k) => ({
                ...acc,
                [k]: {
                    ...self.__coreFields[k],
                    isEditable: false
                }
            }), {});
        }
        const td = self.currentTicket?.ticketDefinition || self.currentTicketDefinition;
        const baseCoreFields = td.features.coreFieldControl && td.features.coreFieldControl.attributes ? Object.keys(self.__coreFields).reduce((acc, k) => ({
            ...acc,
            [k]: /*hardcode title*/ k === "title" ? {
                ...self.__coreFields[k],
                ...(td.features.coreFieldControl.attributes[k] || td.features.coreFieldControl.attributes[self.__coreFields[k].key] || {}),
                name: (td.features.coreFieldControl.attributes[k] || td.features.coreFieldControl.attributes[self.__coreFields[k].key] || {})['label'] || self.__coreFields[k].name,
                isMandatory: true
            } : {
                ...self.__coreFields[k],
                ...(td.features.coreFieldControl.attributes[k] || td.features.coreFieldControl.attributes[self.__coreFields[k].key] || {}),
                name: (td.features.coreFieldControl.attributes[k] || td.features.coreFieldControl.attributes[self.__coreFields[k].key] || {})['label'] || self.__coreFields[k].name,
            }
        }), {}) : self.__coreFields;

        if (!self.currentTicket) {
            return Object.keys(baseCoreFields).reduce((acc, k) => ({
                ...acc,
                [k]: {
                    ...baseCoreFields[k],
                    isMandatory: baseCoreFields[k].mandatory === undefined ? baseCoreFields[k].isMandatory : baseCoreFields[k].mandatory,
                    isEditable: false,
                }
            }), {});
        }

        if (self.currentTicket.ticketDefinition.isAdhoc) {
            return Object.keys(baseCoreFields).reduce((acc, k) => ({
                ...acc,
                [k]: {
                    ...baseCoreFields[k],
                    isMandatory: baseCoreFields[k].mandatory === undefined ? baseCoreFields[k].isMandatory : baseCoreFields[k].mandatory,
                    isEditable: k === "category" ? false : true
                }
            }), {});
        }
        if (!(self.currentTicket.ticketDefinition.features.coreFieldControl && self.currentTicket.ticketDefinition.featureConfig.coreFieldControl && self.currentTicket.ticketDefinition.features.coreFieldControl[self.featureConfig.coreFieldControl.configKeys[1] || "isEditable"])) {
            return Object.keys(baseCoreFields).reduce((acc, k) => ({
                ...acc,
                [k]: {
                    ...baseCoreFields[k],
                    isMandatory: baseCoreFields[k].mandatory === undefined ? baseCoreFields[k].isMandatory : baseCoreFields[k].mandatory,
                    isEditable: false
                }
            }), {});
        }
        const editableCoreFields: string[] = self.currentTicket.ticketDefinition.features.coreFieldControl.isEditable[self.currentTicket?.status || ""] || [];
        return Object.keys(baseCoreFields).reduce((acc, k) => ({
            ...acc,
            [k]: {
                ...baseCoreFields[k],
                isMandatory: baseCoreFields[k].mandatory === undefined ? baseCoreFields[k].isMandatory : baseCoreFields[k].mandatory,
                isEditable: self.currentTicket?.canEdit && editableCoreFields.includes(k)
            }
        }), {});
    },
    get startableTicketTypes() {
        return self.ticketTypes.filter(({ allowedToInitiate }) => allowedToInitiate);
    },
    get popupIsOpen() {
        return !!self.currentTicket || !!self.currentTicketDefinition || !!self.bulkImport.isOpen;
    },
    get activeSpecialFilters() {
        let ret: { id: string; label: string; isActive: boolean; isAlwaysActive: boolean }[] = [];
        self.gridFilters.forEach(filt => {
            if (filt.isActiveForCurrentView) {
                ret.push({ id: filt.id, label: filt.label, isActive: filt.isActiveForCurrentView, isAlwaysActive: filt.alwaysActive.includes(self.currentView), });
            }
        });
        return ret;
    },
    get allSpecialFilters() {
        let ret: { id: string; label: string; isActive: boolean; isAlwaysActive: boolean; }[] = [];
        self.gridFilters.forEach(filt => {
            ret.push({ id: filt.id, label: filt.label, isActive: filt.isActiveForCurrentView, isAlwaysActive: filt.alwaysActive.includes(self.currentView), });
        });
        return ret;
    },
    get gridSpecialFilters() {
        let ret: { [K: string]: { id: string; value: string; isActive: boolean; }; } = {};
        self.gridFilters.forEach(sf => {
            sf.value.forEach(({ id, value }) => {
                if (ret[id] === undefined) {
                    ret[id] = { id, value, isActive: sf.isActiveForCurrentView }
                }
                else if (sf.isActiveForCurrentView && !ret[id].isActive) {
                    ret[id].isActive = sf.isActiveForCurrentView;
                }
            });
        });
        return Object.keys(ret).map(k => ret[k]);
    },
    get ticketsGrid() {
        if (!(self.gridKeys.length && self.tickets.length)) { return []; }
        const ticketsList = (self.currentView === "all" && !self.features.filteredViews?.hideAllTicketsView?.hidden) ? self.tickets : (self.filteredViewsMap.get(self.currentView)?.tickets || []);
        return ticketsList.map(ticket =>
            self.consolidatedGridKeys.reduce((acc, k) => k === "processVariables" ?
                ({
                    ...acc,
                    [k]: ticket.processVariables,
                })
                : k.includes("dropdown:") ?
                    ({
                        ...acc,
                        [k.replace("dropdown:", "")]: self.dynamicDropdowns.get(k.replace("dropdown:", ""))?.options || [],
                    })
                    : ({
                        ...acc,
                        [k]: Array.isArray(ticket[k]) ? [...ticket[k]] : ticket[k]
                    }), {}));
    },
    get categories() {
        const feature = self.featureConfig.moduleDefinition;
        if (self.features[feature.pathParam] && self.features[feature.pathParam][feature.configKeys[0] || ""]) {
            return self.features[feature.pathParam][feature.configKeys[0] || ""]?.metadata || [];
        }
        return [];
    },
    get columnsDef(): MRT_ColumnDef<RequestTicketDetails>[] {
        const newColDefs = transduce(
            self.filteredViews,
            compose(
                map(({ id }) => self.filteredViewsMap.get(id)),
                filter(fV => fV !== undefined),
                map(fV => fV!.columnOverrides),
                flatten(),
                map(colOv => colOv.colDef),
                filter((colDef) => colDef !== null),
            ), toObject<MRT_ColumnDef<RequestTicketDetails>, string, MRT_ColumnDef<RequestTicketDetails>>(a => a.id!, a => a));
        return Object.keys(newColDefs).map(k => newColDefs[k]);
    },
})).views(self => ({
    get columnsVisibility() {
        return {
            unread: false,
            delayed: false,
            ...self.columnsDef.reduce((acc, { id }) => ({ ...acc, [id!]: false }), {}),
            ...(self.filteredViewsMap.get(self.currentView)?.hideColumns || {}),
        };
    },
    get columnOrder(): string[] {
        return self.columnOrderMap.get(self.currentView)?.columnOrder || [];
    },
    get columnOverridenLabels() {
        return self.filteredViewsMap.get(self.currentView)?.overrideLabels || {};
    },
})).actions(self => ({
    setModuleTitle(val?: string) {
        if (val) {
            self.moduleTitle = val;
            localStorage.setItem(`${self.module}Title`, val);
        }
    },
    setFilteredViewMap(id: string, tickets: string[], columnOverrides: IColumnOverride[]) {
        // if (self.filteredViewsMap.has(id)) {
        //     self.filteredViewsMap.get(id)!.addTickets(tickets);
        // }
        // else {
        if (!self.filteredViewsMap.has(id)) {
            self.filteredViewsMap.set(id, { id, tickets, columnOverrides });
        }
        // }
    },
    setFeatureConfig(val: { [K: string]: IFeature; }) {
        self.featureConfig = val;
    },
    setPriorities(priorities: { id: string; name: string }[]) {
        self.priorities = priorities.map(({ id, name }) => ({ id, name, position: id.match(/([0-9]+)/g)?.length ? Number.parseInt(id!.match(/([0-9]+)/g)![0] || "0") : 0 })).sort(sortingStrategyPosition("position"));
    },
    setStatuses(statuses: { id: string; name: string; ticketType: string; statusCategory: string; }[]) {
        self.statuses = statuses;
    },
    setCoreFields(val: { [K: string]: any; }) {
        self.__coreFields = val;
    },
    setTicketTypes(val: SnapshotIn<ITicketType>[]) {
        self.ticketTypes.push(...val.map(val => ({ ...val, featureConfig: val.featureConfig || self.featureConfig })));
    },
    // setFeaturesForAllTicketTypes(feats: { feature: string;[K: string]: any; }[]) {
    //     let featByType = {};
    //     feats.forEach(({ feature, ...rest }) => {
    //         Object.keys(rest).forEach((typeId) => {
    //             featByType[typeId] = { ...(featByType[typeId] || {}), [feature]: { ...rest[typeId] } };
    //         })
    //     });
    //     self.ticketTypes.forEach(tT => {
    //         tT.setFeatures(featByType[tT.typeId] || {});
    //     });
    // },
    setFeaturesForAllTicketTypes(val: { [K: string]: { [K: string]: any; }; }) {
        self.ticketTypes.forEach(tT => {
            if (val[tT.typeId]) {
                tT.setFeatures(val[tT.typeId]);
            }
        });
    },
    scrubTicket(ticketId: string) {
        const tckt = self.tickets.find(({ id }) => id === ticketId);
        if (tckt) {
            tckt.history.clear();
            tckt.commentDetailList.clear();
            tckt.tasks.clear();
        }
    },
    setColumnOrder(val: string[]) {
        if (!val.length) { return; }
        const realVal = val.filter(v => v !== undefined)
        if (!realVal.length) { return; }
        if (!self.columnOrderMap.has(self.currentView)) {
            self.columnOrderMap.set(self.currentView, { id: self.currentView, columnOrder: realVal });
        }
        else {
            const colOrd = self.columnOrderMap.get(self.currentView)?.columnOrder;
            colOrd?.clear();
            colOrd?.push(...realVal);
        }
        localStorage.setItem("ticketColumnOrder", JSON.stringify(getSnapshot(self.columnOrderMap)));
    },
    getTicketTypeTitle(typeId: string, ticketId: string) { //to fetch ticket type name
        return self.ticketTypes.find(tt => tt.typeId === typeId)?.title || self.tickets.find(t => t.id === ticketId)?.requestTypeTitle || typeId || "";
    },
    setCurrentView(val?: string) {
        self.currentView = val || self.filteredViews[0]?.id || "all";
    },
})).actions(self => ({
    setCurrentTicket(id: string | undefined) {
        if (id === self.currentTicket?.id) {
            console.log("skipping redundant ticket");
            return;
        }
        console.log("setting current tickt", id);
        if (!id && self.currentTicket?.id) {
            // TODO: Uncomment when you face problems related to currentTask ref
            // destruction
            // self.currentTicket.setCurrentTask();
            self.scrubTicket(self.currentTicket.id);
        }
        self.currentTicket = resolveIdentifier(Ticket, self.tickets, id || "");
        if (self.currentTicket) {
            self.currentTicket.setPermissions();
            setTimeout(() => self.currentTicket?.mark(false));
            const unread = (getRoot(self) as Instance<typeof LateStoreModel>).notifications.notificationsQ.filter(({ ticketId: tId }) => tId === self.currentTicket!.id);
            if (unread.length) { unread.forEach((un: INotification) => un.mark()); }
        }
    },
    setCurrentTicketDefinition(id: string | undefined) {
        if (id === self.currentTicketDefinition?.typeId) {
            console.log("skipping redundant ticket def");
            return;
        }
        self.currentTicketDefinition = resolveIdentifier(TicketType, self.ticketTypes, id || "");
    },
    getTicketsList: flow(function* getTicketsList() {
        if (self.loading) { return; }
        self.loading = true;
        self.tickets.clear();
        self.filteredViewsMap.clear();
        try {
            const { data } = yield getTickets({ tenant: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId, moduleId: self.module, projectId: (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo.currentProject.id });
            const { tickets, views } = data.reduce((acc, { id, priority, priorityTitle, filteredViews, ...rest }) => ({
                tickets: [...acc.tickets, {
                    ...rest, id,
                    priority,
                    priorityTitle: priorityTitle || priority,
                    filteredViews: filteredViews || [],
                    unread: (getRoot(self) as Instance<typeof LateStoreModel>).notifications.notificationsQ.some(({ notificationStatus, ticketId }) => ticketId === id && notificationStatus === NotificationState.UNREAD)
                }],
                views: (filteredViews && filteredViews.length) ?
                    filteredViews.reduce((accum, v) => ({
                        ...accum,
                        [v]: [...(acc.views[v] || []), id]
                    }), { ...acc.views })
                    :
                    {
                        ...acc.views,

                    },
            }), { tickets: [], views: {} });
            self.tickets.push(...tickets);
            Object.keys(views).forEach(v => {
                const colOvers = (self.features?.filteredViews?.columnOverrides || {})[v] || [];
                self.setFilteredViewMap(v, views[v] || [], colOvers);
            });
            if (!!self.features.filteredViews?.hideAllTicketsView?.hidden && self.currentView === "all") {
                self.setCurrentView();
            }
        }
        catch (err) { console.error(err); }
        finally { self.loading = false; }
    }),
    addDynamicDropdown(id: string, options: { id: string; name?: string; }[]) {
        if (!self.dynamicDropdowns.has(id)) {
            self.dynamicDropdowns.set(id, { id, options });
        }
    }
})).actions(self => ({
    refreshCoreTicket: flow(function* refreshCoreTicket(ticketId: string, updateUnread?: boolean) {
        const tckt: ITicket | undefined = self.tickets.find(({ id }) => id === ticketId);
        if (!tckt) {
            if (!self.currentTicket) {
                yield self.getTicketsList();
            }
            else {
                try {
                    const { data } = yield getTickets({ tenant: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId, moduleId: self.module, projectId: (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo.currentProject.id });
                    //TODO: Maybe update filteredViewsMap here.
                    const newDeets = data.slice(0, Math.max(0, data.findIndex(({ id }) => id === self.tickets[0].id)));
                    self.tickets.unshift(...newDeets.map(({ id, priority, priorityTitle, ...rest }) => ({
                        ...rest, id,
                        priority,
                        priorityTitle: priorityTitle || priority,
                        unread: (getRoot(self) as Instance<typeof LateStoreModel>).notifications.notificationsQ.some(({ notificationStatus, ticketId }) => ticketId === id && notificationStatus === NotificationState.UNREAD)
                    })));
                }
                catch (err) { console.error(err); }
            }
            return;
        }
        try {
            tckt.setLoading(true);
            const { data } = yield getTickets({ tenant: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId, moduleId: self.module, ticketId, projectId: (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo.currentProject.id });
            applySnapshot(tckt, { ...getSnapshot<any>(tckt), ...data, watchers: data.watchers || [], filteredViews: data.filteredViews || [], unread: updateUnread ? updateUnread : tckt.unread, dueDate: data.dueDate || "" })
        }
        catch (err) { console.error(err); }
        finally { tckt.setLoading(false); }
    }),
    setGridFilters(val: IGridFilter[]) {
        self.gridFilters.clear();
        val.forEach(v => {
            self.gridFilters.set(v.id, v);
        });
    },
    addGridKeys(val: string[]) {
        self.gridKeys.push(...val);
    },
    setFilterRowsCount(val: number) {
        self.filteredRowsCount = val;
    },
    setModuleMetadata({ coreFields, featureConfig, gridKeys, gridFilters }) {
        // self.setCoreFields(coreFields);
        // self.setFeatureConfig(features);
        self.__coreFields = coreFields;
        self.featureConfig = featureConfig;
        self.gridKeys.clear();
        self.gridKeys.push(...gridKeys);
        self.gridFilters.clear();
        applySnapshot(self.gridFilters, gridFilters.reduce((acc, { id, ...rest }) => ({ ...acc, [id]: { id, ...rest } }), {}));
        // gridFilters.forEach(v => {
        //     self.gridFilters.set(v.id, v);
        // });
    },
    getTags: flow(function* getTags(force: boolean = true) {
        try {
            if (!self.tags.length || force) {
                self.tags.clear();
                const { data } = yield listTags({ tenant: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId });
                if (data && data.length) {
                    self.tags.push(...data.sort());
                }
            }
        }
        catch (err) { console.error(err); }
    }),
    renameTagAndReplace: flow(function* renameTagAndReplace({ oldTag, newTag }: { oldTag: string; newTag: string; }) {
        if (self.tags.includes(oldTag)) {
            try {
                const { data } = yield renameTag({
                    tenant: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId,
                    payload: {
                        tagId: oldTag,
                        newTagId: newTag,
                        userId: (getRoot(self) as Instance<typeof LateStoreModel>).auth.userId,
                        moduleId: self.module
                    }
                });
                // self.tagsToTicketsMap[oldTag];
                applyAction(self.tickets, self.tagsToTicketsMap[oldTag]
                    .map(({ path }) => ({
                        name: 'renameTag',
                        path: "/" + path.split('/').slice(-1).join(''),
                        args: [{ oldTag, newTag }]
                    })));
                self.tags.remove(oldTag);
                self.tags.push(newTag);
            }
            catch (err) {
                console.error(err);
                if (!self.tags.includes(oldTag)) {
                    // self.tagsToTicketsMap[newTag];
                    applyAction(self.tickets, self.tagsToTicketsMap[newTag]
                        .map(({ path }) => ({
                            name: 'renameTag',
                            path: "/" + path.split('/').slice(-1).join(''),
                            args: [{ oldTag: newTag, newTag: oldTag }]
                        })));
                    self.tags.remove(newTag);
                    self.tags.push(oldTag);
                }

            }
        }
    }
    ),
    getAllowedWatchers: flow(function* getAllowedWatchers(variable: string) {
        try {
            const { data: { data } } = yield getDropdownOption({ customerId: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId, variableType: variable, projectId: (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo.currentProject.id, })
            self.allowedWatchers.clear();
            self.allowedWatchers.merge(data.map(({ id, ...rest }) => [id, { id, ...rest }]));
        }
        catch (err) { console.error(err); }
    }),
    removeTicket(ticket: ITicket) {
        if (self.currentTicket?.id === ticket.id) {
            self.setCurrentTicket(undefined);
        }
        return self.tickets.remove(ticket);
    },
    getPermission: flow(function* getPermission(resourceType: string, permission: string) {
        try {
            const customerId = (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId;
            const projectId = (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo.currentProject.id;
            const { data } = yield listPermissionedResources({
                customerId,
                projectId,
                moduleId: self.module,
                resourceType,
                permission,
                subjectType: "user",
                subjectId: (getRoot(self) as Instance<typeof LateStoreModel>).auth.userId,
            });
            if (!data) { return { [permission]: [] }; }
            // const prefix = getPrefixForResourceType(resourceType, { customerId, projectId, moduleId: self.module });
            // return { [permission]: data.map((resourceId: string) => resourceId.replace(prefix, "")) };
            return { [permission]: data.resourceIdList };
        }
        catch (err) { console.error(err); }
    }),

})).actions(self => ({
    setFeaturesForModule(val: { [K: string]: { [K: string]: any; }; }) {
        self.features = val;
        /* Used to sync changes needed for the main observable related to features if the features weren't set before tickets got set.
           TODO: Refactor into its own action if it gets more complicated
        */
        if (self.filteredViewsMap.size && val.filteredViews?.columnOverrides) {
            self.filteredViewsMap.forEach(fV => {
                fV.addColumnOverrides(val.filteredViews.columnOverrides[fV.id] || []);
            });
        }

        if (self.filteredViewsMap.size && val.filteredViews?.allowProjectSwitch) {
            self.filteredViewsMap.forEach(fV => {
                // if((val.filteredViews?.allowProjectSwitch || {})[fV.id] )
                if (val.filteredViews?.allowProjectSwitch[fV.id])
                    fV.setProjectSelection((val.filteredViews?.allowProjectSwitch).projects || [], (val.filteredViews?.allowProjectSwitch).label);
            });
        }

        if (val.filteredViews?.specialCondition) {
            Object.keys(val.filteredViews?.specialCondition || {})
                .forEach(view => { __stubSpecialFilterParser(val.filteredViews!.specialCondition[view] || {}).forEach(filt => { self.gridFilters.get(filt)?.pushAlwaysActive(view) }); });
        }
    },
    getPermissions: flow(function* getPermissions() {
        self.permissions.clear();
        try {
            for (const resourceType in self.resourcePermissions) {
                const datas = yield Promise.all(self.resourcePermissions[resourceType].map(p => self.getPermission(resourceType, p)));
                const permissions = datas.reduce((acc, obj) => ({ ...acc, ...obj }), {});
                self.permissions.set(resourceType, { resourceType, permissions });
            }
        }
        catch (err) { console.error(err); }
    }),
    getModuleTitleFromDashboard: flow(function* getModuleTitleFromDashboard() {
        if (!self.moduleTitle.length) {
            try {
                const { modules } = yield (getRoot(self) as Instance<typeof LateStoreModel>).fetch(GET_TICKET_MODULE_TITLE, { variables: { project: (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo.currentProject.id, module: self.module, } });
                if (modules && modules.length) {
                    const dashboardName = modules.find(({ embedConfig }) => embedConfig?.view === self.currentView)?.dashboardName || modules[0].dashboardName;
                    self.setModuleTitle(dashboardName);
                }
            }
            catch (err) { console.error(err); }
        }
    }),
    toggleSwtichUI() {
        self.switchedUI = !self.switchedUI;
        localStorage.setItem('switchedUI', JSON.stringify(self.switchedUI))
    }
})).volatile(self => ({
    filteredListener: onPatch(self.tickets, ({ op, path, value }, { value: oldVal }) => {
        const pathArr = path.split('/');
        if (pathArr.slice(-2)[0] === 'filteredViews') {
            const t = resolvePath(self.tickets, pathArr.slice(0, 2).join('/'));
            if (t === undefined) { console.log('WROONG'); return; }
            if (op === 'add') {
                self.filteredViewsMap.get(value)?.addTicket(t);
            }
            else if (op === 'remove') {
                self.filteredViewsMap.get(oldVal)?.removeTicket(t);
            }
        }
    }),
    pathListener: onPatch((getRoot(self) as Instance<typeof LateStoreModel>).params, ({ op, path, value }) => {
        if (path === "/section" && op === "replace" && value === self.module && !self.moduleTitle.length) {
            const title = localStorage.getItem(`${self.module}Title`);
            if (title && title.length) {
                self.setModuleTitle(title);
            }
            else {
                self.getModuleTitleFromDashboard();
            }
        }
    }),
    observeColumnsDef: reaction(() => self.columnsDef, v => {
        // TODO: Probably don't directly import the default columns as a constant but have it be am observable state
        if (localStorage.getItem("ticketColumnOrder")) { return; }
        const configuredColOrd = self.features.filteredViews?.columnOrder;
        if (configuredColOrd && v.length) {
            const defaultColumnOrder = [...defaultColumns, ...v.map(({ id }) => id!)];
            Object.keys(configuredColOrd).forEach(fVKey => {
                const colOrdSet: string[] = [...(new Set<string>([...configuredColOrd[fVKey], ...defaultColumnOrder]))];
                if (self.columnOrderMap.has(fVKey)) {
                    self.columnOrderMap.get(fVKey)?.setColumnOrder(colOrdSet);
                }
                else {
                    self.columnOrderMap.set(fVKey, { id: fVKey, columnOrder: colOrdSet });
                }
            });
        }
    }),
    observeFilteredViewSize: reaction(() => self.filteredViewsMap.size, fvMap => {
        if (self.filteredViewsMap.size && self.features.filteredViews?.allowProjectSwitch) {
            self.filteredViewsMap.forEach(fV => {
                // if((self.features.filteredViews?.allowProjectSwitch || {})[fV.id] )
                if (self.features.filteredViews?.allowProjectSwitch[fV.id])
                    fV.setProjectSelection((self.features.filteredViews?.allowProjectSwitch[fV.id]).projects || [], (self.features.filteredViews?.allowProjectSwitch[fV.id]).label);
            });
        }
    })
})).actions(self => ({
    clear() {
        applySnapshot(self, { bulkImport: {} });
        const __clearStoreKeys = ["ticketColumnOrder"];
        __clearStoreKeys.forEach(k => { localStorage.removeItem(k); });
    },
})).actions(self => ({
    beforeDetach() {
        self.filteredListener();
        self.pathListener();
        self.observeColumnsDef();
        self.observeFilteredViewSize();
    },
    afterAttach() {
        const colOrd = localStorage.getItem("ticketColumnOrder");
        try {
            if (colOrd) {
                applySnapshot(self.columnOrderMap, JSON.parse(colOrd));
            }
        }
        catch (err) {
            console.error("Columns Order as persisted in local storage is in incorrect format: ", colOrd);
            localStorage.removeItem("ticketColumnOrder");
        }
    },
}));

export interface IUser extends Instance<typeof User> { };
export interface IGridFilter extends Instance<typeof GridFilter> { };
export interface ITicketType extends Instance<typeof TicketType> { };
export interface IFormField extends Instance<typeof FormField> { };
export interface ITask extends Instance<typeof Task> { };
export interface ITicket extends Instance<typeof Ticket> { };
export interface ITicketModule extends Instance<typeof TicketModule> { };
export interface ICompletedTask extends Instance<typeof CompletedTask> { };
export interface IFilteredView extends Instance<typeof FilteredView> { };
export interface IPermission extends Instance<typeof Permission> { };
export interface IColumnOverride extends Instance<typeof ColumnOverride> { };
export interface IColumnOrder extends Instance<typeof ColumnOrder> { };
export interface IBulkImport extends Instance<typeof BulkImport> { };
export interface IBulkImportRequest extends Instance<typeof BulkImportRequest> { };
export interface IUserTaskType extends Instance<typeof UserTaskType> { };
