import React from 'react';
import { Button, Group, Select, Stack, Text, Loader, Grid, ScrollArea, Tooltip, ActionIcon, CloseButton } from '@mantine/core';
import { observer } from 'mobx-react-lite';
import { IconDownload, IconX, IconUpload, IconPhoto, IconAlertHexagonFilled, IconReload, IconAlertTriangleFilled, IconAlertHexagon, IconAlertTriangle } from '@tabler/icons-react';
import { IBulkImport, IBulkImportRequest, ITicketType } from '../../../models/Ticket';
import { Dropzone } from '@mantine/dropzone';
import { notifications } from '@mantine/notifications';
import { BulkRequestStatuses } from '../../../models/enums';
import useStyles from '../TicketExcelImportStyles';
import NoDataFound from '../../../pages/fbtReports/NoDataFound';
import DropOrUpload from '../DropUploadZone';
import BulkImportRequestHistoryRow from '../RequestHistoryRow';


// const useStyles = makeStyles((theme: Theme) => ({
// //  iconStyles: { width: '1.3em', height: '1.3em', paddingRight: '0.35em', color: '#005eff', },
// //  iconDivStyles: { display: "flex", justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', '&:hover': { color: '#005eff' } },
// //  dropozone: (props: { disabled: boolean }) => ({ cursor: props.disabled ? "default" : "pointer", border: props.disabled ? "0.1em solid #ececec" : "0.1em dashed #d9d9d9", '&:hover': { background: props.disabled ? '#fff' : '#f8f9fa' } })
// actionButton: {}
//  }));

// const MAX_SIZE = 25 * 1024 ** 2;

// const DropOrUpload = observer(({ model, label, caption }: { model: IBulkImport; label: string; caption: string }) => {
//   return (
//     <Stack w="100%" spacing="xs">
//       <Group position="apart"
//        align="center" >
//         <Text
//           fw={600}
//           // my="0.5em"
//           mb={'0.1em'}
//           fz="0.8525em"
//           c="#000"
//           opacity="1"
//         >{label}
//           <span style={{ color: "red", marginLeft: "0.25em" }}>*</span>
//         </Text>
//         <Text
//           fw={400}
//           // my="0.5em"
//           mb={'0.5em'}
//           fz="0.6525em"
//           c="#585858"
//           opacity="0.8"
//         >{caption}
//         </Text>
//       </Group>
//       <Dropzone
//         onDrop={(files) => {
//           console.log('accepted files', files, 'size', files[0].size, 'MAX_SIZE', MAX_SIZE);
//           if (files[0].size < MAX_SIZE) model.makeBulkImportRequest(files[0])
//         }}
//         onReject={(files) => {
//           console.log('rejected files', files);
//           notifications.show({ title: 'Failed!', message: `Maximum file upload size is 25 MB.`, color: 'red', icon: <IconX /> })
//         }}
//         name={label}
//         activateOnClick
//         w="100%"
//         styles={{
//           inner: { pointerEvents: "all", cursor: model.disabled ? "not-allowed" : "pointer" },
//           root: model.disabled ? {
//             backgroundColor: "#eaeaea",
//             borderColor: "#585858",
//             cursor: 'not-allowed',
//             border: '0.1em dashed #ececec',
//             borderRadius: 0,
//             '&:hover': {
//               backgroundColor: "#eaeaea",
//               color: "#888888",
//               cursor: 'not-allowed',
//             },
//           } : {
//             borderRadius: 0,
//             border: '0.1em dashed #005eff',
//           }
//         }}
//         maxSize={MAX_SIZE}
//         disabled={model.disabled}
//       >
//         {model.loading ?
//           <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//             <Loader size={'xs'} />
//           </div> : (
//             <Group position="center" spacing="xl" style={{ pointerEvents: 'none', alignSelf: 'baseline', }}>
//               <Dropzone.Accept>
//                 <IconUpload
//                   size="1.2em"
//                   stroke={1.5}
//                 />
//               </Dropzone.Accept>
//               <Dropzone.Reject>
//                 <IconX
//                   size="1.2em"
//                   stroke={1.5}
//                 />
//               </Dropzone.Reject>
//               <Dropzone.Idle>
//                 <IconPhoto size="1.2em" stroke={1.5} />
//               </Dropzone.Idle>
//               <div>
//                 <Text size="xs" inline>
//                   {model.disabled ? "Select a Request Type to upload data. Alternatively, you can download the template." : "Drag files here or click to select files"}
//                 </Text>
//               </div>
//             </Group>)
//         }
//       </Dropzone>
//     </Stack >
//   );
// });

// const BulkImportRequestHistoryRow = observer(({ request }: { request: IBulkImportRequest }) => {
//   return (
//   <Grid justify="space-around" mx={0} >
//     <Grid.Col sx={{ borderLeft: "0.0525em solid #585858", borderBottom: "0.0525em solid #585858", padding: 10, alignSelf: 'end', fontSize: '0.85em' }} span={2}><Tooltip label={request.requestId}><Text truncate>{request.requestId}</Text></Tooltip></Grid.Col>
//     <Grid.Col sx={{ borderLeft: "0.0525em solid #585858", borderBottom: "0.0525em solid #585858", padding: 10 , alignSelf: 'end', fontSize: '0.85em'}} span={4} > {request.ticketType}</Grid.Col >
//     <Grid.Col sx={{ borderLeft: "0.0525em solid #585858", borderBottom: "0.0525em solid #585858", padding: 10 , alignSelf: 'end', fontSize: '0.85em'}} span={4} > {request.statusText}</Grid.Col >
//     <Grid.Col sx={{ borderLeft: '0.0525em solid #585858', borderBottom: "0.0525em solid #585858", borderRight: "0.0525em solid #585858", justifyItems: "center",alignSelf: 'end' }} span={2} >
//       <button 
//       style={{
//         display: 'flex', 
//         justifyContent: 'center', 
//         alignItems: 'center', 
//         cursor: request.disabled ? 'default' : 'pointer',
//         color: (request.disabled) ? '#585858' : request.status === BulkRequestStatuses.PENDING ? 'orange'  : '#005eff',
//         border: 0,
//         background: 'transparent',

//       }} 
//       disabled={request.disabled} 
//       onClick={() => { request.download(); }}>
//       <Tooltip label={request.disabled ? `Failed` : request.status === BulkRequestStatuses.PENDING ? `Click to Check progress` : `Click to Download`}>
//       <span style={{ fontSize: '0.85em', marginRight: '0.5em'}}>{ request.disabled ? `Failed` : request.status === BulkRequestStatuses.PENDING ? `Check progress` : `Download`}</span>
//       </Tooltip>
//       {request.disabled ? <IconAlertHexagonFilled size="1em" /> : request.status === BulkRequestStatuses.PENDING ? <IconReload size="1em" /> : <IconDownload size="1em" />}
//       </button>
//     </Grid.Col >
//   </Grid >
// )});

const TicketExcelImport = ({ importModel }: { importModel: IBulkImport }) => {
  const classes = useStyles();
  return (
    <Stack align="center" 
    // mx="1rem" 
    w="100%" spacing="xs" p=".5rem">
      {/* <Group position='apart' w="100%" style={{display: 'flex', alignItems: 'center'}}>
      <Text fz="xl" fw={800} mb={'1em'} className={classes.title}>Bulk Import</Text>
      <div className={classes.closeButton}><CloseButton color='red' title='Close' onClick={onClose} /></div>
      </Group> */}
      <Grid w="100%" mx="2rem" align="flex-start">
        <Grid.Col span={6} style={{marginBottom: '1em'}}>
          <Group className={classes.ticketTypeGroup}>
            <Select
              label="Request Type"
              data={importModel.startableTicketTypes.map((tT: ITicketType) => ({ value: tT.typeId, label: tT.title }))}
              value={importModel.selectedTicketType?.typeId}
              onChange={(val) => { if (val) { importModel.selectTicketType(val); } }}
              placeholder={'Choose a request type to download excel template'}
              className={classes.dropdown}
            />
            <Tooltip label="Download Template File (Excel)">
              <Button className={classes.dropdownButton} disabled={importModel.disabled} onClick={() => { importModel.downloadTemplate(); }}>
                <span className={classes.downloadText}>Template</span>
                <IconDownload size="1.5em" />
              </Button>
            </Tooltip>
          </Group>
        </Grid.Col>
      </Grid>
      <Grid w="100%" mx="2rem" align="flex-start" mt={'1em'}>
      <Grid.Col span={6} >
          <Group>
            <DropOrUpload
              label="Upload Excel Here"
              model={importModel}
              caption={'Ensure fields are filled as per guidelines'}
              disabled={!importModel.selectedTicketType}
              actionType={"create"}
            />
          </Group>
        </Grid.Col>
      </Grid>
      <div className={classes.tableTitleDiv}>
      <Text fz="lg" fw={750} mr={'0.5em'}>Jobs</Text>
      <span className={classes.tableTitleSmall}>(Showing last 10 results)</span>
      </div>
      <Grid sx={{  border: '0.05em solid #585858' }} justify="space-around" mx="2rem" w="100%">
        <Grid.Col span={2}><Text fw={600}>Job ID</Text></Grid.Col>
        <Grid.Col span={4}><Text fw={600}>Request Type</Text></Grid.Col>
        <Grid.Col span={4}><Text fw={600}>Job Status</Text></Grid.Col>
        <Grid.Col span={2}> <Text fw={600} align="center">Check & Download</Text> </Grid.Col>
      </Grid>
      {importModel.history.length ?
        <ScrollArea h={400} type="auto" w="100%" >
          {importModel.history.map((request: IBulkImportRequest) => (
            <BulkImportRequestHistoryRow key={request.requestId} request={request} />
          ))}
        </ScrollArea>
        :
        <Group position="center" align="center" w="100%" p="1rem" bg="#f9f9f9" mih={100}>
          {/* <NoDataFound title='No jobs found.' titleStyle={{ display: 'flex', fontSize: '0.75em', fontWeight: 400 }}/> */}
          <IconAlertTriangle size={'1.25em'} /> <span>No jobs found.</span>
        </Group>
      }
    </Stack>
  );

};

export default observer(TicketExcelImport);

