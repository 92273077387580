import * as React from 'react';
import { Button, makeStyles, LinearProgress, Box, Tooltip } from '@material-ui/core';
import { useStore } from '../models/ProvideModel';
import { useObserver } from 'mobx-react-lite';
import { useLocation } from "react-router";
import { getSnapshot } from 'mobx-state-tree';
import { memo } from 'react';

export interface IDownloadPDFProps {
  title: string,
  reportName: string,
  limit: string | number
}

const useStyles = makeStyles(theme => ({
  button: {
    marginLeft: "8px",
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "12px",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.30,
    letterSpacing: "normal",
    backgroundColor: "#f4f4f4",
    color: "#005EFF",
    borderRadius: "2px",
    boxShadow: "none !important",
    textTransform: "capitalize",
    height: "28px",
    width: "96%",
    "&:hover": {
      backgroundColor: "#f4f4f4",
      textDecoration: "none",
      fontWeight: "bold"
    },
    '@media print': {
      display: "none"
    }
  },
  buttonSm: {
    margin: "0px 0px 0px 7px",
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "12px",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.30,
    letterSpacing: "normal",
    backgroundColor: "#f4f4f4",
    color: "#005EFF",
    borderRadius: "2px",
    boxShadow: "none !important",
    textTransform: "capitalize",
    height: "28px",
    width: "100%",
    "&:hover": {
      backgroundColor: "#f4f4f4",
      textDecoration: "none",
      fontWeight: "bold"
    },
    '@media print': {
      display: "none"
    }
  },
  hoverDiv: { padding: "5px 10px", border: "solid 1px #005EFF" },
  hoverText: {
    fontSize: "12px",
    fontFamily: "Source Sans Pro, sans-serif !important",
    color: "#FFF",
    fontWeight: 700,
    lineHeight: 1.29,
    letterSpacing: "normal",
  },
  popper: {
    margin: "10px"
  },
  loading: {
    margin: theme.spacing(1),
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "12px",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.30,
    letterSpacing: "normal",
    backgroundColor: "#f4f4f4",
    background: "#f4f4f4",
    color: "#585858",
    borderRadius: "2px",
    boxShadow: "none !important",
    textTransform: "capitalize"
  },
  colorPrimary: {
    backgroundColor: '#f4f4f4',
  },
  barColorPrimary: {
    backgroundColor: '#005EFF',
  },
  download: {
    margin: theme.spacing(1),
    fontFamily: "Source Sans Pro, sans-serif !important",
    fontSize: "12px",
    fontWeight: 600,
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.30,
    letterSpacing: "normal",
    backgroundColor: "#f4f4f4",
    color: "#005EFF",
    borderRadius: "2px",
    boxShadow: "none !important",
    textTransform: "capitalize",
    height: "28px",
    width: "130px",
    "&:hover": {
      backgroundColor: "#f4f4f4",
      textDecoration: "none",
      fontWeight: "bold",
    },
    '@media print': {
      display: "none"
    },
  },
}));

const DownloadPDF = (props: IDownloadPDFProps) => {
  const classes = useStyles();
  const store = useStore();
  const { pathname, search } = useLocation();
  const { fullScreenMode } = store.responsiveUtils;
  let path = process.env.REACT_APP_SITE_URL + pathname
  let filters = search === '' ? '?view=print' : search + '&view=print';
  let pageURL = path + filters
  let reportTitle = (store.downloadpdf.reportName) + ' - ' + ((store.params.phase === "none") ? "ALL PHASES" : store.params.phase) + ' - ' + ((store.params.spaceType === "none") ? "ALL SPACE TYPES" : store.params.spaceType)
  let projectName = store.auth.getCustomer().trim().replace(/ /g, "-") + "-" + store.projectInfo.currentProject.name.trim().replace(/ /g, "-")
  let section = store.params.section === 'snags' ? 'Snag' : store.params.section === 'unit-act-info' ? 'Activity' : ''
  //handler function to generate PDF
  const handleData = () => {
    let pageData = {
      pageURL,
      projectInfo: getSnapshot(store.projectInfo),
      projectName,
      reportTitle,
      reportType: section
    }
    store.download.download(pageData);
    store.exportTableFilters.setPdfPopupOpen(true)
  }

  //onHover tooltip component
  const HandleHover = () => {
    return (
      <React.Fragment>
        <div className={classes.hoverDiv}>
          <Box display="flex" justifyContent="space-between">
            <span className={classes.hoverText}>Application can't export more than 1000 records at a time as PDF.
              To enable PDF export please apply a filter to reduce the number of records and try again.</span>
          </Box>
        </div>
      </React.Fragment>
    )
  }



  const smMode = (isSm) => {
    return isSm ? "17%" : "10%"
  }

  return useObserver(() => (
    <p style={{ marginLeft: "8px" }}>
      {!fullScreenMode && <Tooltip title={store.downloadpdf.limit > 1000 ? <HandleHover /> : ""} placement="bottom" classes={{ tooltip: classes.popper }} leaveDelay={200}>
        <span>
          <Button
            variant="contained"
            color="primary"
            size="small"
            className={store.responsiveUtils.currentViewport.isLg ? classes.button : classes.buttonSm}
            disabled={store.download.isBusy ? true : (store.downloadpdf.limit <= 0 || store.downloadpdf.limit > 1000) ? true : false}
            onClick={store.download.loading ? () => { } : handleData}
          >
            {(store.download.loading || (store.downloadpdf.limit <= 0 || store.downloadpdf.limit > 1000)) ? null : <img src={require('../assets/images/downloadIcons/download2-small.png')} width={15} height={15} style={{ marginRight: 5 }} />}
            {store.download.loading ? <div className={classes.loading}>EXPORTING PDF <LinearProgress classes={{ colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary }} /></div> : store.downloadpdf.title ? store.downloadpdf.title : "Export PDF"}
            {/* {loading ? getDialog() : null} */}
          </Button>
        </span>
      </Tooltip>}
    </p>
  ));
}

export default memo(DownloadPDF);

