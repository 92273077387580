import React, { memo, RefObject } from 'react';
import TabsComponent from './tabsComponent/TabsComponent';
import { makeStyles, Theme } from "@material-ui/core/styles";
import { IChan } from 'csp-with-ts';
/**Components */
import RequestPopupHeader from './header/Header';
// import RequestPopupCore from '../materialFormPopup/RequestPopupCore';
import HistoryComments from './historyComments/HistoryComments';
import RequestPopupCoreOld from './coreFields/CoreFields';
import RequestPopupCore from './coreFields/CoreFieldsV2';
import { IFile } from './tabItems/attachments/Attachment';
import { ITicket } from '../../models/Ticket';
import { observer } from 'mobx-react-lite';
import { onPatch } from 'mobx-state-tree';
import { useStore } from '../../models/ProvideModel';

const useStyles = makeStyles((theme: Theme) => ({
  root: { display: "flex", flexDirection: "column", height: "100%", width: "100%", padding: '0.5em' },
  main: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    flexBasis: "auto",
    flexGrow: 9,
    overflow: "overlay",
    border: "0.01em solid #E9E9E9",
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap'
    },
    [theme.breakpoints.up('md')]: {
      height: '100vh'
    }
  },
  leftSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRight: '0.01em solid #D9D9D9',
    [theme.breakpoints.down('sm')]: {
      borderRight: 'none'
    }
  },
  rightSection: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexShrink: 1,
    width: "75%",
    flexBasis: "auto",
    // overflow: "scroll",
  }
}))
/* This component is the parent container for new ticket popup which contains various other related components.
 * Receive all the props that are required by various components here in this component.
 * Props for:
 * Header component - To show title and close button
 * Core fields component - To show all core fields on left section
 * Tabs view
 * Details - To show ticket description and general information (general form)
 * Tasks - To show one or more tasks (paralell tasks)
 * Attachments - To show all attachments related to the ticket.
 * History & Comments
 */

interface ITicketPopup {
  //Header props
  //Core field props
  ticket: ITicket;
  priorities: { id: string; name: string; }[];
  coreFields: { [K: string]: any; };
  ch: IChan;
  statuses: { id: string; name: string;[K: string]: string; }[];
  //History and Comment props
  customerId: string;
  userId: string;
  lg?: boolean;
  projectId: string;
  onClose: () => void;
  refreshTicket: (id: string) => Promise<any>;
  tags: string[];
  categories: string[];
}

interface IAttachmentProp { filesList: IFile[], openRef: RefObject<() => void>, usersMap: { [K: string]: string }, loading: boolean }

const TicketPopup = ({ ticket, priorities, coreFields, ch, statuses, customerId, userId, lg, projectId, onClose, refreshTicket, tags, categories }: ITicketPopup) => {
  const classes = useStyles();
  const store = useStore();
  const openRef = React.useRef<() => void>(null);
  // const getAttachmentsFromComments = (ref: RefObject<() => void>) => {
  //     openRef = ref
  // }

  React.useEffect(() => onPatch(ticket, ({ op, path, value }, { value: oldVal }) => {
    if (path == '/tasksLoading' && op == 'replace' && value == false && oldVal == true) {
      if (ticket.tasks.length) {
        ticket.setCurrentTask(ticket.tasks[0].taskId)
      }
    }
  }
  ), [])

  return (
    <div className={classes.root}>
      <div>
        <RequestPopupHeader
          id={ticket.id || ""}
          title={ticket?.popupLoading ? "Loading..." : `${ticket?.name}`}
          closeAction={onClose}
          ticket={ticket}
          coreFields={coreFields}
          ch={ch}
        />
      </div>
      <div className={classes.main}>
        <div className={classes.leftSection}>
            <div>
            { store.ticket?.switchedUI?
              <RequestPopupCore
              ticket={ticket}
              priorities={priorities}
              coreFields={coreFields}
              statuses={statuses}
              tags={tags}
              userId={userId}
              categories={categories}
              ch={ch} />
              :
              <RequestPopupCoreOld
              ticket={ticket}
              priorities={priorities}
              coreFields={coreFields}
              statuses={statuses}
              tags={tags}
              userId={userId}
              categories={categories}
              ch={ch} />
            }
          </div>
          <TabsComponent
            filesList={ticket.files}
            usersMap={ticket.processVariables.usersMap}
            openRef={openRef}
            loading={false}
            ticket={ticket}
            coreFields={coreFields}
            projectId={projectId}
            customerId={customerId}
            userId={userId}
            lg={lg}
            onClose={onClose}
            taskIdValue={ticket.currentTask?.taskId || ""}
            ch={ch}
            refreshTicket={refreshTicket}
          />
        </div>
        <div className={classes.rightSection}>
          <HistoryComments
            ticket={ticket}
            customerId={customerId}
            userId={userId}
            lg={lg}
            projectId={projectId}
            openRef={openRef}
          />
        </div>
      </div>

    </div>
  )
}

export default observer(TicketPopup)
