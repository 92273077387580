import React, { useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  TextField,
  TextareaAutosize
} from '@material-ui/core';
import { IDownloadPdf } from '../../models/Download';
import { observer } from 'mobx-react-lite';
import shareIcon from '../../assets/images/share-48.svg';
import CloseDefault from '../../assets/images/menuNavigation/Close Default.svg';
import CloseDisable from '../../assets/images/menuNavigation/Arrow-Circle-Disabled.svg';
import { useStyles } from './ShareStyles';
import config from '../../configs/clientConfig';

export interface ISharePopup {
  report: IDownloadPdf | null;
  isOpen: boolean;
  onClose: () => void;
}

function removeDuplicateEmails(array: string[]) {
  const trimmedList = array.map(e => e.trim());
  return Array.from(new Set(trimmedList));
}

const validateEmail = (email: string): boolean => {
  const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return email
    .split(',')
    .map(e => e.trim()) // Trim whitespaces from each email
    .every(e => emailRegEx.test(e)); // Check if all emails are valid
};

const inputTypeCheck = (e) => { e.target.value = [6,7,8,9].includes(parseInt(e.target.value[0])) ? Math.max(0, parseInt(e.target.value)).toString().slice(0, 10) : null}
const disableCheck = (input1: any, input2: any, loadingState: boolean) => (!input1 && (!input2 || input2.length < 10)) || (input1 &&  input2 && input2.length < 10 ) || loadingState;
const commentsTypeCheck = (e) => { 
  if(e.target.value.match(/\</g)) { e.target.value = e.target.value.replace(/\</g, "(") } //Email body doesn't allow <
  else if (e.target.value.match(/\>/g)) { e.target.value = e.target.value.replace(/\>/g, ")") }  //Email body doesn't allow >
  e.target.value = e.target.value.replace(/\s{3,}/g, "  "); //Whatsapp body doesn't allow more than 4 consecutive white-spaces
}

const SharePopup = ({ report, isOpen, onClose }: ISharePopup) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [messageBody, setMessageBody] = useState('');
  const [message, setMessage] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState(false);
  const [hover, setHover] = useState(false);

  const customMessageLength = 500;

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  const resetForm = () => {
    setEmail('');
    setWhatsapp('');
    setMessageBody('');
    setMessage(undefined);
    setErrorStatus(false);
  };

  const callShareAPI = async (action: string, message: string) => {
    if (!report) return;

    const emailList = email.split(',').length > 1 ? email.split(',') : [email]
    const params = {
      email: (action.includes('EMAIL') || action.includes('BOTH')) ? removeDuplicateEmails(emailList) : [],
      whatsapp: (action.includes('WHATSAPP') || action.includes('BOTH')) ? parseInt(whatsapp, 10) : 0,
      data: {
        subject: `PDF for Project ${report.project}`,
        body: message || `Please find attached the report for Project ${report.project}`,
        reportType: report.reportType
      },
    };

    setLoading(true);

    try {
      const result = config.isWhatsappEnabled
        ? await report.shareBoth(params.email, params.whatsapp, params.data)
        : await report.share(params.email, params.data);

      setMessage(result?.message);
    } catch (error) {
      setErrorStatus(true);
      setMessage(error?.message?.response?.body?.errors?.[0]?.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setErrorStatus(false);
    setMessage('');

    let action = '';
    if (email && whatsapp) action = 'BOTH';
    else if (email) action = 'EMAIL';
    else if (whatsapp) action = 'WHATSAPP';

    if (!action) return;

    if ((action.includes('EMAIL') || action.includes('BOTH')) && !validateEmail(email)) {
      setErrorStatus(true);
      setMessage('Invalid email address');
      return;
    }
    callShareAPI(action, messageBody.trim());
  };

  const closeHandler = () => {
    onClose();
    resetForm();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeHandler}
      BackdropProps={{ classes: { root: classes.backDrop } }}
    >
      <form className={classes.dialogPaddings} style={{minWidth: 600}} onSubmit={handleSubmit}>
        <div className={classes.dialogTitleFlex}>
          <div className={classes.title}>Share</div>
          <img src={shareIcon} alt="share icon" height={17} style={{marginLeft: '0.5em'}}/>
          <div className={classes.closeDiv}>
            <div
              className={classes.close}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              onClick={closeHandler}
            >
              <img alt="close" src={hover ? CloseDisable : CloseDefault} />
            </div>
          </div>
        </div>
        <div className={classes.titleDescription} style={{marginTop: '0.7em'}}>
          {/* {config.isWhatsappEnabled
            ? 'Please enter at least one input'
            : 'Please enter an email address to send the report to'} */}
            Send report{report?.project ? ` for ${report?.project} ` : ' '}to :
        </div>
        <div style={{display: 'flex', flexDirection: 'column'}}>
        <span className={classes.label}>Email</span>
        <TextField
          autoFocus
          className={classes.textInput}
          type="text"
          fullWidth
          variant="standard"
          placeholder={'Enter email here (multiple allowed comma separated)'}
          value={email}
          onChange={(e) => {if(e.target.value.length === 0) { setErrorStatus(false); setMessage(''); } setEmail(e.target.value)}}
          error={errorStatus && email.length > 0 && !validateEmail(email)}
          helperText={ (errorStatus && email.length > 0 && !validateEmail(email)) ? 'Invalid email' : ''}
        />
        </div>
        {config.isWhatsappEnabled && (
          <div style={{display: 'flex', flexDirection: 'column'}}>
          <span className={classes.label}>WhatsApp number (+91)</span>
          <TextField
            className={classes.numberInput}
            type="number"
            variant="standard"
            fullWidth
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*',  onInput: (e) => { inputTypeCheck(e) } }}
            value={whatsapp}
            placeholder={'Enter mobile number here'}
            onChange={(e) => {if(e.target.value.length === 0) { setErrorStatus(false); setMessage(''); } setWhatsapp(e.target.value)}}
          />
          </div>
        )}
        <div style={{display: 'flex', flexDirection: 'column'}}>
        <span className={classes.label}>Comments / Notes</span>
        <TextareaAutosize
          aria-label="message textarea"
          placeholder={`Enter additional comments here`}
          rows={3}
          className={classes.textArea}
          maxLength={customMessageLength}
          value={messageBody}
          onChange={(e) => setMessageBody(e.target.value)}
          onInput={commentsTypeCheck}
        />
        </div>
        <span className={classes.charLeft}>
          {customMessageLength - messageBody.length} characters left
        </span>
        <div className={classes.actionStyles}>
          <div
            className={classes.messageStyle}
            style={{ color: errorStatus ? 'red' : 'green' }}
          >
            {message}
          </div>
          <Button
            className={classes.buttonStyles}
            variant="contained"
            color="primary"
            size="small"
            disabled={disableCheck(email, whatsapp, loading)}
            type={'submit'}
          >
            {loading ? 'Sharing...' : 'Share'}
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export default observer(SharePopup);
