import moment from "moment";
import DefaultFallback from "../components/DefaultFallback";
import FormDropdown from "../components/filter/FormDropdown";
import CheckboxInput from "../components/FormInputs/CheckboxInput";
import DateTimeInput from "../components/FormInputs/DateTimeInput";
import GenericInput, { IGenericInputProps } from "../components/FormInputs/GenericInput";
import Headline from "../components/FormInputs/Headline";
import MantineImageField from "../components/FormInputs/MantineImageField";
import MultiSelectDropdownInput from "../components/FormInputs/MultiSelectDropdownInput";
import RadioInput from "../components/FormInputs/RadioInput";
import { TicketStatusCategories, RequestPriorities, ActivityStatuses } from "../models/enums";
import { copyFileUrl, transformDateTime, transformNumeric, transformPercent, transformTime } from "./utils";
import MultipleImageField from "../components/FormInputs/multipleImage/MultipleImageField";
import ExpressionInput from "../components/FormInputs/ExpressionInput";


export const colors = {
    default: "#fff",
    black: "#1d1d1d",
    darkGreyBlue: "#2f3e6c",
    brownishGrey: "#686868",
    white: "#d8d8d8",
    warmGrey: "#9f9f9f",
    whiteTwo: "#efefef",
    washedOutGreen: "#b2ea79",
    sunYellow: "#ffce32",
    whiteThree: "#edebeb",
    lightBlueGrey: "#e0f7f2",
    completed: '#34d1ae',
    OnGoing: '#fff6cc',
    notStarted: '#e9e9e9',
    plainWrong: '#e90901'
};

export const borderColors = {
    completed: '#34d1ae',
    OnGoing: '#ffd300',
    notStarted: '#d1d1d1',
    plainWrong: '#e90901'
}

// Structures TCA and ECA will be taking values from structures bricks because
// of these old keys. Should we change?
export const brickPalette = {
    structures: {
        landing: {
            completed: '#34d1ae',
            started: '#ffd300',
            notStarted: '#e9e9e9',
            plainWrong: '#e90901'
        },
        fullscreen: {
            completed: '#e0f7f2',
            started: '#fff5c9',
            notStarted: '#eaeaea66',
            plainWrong: '#e90901'
        }
    },
    structures_alt: {
        landing: {
            completed: '#e0f7f2',
            started: '#ffd300',
            notStarted: '#eaeaea66',
            plainWrong: '#e90901'
        },
        fullscreen: {
            completed: '#e0f7f2',
            started: '#ffd300',
            notStarted: '#eaeaea66',
            plainWrong: '#e90901'
        }
    },
    finishing: {
        landing: {
            completed: '#e0f7f2',
            started: '#ffd300',
            notStarted: '#eaeaea66',
            plainWrong: '#e90901'
        },
        fullscreen: {
            completed: '#e0f7f2',
            started: '#ffd300',
            notStarted: '#eaeaea66',
            plainWrong: '#e90901'
        }
    },
    handover: {
        landing: {
            completed: '#e0f7f2',
            started: '#ffd300',
            notStarted: '#eaeaea66',
            plainWrong: '#e90901'
        },
        fullscreen: {
            completed: '#e0f7f2',
            started: '#ffd300',
            notStarted: '#eaeaea66',
            plainWrong: '#e90901'
        }
    },
    none: {
        landing: {
            completed: '#e0f7f2',
            started: '#ffd300',
            notStarted: '#eaeaea66',
            plainWrong: '#e90901'
        },
        fullscreen: {
            completed: '#e0f7f2',
            started: '#ffd300',
            notStarted: '#eaeaea66',
            plainWrong: '#e90901'
        }
    }
};

export const axis = {
    structures: {
        landing: {
            top: 0.15,
            bottom: 0.08,
            left: 30,
            right: 80
        },
        fullscreen: {
            top: 10,
            bottom: 10,
            left: 0,
            right: 40
        }
    },
    structures_alt: {
        landing: {
            top: 0.15,
            bottom: 0.08,
            left: 10,
            right: 10
        },
        fullscreen: {
            top: 0,
            bottom: 10,
            left: 10,
            right: 10
        }
    },
    finishing: {
        landing: {
            top: 0.15,
            bottom: 0.08,
            left: 10,
            right: 10
        },
        fullscreen: {
            top: 0,
            bottom: 10,
            left: 10,
            right: 10
        }
    },
    handover: {
        landing: {
            top: 0.15,
            bottom: 0.08,
            left: 10,
            right: 10
        },
        fullscreen: {
            top: 0,
            bottom: 10,
            left: 10,
            right: 10
        }
    },
    none: {
        landing: {
            top: 0,
            bottom: 0,
            left: 1,
            right: 1
        },
        fullscreen: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        }
    }
};

export const brick = {
    structures: {
        landing: {
            width: 128,
            height: 53
        },
        fullscreen: {
            width: 140,
            height: 120,
        }
    },
    structures_alt: {
        landing: {
            width: 100,
            height: 50
        },
        fullscreen: {
            width: 140,
            height: 54
        }
    },
    finishing: {
        landing: {
            width: 100,
            height: 50
        },
        // TODO: changed for demo only. 20, AUG - 2021
        // fullscreen: {
        //     width: 100,
        //     height: 40
        // },
        fullscreen: {
            width: 100,
            height: 54
        }
    },
    handover: {
        landing: {
            width: 100,
            height: 50
        },
        // TODO: changed for demo only. 20, AUG - 2021
        // fullscreen: {
        //     width: 100,
        //     height: 40
        // },
        fullscreen: {
            width: 100,
            height: 54
        }
    },
    none: {
        landing: { x: 1, y: 1 },
        fullscreen: { x: 1, y: 1 },
    }
};

export const scale = {
    structures: {
        landing: {
            x: 10, y: 10
        },
        fullscreen: {
            x: 15, y: 10
        }
    },
    structures_alt: {
        landing: {
            x: 8, y: 16
        },
        // TODO: changed for demo only. 20, AUG - 2021
        // fullscreen: {
        //     x: 8, y: 16
        // }
        fullscreen: {
            x: 16, y: 16
        }
    },
    finishing: {
        landing: {
            x: 8, y: 16
        },
        // TODO: changed for demo only. 20, AUG - 2021
        // fullscreen: {
        //     x: 8, y: 16
        // }
        fullscreen: {
            x: 16, y: 16
        }
    },
    handover: {
        landing: {
            x: 8, y: 16
        },
        // TODO: changed for demo only. 20, AUG - 2021
        // fullscreen: {
        //     x: 8, y: 16
        // }
        fullscreen: {
            x: 16, y: 16
        }
    },
    none: {
        landing: { x: 1, y: 1 },
        fullscreen: { x: 1, y: 1 },
    }

};

export const __hardcoded = {
    "1_planned": "notStarted",
    "3_active": "started",
    "8_done": "completed"
};

export type IHardcoded = { [P in keyof typeof __hardcoded]: typeof __hardcoded[P] };


const __opacities = {
    0: 0.01,
    25: 0.15,
    50: 0.3,
    75: 0.5
};

export const opacities = new Proxy(__opacities, {
    get: (obj: typeof __opacities, prop: string) => {
        const orig = obj[prop];
        if (orig !== undefined) { return orig; }
        else {
            if (Number.isNaN(parseInt(prop, 10))) { return 1; }
            const retIndex = Object.keys(obj).findIndex(k => !Number.isNaN(parseInt(k, 10)) && parseInt(k, 10) - parseInt(prop, 10) > 0) - 1;
            return retIndex > -1 ? obj[Object.keys(obj)[retIndex]] : 0.5;
        }
    }
});

const __materialStatusColours = {
    [TicketStatusCategories.OPEN]: { bg: "#EAEAEA", border: "#585858" },
    [TicketStatusCategories.WIP]: { bg: "#FFED99", border: "#FFD300" },
    [TicketStatusCategories.REJECTED]: { bg: "#E90901", border: "#E90901" },
    // [TicketStatusCategories.APPROVED]: { bg: "#e0f7f2", border: "#34D1AE" },
    // [TicketStatusCategories.FOR_ACCEPTANCE]: { bg: "#FFA020", border: "#FF9F00" },
    // [TicketStatusCategories.ISSUANCE]: { bg: "#e0f7f2", border: "#34D1AE" },
    [TicketStatusCategories.CLOSED]: { bg: "#2FB597", border: "#2FB597" },
    [TicketStatusCategories.ESCALATED]: { bg: "#E90901", border: "#E90901" },
    [TicketStatusCategories.INVALID]: { bg: "#FF7777", border: "#EA3434" },
    [TicketStatusCategories.READY]: { bg: "#2FB597", border: "#2FB597" },
};

export const materialStatusColours = new Proxy(__materialStatusColours, {
    get: (obj: typeof __materialStatusColours, prop: string) => {
        const orig = obj[prop];
        if (orig !== undefined) { return orig; }
        else {
            return {
                bg: "EAEAEA66", border: "#d1d1d1"
            };
        }
    }
})

const __requestPrioritiesColors = {
    [RequestPriorities.SUPER_CRITICAL]: { bg: "#990000", border: "#ff6666", color: "#FFF" },
    [RequestPriorities.CRITICAL]: { bg: "#cc0000", border: "#ff3232", color: "#FFF" },
    [RequestPriorities.L1]: { bg: "#cc0000", border: "#ff3232", color: "#FFF" },
    [RequestPriorities.URGENT]: { bg: "#e06666", border: "#ff0000", color: "#FFF" },
    [RequestPriorities.HIGH]: { bg: "#e69138", border: "#ffae19", color: "#FFF" },
    [RequestPriorities.L2]: { bg: "#e69138", border: "#ffae19", color: "#FFF" },
    [RequestPriorities.MEDIUM]: { bg: "#fff2cc", border: "#cccc00", color: "#000" },
    [RequestPriorities.L3]: { bg: "#fff2cc", border: "#cccc00", color: "#000" },
    [RequestPriorities.NORMAL]: { bg: "#d8e7f5", border: "#8bb9e3", color: "#000" },
    [RequestPriorities.L4]: { bg: "#d8e7f5", border: "#8bb9e3", color: "#000" },
    [RequestPriorities.LOW]: { bg: "#fafafa", border: "#a6a6a6", color: "#000" },
    [RequestPriorities.L5]: { bg: "#fafafa", border: "#a6a6a6", color: "#000" },
    [RequestPriorities.NO_PRIORITY]: { bg: "#f2f2f2", border: "#d8d8d8", color: "#000" },
};

export const requestPrioritiesColors = new Proxy(__requestPrioritiesColors, {
    get: (obj: typeof __requestPrioritiesColors, prop: string) => {
        const orig = obj[prop];
        if (orig !== undefined) { return orig; }
        else {
            return {
                bg: "EAEAEA66", border: "#d1d1d1"
            };
        }
    }
})

const identity = val => val;

const __transformMap = {
    'date': { transform: (val: string) => val?.length ? moment(val).format('YYYY-MM-DD') : "", Component: DateTimeInput },
    'time': { transform: transformTime, Component: DateTimeInput },
    'datetime': { transform: transformDateTime, Component: DateTimeInput },
    'percent': { transform: transformPercent, Component: GenericInput, disableCopy: true, },
    'numeric': { Component: GenericInput, transform: transformNumeric, disableCopy: true, },
    'integer': { Component: GenericInput, transform: transformNumeric, disableCopy: true, },
    'decimal': { Component: GenericInput, transform: transformNumeric, disableCopy: true, },
    'text': { Component: GenericInput, transform: transformNumeric, disableCopy: true, },
    'multi-line-text': { Component: GenericInput, transform: transformNumeric, disableCopy: true, },
    'dropdown': { Component: FormDropdown, transform: identity, },
    // 'upload': { Component: MantineImageField, transform: (val) => val?.length ? val : null, copy: copyFileUrl, disableCopy: true, },
    'upload': { Component: MultipleImageField, transform: (val) => val?.length ? val : null, copy: copyFileUrl, disableCopy: true, },
    'headline': { Component: Headline, transform: identity, disableCopy: true, },
    'headline-with-line': { Component: Headline, transform: identity, disableCopy: true, },
    'boolean': { Component: CheckboxInput, transform: identity, disableCopy: true, },
    'radio-buttons': { Component: RadioInput, transform: identity },
    'multiselect': { Component: MultiSelectDropdownInput, transform: identity, },
    'expression': { Component: ExpressionInput, transform: identity, },
    /* 'photo': { transfo rm: transformPhoto, Component: ImagePicker } */
};

export const transformMap = (prop: string) => {
    const orig = Reflect.get(__transformMap, prop);
    if (orig !== undefined) {
        return orig;
    }
    return { Component: DefaultFallback, transform: transformNumeric };
};

export const transformCopy = new Proxy({}, {
    get(target, property) {
        if (!Reflect.has(target, property)) {
            const orig = __transformMap[property];
            const fn = orig === undefined ? identity : (orig.copy || orig.transform || identity);
            // Reflect.set(target, property, new Proxy({}, { get(_, p) { return Reflect.apply(fn, undefined, property === "upload" ? (p as string).split("|version=") : [p]); } }));
            Reflect.set(target, property, new Proxy({}, { get(_, p) { return Reflect.apply(fn, undefined, [p]); } }));
        }
        return Reflect.get(target, property);
    },
});

Object.keys(__transformMap).map(k => transformCopy[k]);

export const reverseTransform = {
    'date': (val: string) => val.length ? moment(val).format("YYYY-MM-DD") : "",
    'time': (val: string) => val.length ? moment(val).format("HH:mm") : "",
    'datetime': (val: string) => val.length ? moment(val).format("YYYY-MM-DD[T]HH:mm") : ""
};

export const reverseTransformCompleted = {
    'date': (val: string) => val?.length ? moment(val, "DD-MM-YYYY").format("YYYY-MM-DD") : "",
    'time': (val: string) => val?.length ? moment(val).format("HH:mm") : "",
    'datetime': (val: string) => val?.length ? moment(val).format("YYYY-MM-DD[T]HH:mm") : ""
};

export const activityStatuses = {
    CONFIGURED: "Configured",
    PLANNED: "Planned",
    HOLD: "Hold",
    ACTIVE: "Active",
    DONE: "Done",
    ARCHIVED: "Archived",
};

const __activityStatusColours = {
    [ActivityStatuses.CONFIGURED]: { bg: "#EAEAEA", border: "#585858" },
    [ActivityStatuses.PLANNED]: { bg: "#EAEAEA", border: "#585858" },
    [ActivityStatuses.ACTIVE]: { bg: "#FFED99", border: "#FFD300" },
    // [ActivityStatuses.REJECTED]: { bg: "#E90901", border: "#E90901" },
    // [ActivityStatuses.APPROVED]: { bg: "#e0f7f2", border: "#34D1AE" },
    // [ActivityStatuses.FOR_ACCEPTANCE]: { bg: "#FFA020", border: "#FF9F00" },
    // [ActivityStatuses.ISSUANCE]: { bg: "#e0f7f2", border: "#34D1AE" },
    [ActivityStatuses.DONE]: { bg: "#2FB597", border: "#2FB597" },
    [ActivityStatuses.HOLD]: { bg: "#FF7777", border: "#EA3434" },
};

export const activityStatusColours = new Proxy(__activityStatusColours, {
    get: (obj: typeof __activityStatusColours, prop: string) => {
        const orig = obj[prop];
        if (orig !== undefined) { return orig; }
        else {
            return {
                bg: "EAEAEA66", border: "#d1d1d1"
            };
        }
    }
});
