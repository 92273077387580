import React, { memo, Dispatch, ReducerAction } from 'react';
import { DatePickerInput, DateTimePicker } from '@mantine/dates';

export interface IDateTimeInputProps {
  field: string;
  dispatch: Dispatch<ReducerAction<any>>;
  state: string;
  type: string;
  label: string;
  disabled?: boolean;
  required?: boolean;
  description?: string;
  size?: string;
  isDueDate?: boolean;
  icon?: JSX.Element;
  coreField?: boolean;
  params?: {[K: string] : any} | null | undefined
  closePopover?: () => void;
  setAutoFocus? : boolean;
};

const calculateMinDate = ({ state, isDueDate }: { state: string, isDueDate: boolean }): Date | undefined => {
  if (state && isDueDate) {
    if (new Date(state).getTime() >= new Date().getTime()) {
      return new Date()
    }
    return new Date(state)
  }
  else if (!state && isDueDate) { return new Date() }
  return undefined
}

const convertTo24Hour = (time: string): [number, number] => {
  const [timePart, meridiem] = time.toLowerCase().split(' ');
  const [hours, minutes] = timePart.split(':').map(Number);

  let convertedHours = hours % 12; // Convert 12 AM/PM to 0/12 respectively
  if (meridiem === 'pm') {
    convertedHours += 12;
  }
  return [convertedHours, minutes || 0];
};

const getConvertedHoursString = (time: string, format: '12h' | '24h'): string => {
  // Check if the input includes 'am' or 'pm'
  const is12HourFormat = time.toLowerCase().includes('am') || time.toLowerCase().includes('pm');

  // Split into time and meridiem (if present)
  const [timePart, meridiem] = time.toLowerCase().split(' ');
  const [hours, minutes = '00'] = timePart.split(':').map(Number);

  let convertedHours: number;
  let finalMeridiem: string;

  if (is12HourFormat) {
    // Handle 12-hour format
    convertedHours = hours % 12; // Convert 12 AM/PM to 0/12
    if (meridiem === 'pm') {
      convertedHours += 12;
    }
    finalMeridiem = meridiem; // Preserve the original meridiem
  } else {
    // Handle 24-hour format
    convertedHours = hours;
    finalMeridiem = hours >= 12 ? 'pm' : 'am'; // Derive meridiem from the 24-hour time
  }

  if (format === '24h') {
    // Convert to 24-hour format
    const displayHours = convertedHours.toString().padStart(2, '0'); // Ensure two-digit hours
    const displayMinutes = minutes.toString().padStart(2, '0'); // Ensure two-digit minutes
    return `${displayHours}:${displayMinutes}`;
  } else {
  // Convert back to 12-hour format if needed
  const displayHours = convertedHours % 12 || 12; // Convert 0 to 12 for AM/PM display
  const displayMinutes = minutes.toString().padStart(2, '0'); // Pad single-digit minutes with a leading zero
  return `${displayHours}:${displayMinutes} ${finalMeridiem}`;
  }
};

const DateTimeInput = ({ field, dispatch, state, type, label, disabled, required, description, size, isDueDate = false, icon, coreField, params, closePopover, setAutoFocus }: IDateTimeInputProps) => {
  const minDate = params?.onlyFutureDates ? new Date() : undefined; // Set minDate as today if onFutureDates is true
  const timeFormat = params?.displayFormat === '12h' ? 'DD/MM/YYYY hh:mm A' : 'DD/MM/YYYY HH:mm'; // Format for time display (12h / 24h)
  // const ref=React.useRef<HTMLInputElement>()
  const pickerRef = React.useRef<HTMLButtonElement | null>(null);

  React.useEffect(() => {
    if (setAutoFocus && pickerRef.current) {
      setTimeout(() => {
        pickerRef.current?.click(); // Opens the DateTimePicker programmatically
      }, 100);
    }
  }, [setAutoFocus]);

  // React.useEffect(() => {
  //   // Validate and set the default value only if not already set
  //   if (!state || !state.length) {
  //     const validatedDate = validateMinAndMaxTime(new Date(), params?.minTime, params?.maxTime, params?.displayFormat || '24h') || new Date();
  //     dispatch({ type: 'REPLACE_DATE_TIME', payload: { field, type, input: validatedDate?.toISOString() } });
  //   }
  // }, [params?.minTime, params?.maxTime, params?.displayFormat, state, dispatch]);
  
  const validateMinAndMaxTime = (date: Date, minTime: string | undefined, maxTime: string | undefined, format: string = '24h'): Date => {
    
    /*
     - If both minTime and maxTime params exist, 
     - check if the date lies between min and max hours, if not set to min hours of the day.
    */
    if(minTime && maxTime) {
      const [minTimeHours, minTimeMinutes] =  format === '12h' ? convertTo24Hour(minTime) : minTime.split(':').map(Number);
      const [maxTimeHours, maxTimeMinutes] =  format === '12h' ? convertTo24Hour(maxTime) :  maxTime.split(':').map(Number);
      const hour = date.getHours();
      const currentMinutes = date.getMinutes();
      const isBeforeMin = hour < minTimeHours || (hour === minTimeHours && currentMinutes < minTimeMinutes);
      const isAfterMax = hour > maxTimeHours || (hour === maxTimeHours && currentMinutes > maxTimeMinutes);

      if (isBeforeMin) {
        date.setHours(minTimeHours, minTimeMinutes || 0, 0, 0);
      } else if (isAfterMax) {
        date.setHours(maxTimeHours, maxTimeMinutes || 0, 0, 0);
      }

      return date;
    }
    
    /*
     - If only min time param exists, check if the hour of the day is less than the allowed min time.
     - If true, set hour to allowed min time hours.
     - If minutes present, set minutes or set default to 0th minute of the hour. 
     */
    if(minTime) {
      const [minTimeHours, minTimeMinutes] = format === '12h' ? convertTo24Hour(minTime) : minTime.split(':').map(Number);
      const hour = date.getHours();
      const currentMinutes = date.getMinutes();
      const isBeforeMin = hour < minTimeHours || (hour === minTimeHours && currentMinutes < minTimeMinutes);

      if (isBeforeMin) {
        date.setHours(minTimeHours, minTimeMinutes || 0, 0, 0);
      }

      return date;
    }
    
    /*
     - If only max time param exists, check if the hour of the day is more than the allowed max time.
     - If true, set hour to allowed max time hours.
     - If minutes present, set minutes or set default to 0th minute of the hour.
    */
    if(maxTime) {
      const [maxTimeHours, maxTimeMinutes] = format === '12h' ? convertTo24Hour(maxTime) : maxTime.split(':').map(Number);
      const hour = date.getHours();
      const currentMinutes = date.getMinutes();
      const isAfterMax = hour > maxTimeHours || (hour === maxTimeHours && currentMinutes > maxTimeMinutes);

      if (isAfterMax) {
        date.setHours(maxTimeHours, maxTimeMinutes || 0, 0, 0);
      }

      return date;
    }
    return new Date(); // If no minTime or maxTime is provided
  };

  if (type == "datetime") {
    //TODO: Revert prop value to defaultValue if you face any bugs.
    // const validDate = validateMinAndMaxTime(new Date(), params?.minTime, params?.maxTime, params?.displayFormat);
    // dispatch({ type: 'REPLACE_DATE_TIME', payload: { field, type, input: validDate?.toISOString() } });
    return (
      <DateTimePicker
        ref={pickerRef} // Attach the ref here
        label={label}
        translate={"Pick a date and time"}
        placeholder='Pick a date and time'
        description={(params?.minTime && params?.maxTime) ? `Min time: ${getConvertedHoursString(params?.minTime, params?.displayFormat)}, Max time: ${getConvertedHoursString(params?.maxTime, params?.displayFormat)}` : params?.minTime ? `Min time: ${getConvertedHoursString(params?.minTime, params?.displayFormat)}, Max time: ${getConvertedHoursString('23:59', params?.displayFormat)}` : params?.maxTime ? `Min time: ${getConvertedHoursString('00:00', params?.displayFormat)}, Max time: ${getConvertedHoursString(params?.maxTime, params?.displayFormat)}` :null}
        disabled={disabled}
        required={required}
        minDate={minDate}
        // defaultValue={validateMinAndMaxTime(new Date(), params?.minTime, params?.maxTime, params?.displayFormat)}
        onChange={(event) => {
          // dispatch({ type: 'REPLACE_DATE_TIME', payload: { field, type, input: event?.toISOString() } });
          const validDate = validateMinAndMaxTime(event!, params?.minTime, params?.maxTime, params?.displayFormat);
          dispatch({ type: 'REPLACE_DATE_TIME', payload: { field, type, input: validDate?.toISOString() } });
          if(closePopover) closePopover();
        }}
        value={state && state.length ? new Date(state) : undefined}
        size={size || 'sm'}
        sx={{
          ["& .mantine-DateTimePicker-label"]: { fontSize: coreField ? '0.7em' : '0.8525em', color: '#585858', fontWeight: coreField ? 500 : 600 },
          ["& .mantine-DateTimePicker-input"]: { marginTop: coreField ? '-4px' : '0.5em', marginBottom: coreField ? 0 : '0.5em', borderBottom: '0.0525em solid #005eff', borderTop: 0, borderRight: 0, borderLeft: 0, borderRadius: 0, paddingRight: coreField ? '1.8em' : '1.8575rem', '&[data-disabled]': { backgroundColor: '#FFF', border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' }, '&[data-with-icon]': { paddingLeft: coreField ? '1.8em' : '1.8575rem' } },
          ["& .mantine-DateTimePickerInput-icon"]: { justifyContent: coreField ? 'flex-start' : 'center' },
        }}
        popoverProps={{ position: 'bottom' }}
        icon={icon}
        autoFocus={setAutoFocus}
        valueFormat={timeFormat}
      />)
  }
  else {
    return (
      <DatePickerInput
        ref={pickerRef} // Attach the ref here
        label={label}
        placeholder={"Pick a date"}
        disabled={disabled}
        required={required}
        minDate={calculateMinDate({ state, isDueDate })}
        onChange={(event) => {
          dispatch({ type: 'REPLACE_DATE_TIME', payload: { field, type, input: event?.toISOString() || "" } });
          if(closePopover) closePopover();
        }}
        value={state && state.length ? new Date(state) : null} // Changed from undefined to null to ensure the clear icon functionality works as expected.
        size={size || 'sm'}
        sx={{
          ["& .mantine-DatePickerInput-label"]: { fontSize: coreField ? '0.7em' : '0.8525em', color: '#585858', fontWeight: coreField ? 500 : 600 },
          ["& .mantine-DatePickerInput-input"]: { marginTop: coreField ? '-4px' : '0.5em', marginBottom: coreField ? 0 : '0.5em', borderBottom: '0.0525em solid #005eff', borderTop: 0, borderRight: 0, borderLeft: 0, borderRadius: 0, paddingRight: coreField ? '1.8em' : '1.8575rem', '&[data-disabled]': { backgroundColor: '#FFF', border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' }, '&[data-with-icon]': { paddingLeft: coreField ? '1.8em' : '1.8575rem' } },
          ["& .mantine-DatePickerInput-icon"]: { justifyContent: coreField ? 'flex-start' : 'center' },
        }}
        popoverProps={{ position: 'bottom' }}
        icon={icon}
        autoFocus={setAutoFocus}
        clearable
      />
    )
  }
};

export default memo(DateTimeInput);
