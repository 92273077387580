import { MRT_Row } from 'mantine-react-table';
import { types, flow, getRoot, Instance, applySnapshot, getSnapshot, destroy, resolveIdentifier, applyPatch, SnapshotIn } from 'mobx-state-tree';
import { commonFetch, createUnit as createUnitApi, createUnitActivity as createUnitActivityAPI, getFeatureConfig2, getDropdownOption } from '../api/transactionServer';
import { UnitActivityDetails } from '../pages/planningModulePage/gridColumns';
import { activityStatuses } from '../utils/constants';
import { LateStoreModel } from './DataStore';
import { ActivityStatuses, CommonApiEntities, getEntityDropdownType, } from './enums';
import moment from 'moment';
import { notifications } from '@mantine/notifications';


const __dispatcher = (val) => {
    switch (val.type) {
        case CommonApiEntities.ACTIVITY_TYPES: return ActivityDropdown;
        case CommonApiEntities.UNITS: return UnitDropdown;
        case CommonApiEntities.FLOORS: return FloorDropdown;
        default: return GeneralDropdown;
    }
};

export interface IGeneral {
    id: string;
    name: string;
};

export interface IActivityType extends IGeneral {
    unitTypes?: string[];
};

export interface IFloor {
    id: number;
    name: string;
    blockId: string;
};

// export interface IUnit extends IGeneral {
//     unitType?: string;
//     blockId?: string;
// };

const GeneralDropdown = types.model({
    id: types.identifier,
    type: "general",
    options: types.array(types.frozen<IGeneral>()),
}).views(self => ({
    get optionsMap() {
        return self.options.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {});
    }
}));
const ActivityDropdown = types.model({
    id: types.identifier,
    type: CommonApiEntities.ACTIVITY_TYPES,
    options: types.array(types.frozen<IActivityType>()),
}).views(self => ({
    get optionsMap() {
        return self.options.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {});
    }
}));
const FloorDropdown = types.model({
    id: types.identifier,
    type: CommonApiEntities.FLOORS,
    options: types.array(types.frozen<IFloor>()),
}).views(self => ({
    get optionsMap() {
        return self.options.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {});
    },
    get floorsByBlock() {
        return self.options.reduce((acc, obj) => ({
            ...acc,
            [obj.blockId]: [...(acc[obj.blockId] || []), { id: obj.id, name: obj.name }]
        }), {});
    },
})).views(self => ({
    getFloorsForBlock(id: string) {
        console.log("i was called", self.floorsByBlock, id);
        return self.floorsByBlock[id] || [];
    },
}));

const Unit = types.model({
    id: types.identifier,
    name: types.string,
    unitType: types.string,
    blockId: types.string,
    floorIdx: types.number,
    // floorLabel: types.string,
    // isEditing: false,
}).views(self => ({
    get blockName() {
        return (getRoot(self) as Instance<typeof LateStoreModel>).planning.dropdowns.get('blocks')?.optionsMap[self.blockId]?.name || self.blockId;
    },
    get unitTypeName() {
        return (getRoot(self) as Instance<typeof LateStoreModel>).planning.dropdowns.get('unitTypes')?.optionsMap[self.unitType]?.name || self.unitType;
    },
    get floorLabel() {
        return (getRoot(self) as Instance<typeof LateStoreModel>).planning.dropdowns.get('floors')?.optionsMap[self.floorIdx]?.name || "NA";
    },
})).views(self => ({
    get canEdit() {
        //TODO: eventually put your permissions logic here.
        return true;
    }
}));

/*
  .actions(self => ({
  setEditingMode(val?: boolean) {
  if (!!val !== self.isEditing) {
  self.isEditing = !!val;
  }
  },
  }));
*/

const UnitDropdown = types.model({
    id: types.identifier,
    type: CommonApiEntities.UNITS,
    // options: types.array(types.frozen<IUnit>()),
    options: types.array(Unit),
}).views(self => ({
    get optionsMap() {
        return self.options.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {});
    },
})).actions(self => ({
    addUnit(val: SnapshotIn<typeof Unit>) {
        self.options.unshift(val);
    },

}));


export const Dropdown = types.union({ dispatcher: __dispatcher }, GeneralDropdown, ActivityDropdown, UnitDropdown, FloorDropdown);

const __canEdit = ['planStart', 'planEnd', 'title', 'progress','plannedTotalQuantity'];
const __canCreate = ['unitId', 'activityTypeId', 'planStart', 'planEnd', 'progress', 'title', 'statusId','plannedTotalQuantity'];

const sortBySyncCTFunc = (a: any, b: any) => { return moment(b.syncCt).valueOf() - moment(a.syncCt).valueOf(); };

const __stubUnitActivity = {
    title: "",
    blockId: "",
    unitId: "",
    activityTypeId: "",
    statusId: "",
    unitTypeId: "",
};
export const UnitActivity = types.model({
    id: types.identifier,
    title: types.optional(types.string, ""),
    blockId: types.string,
    // blockName: types.string,
    unitId: types.string,
    // unitTitle: types.string,
    activityTypeId: types.string,
    // activityTypeTitle: types.string,
    planStart: types.maybeNull(types.string),
    planEnd: types.maybeNull(types.string),
    statusId: types.string,
    // statusName: types.optional(types.string, "Status"),
    // TODO: maybe number?
    progress: (types.optional, types.number, 0),
    // progress: (types.optional, types.string, "0"),
    unitTypeId: types.string,
    isEditing: false,
    // unitTypeName: types.string,
    plannedTotalQuantity: types.maybeNull(types.number)
}).volatile((_) => ({
    editedFields: {},
})).views(self => ({
    get blockName() {
        return (getRoot(self) as Instance<typeof LateStoreModel>).planning.dropdowns.get('blocks')?.optionsMap[self.blockId]?.name || self.blockId;
    },
    get unitTitle() {
        return (getRoot(self) as Instance<typeof LateStoreModel>).planning.dropdowns.get('units')?.optionsMap[self.unitId]?.name || self.unitId;
    },
    get activityTypeTitle() {
        return (getRoot(self) as Instance<typeof LateStoreModel>).planning.dropdowns.get('activityTypes')?.optionsMap[self.activityTypeId]?.name || self.activityTypeId;
    },
    get unitTypeName() {
        return (getRoot(self) as Instance<typeof LateStoreModel>).planning.dropdowns.get('unitTypes')?.optionsMap[self.unitTypeId]?.name || self.unitTypeId;
    },
    get statusName() {
        //TODO: Implement
        return activityStatuses[self.statusId] || "Invalid";
    },
    get canSubmit() {
        return !!self.unitId.length && !!self.activityTypeId.length && !!self.plannedTotalQuantity && self.plannedTotalQuantity >= 0;
    },
    get propertyId() {
        return (getRoot(self) as Instance<typeof LateStoreModel>).planning.dropdowns.get('blocks')?.optionsMap[self.blockId]?.propertyId;
    },
})).views(self => ({
    get canEdit() {
        //TODO: eventually put your permissions logic here.
        return true;
    }
})).actions(self => ({
    updateUnitActivity(sn: { [K: string]: any; }) {
    },
    setEditingMode(val?: boolean) {
        if (!!val !== self.isEditing) {
            self.isEditing = !!val;
        }
    },
})).actions(self => ({
    revert() {
        self.setEditingMode(false);
        // self.updateUnitActivity(self.editedFields);
        applySnapshot(self, { ...getSnapshot(self), ...self.editedFields });
        self.editedFields = {};
    },
    edit(field: string, value: any, createFlag?: boolean) {
        // console.log('the if ',self.isEditing && ((!!createFlag && __canCreate.includes(field)) || __canEdit.includes(field)), field, value)
        if (self.isEditing && ((!!createFlag && __canCreate.includes(field)) || __canEdit.includes(field))) {
            if (self.editedFields[field] === undefined) {
                self.editedFields[field] = self[field];
            }
            applyPatch(self, { op: "replace", path: `/${field}`, value });
            if (field === "unitId") {
                const { blockId, unitType } = (getRoot(self) as Instance<typeof LateStoreModel>).planning.dropdowns.get('units')?.optionsMap[value] || {};
                if (blockId) {
                    // if (self.editedFields["blockId"] === undefined) {
                    //     self.editedFields["blockId"] = self["blockId"];
                    // }
                    applyPatch(self, { op: "replace", path: `/blockId`, value: blockId });
                }
                if (unitType) {
                    // if (self.editedFields["unitTypeId"] === undefined) {
                    //     self.editedFields["unitTypeId"] = self["unitTypeId"];
                    // }
                    applyPatch(self, { op: "replace", path: `/unitTypeId`, value: unitType });
                }
            }
        }
    },
    startActivity: flow(function* startActivity() {
        if (!self.isEditing && (self.statusId === ActivityStatuses.PLANNED || self.statusId === ActivityStatuses.CONFIGURED)) {
            try {
                //TODO: Replace API call here
                yield Promise.resolve();
                applyPatch(self, { op: "replace", path: `/statusId`, value: ActivityStatuses.ACTIVE });
            }
            catch (err) { console.error(err); }
        }
    }),
})).actions(self => ({
    update: flow(function* update() {
        // (getRoot(self) as Instance<typeof LateStoreModel>).planningModule.patchRow(self, sn);
        self.setEditingMode(false);
        try {
            yield Promise.resolve();
            self.editedFields = {};
        }
        catch (err) {
            console.error(err);
            self.revert();
            //Revert patch.
        }
    }),
}));


// const __fieldConfig = Object.keys(UnitActivity.properties).reduce((acc, k) => ({
//     ...acc,
//     [k]: {
//         canEdit: __canEdit.includes(k),
//         canCreate: __canCreate.includes(k)
//     },
// }), {});

const UnitCreate = types.model({
    id: types.optional(types.string, ""),
    name: types.optional(types.string, ""),
    blockId: types.optional(types.string, ""),
    // floorIdx: types.optional(types.number, -10000),
    floorIdx: types.maybeNull(types.number),
    // floorName: types.optional(types.string, "NA"),
    unitTypeId: types.optional(types.string, ""),
}).views(self => ({
    get canSubmit() {
        return !!self.id.length && !!self.name.length && !!self.blockId.length && !!self.floorIdx && !!self.unitTypeId.length;
    },
    get floors() {
        return (getRoot(self) as Instance<typeof LateStoreModel>).planning.dropdowns.get('floors')?.getFloorsForBlock(self.blockId);
    },
    get unitSnapshot(): SnapshotIn<typeof Unit> {
        return {
            id: self.id,
            name: self.name,
            blockId: self.blockId,
            floorIdx: self.floorIdx || -10000,
            // floorLabel: self.floorName,
            unitType: self.unitTypeId,
        };
    }
})).actions(self => ({
    edit(field: string, val: string | number) {
        if (field === "blockId" && val !== self[field]) {
            self.floorIdx = null;
        }
        self[field] = val;
    }
}));

const planningModes = types.enumeration(["read", "create", "edit"]);
//TODO: refactor into its own observable or something later
const unitGridKeys = ["id", "name", "blockId", "blockName", "unitTypeId", "unitTypeName", "floorLabel", "floorIdx"];

export const PlanningModule = types.model({
    loading: false,
    dropdowns: types.map(Dropdown),
    unitActivities: types.array(UnitActivity),
    dropdownFields: types.array(types.string),
    gridKeys: types.array(types.string),
    filteredRowsCount: types.optional(types.number, 0),
    mode: types.optional(planningModes, "read"),
    createdRow: types.maybeNull(UnitActivity),
    currentlyEditing: types.safeReference(UnitActivity),
    createUnitLoading: false,
    createUnitPopupIsOpen: false,
    newUnit: types.maybeNull(UnitCreate),
    currentView: types.optional(types.enumeration(["unitActivities", "units"]), "units"),
    hideUnitActivitiesFlag: false,
    features: types.frozen<{ [K: string]: any; }>({})
}).views(self => ({
    get projectId() {
        // TODO: remove harcoded projectId.
        // return "project_1";
        return (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo.currentProject.id;
    },
})).views(self => ({
    get canCreate() {
        //TODO: Implement Permissions here.
        return true;
    },
    get unitActivitiesGrid() {
        if (self.currentView === "units") {
            if (!self.dropdowns.get("units")?.options.length) { return []; }
            return self.dropdowns.get("units")!.options.map(unit => (
                unitGridKeys.reduce((acc, k) => ({
                    ...acc,
                    [k]: unit[k],
                }), {})
            ));
        }
        if (!(self.gridKeys.length && self.unitActivities.length)) { return []; }
        return self.unitActivities.map(ticket =>
            self.gridKeys.reduce((acc, k) => ({
                ...acc,
                [k]: Array.isArray(ticket[k]) ? [...ticket[k]] : ticket[k]
            }), { unitActivity: ticket }));
    },
})).actions(self => ({
    setDropdown(id, type, options) {
        self.dropdowns.set(id, { id, type, options });
    },
    setDropdownFields(val: string[]) {
        self.dropdownFields.replace(val);
    },
    addGridKeys(val: string[]) {
        self.gridKeys.push(...val);
    },
    setFilterRowsCount(val: number) {
        self.filteredRowsCount = val;
    },
    setNewUnit() {
        self.newUnit = UnitCreate.create({});
    },
    unsetUnit() {
        const des = self.newUnit;
        if (des) {
            self.newUnit = null;
            destroy(des);
        }
    },
    setCurrentView(val: 'units' | 'unitActivities') {
        self.currentView = val;
    },
    setMode(mode: string = "read", { id, row, action }: { id?: string; row?: IUnitActivity; action?: "save" | "cancel" | "copy" }) {
        let unitActivity: IUnitActivity | undefined;
        let unit: IUnitCreate | undefined;
        const unitsFlag = self.currentView === "units";
        if (self.mode === mode) { return; }
        switch (mode) {
            case "edit": if (unitsFlag) {
                // Don't bother with edit for now
                break;
            }
                if (id) {
                    unitActivity = resolveIdentifier(UnitActivity, self.unitActivities, id || "");
                    self.currentlyEditing = unitActivity;
                    unitActivity?.setEditingMode(true);
                }
                break;
            case "create":
                if (unitsFlag) {
                    //TODO: worry about copying rows
                    if (id) {
                        self.newUnit = UnitCreate.create({ id });
                    }
                    break;
                }
                if (action === "copy" && row) {
                    self.createdRow = UnitActivity.create({ ...row, isEditing: true });
                }
                else if (id) {
                    self.createdRow = UnitActivity.create({ ...__stubUnitActivity, id, isEditing: true });
                }
                self.currentlyEditing = self.createdRow || undefined;
                break;
            case "read": if (self.mode === "create") {
                if (unitsFlag) {
                    unit = self.newUnit || undefined;
                    self.newUnit = null;;
                    destroy(unit);
                }
                else {
                    unitActivity = self.createdRow || undefined;
                    self.createdRow = null;
                    destroy(unitActivity);
                }
            } else {
                if (action === "save") { self.currentlyEditing?.update(); }
                else if (action === "cancel") { self.currentlyEditing?.revert(); }
                self.currentlyEditing = undefined;
            }
                break;
            default: return;
        }
        self.mode = mode;
    },
})).actions(self => ({
    getDropdownValue: flow(function* getDropdownValue(id, type) {
        try {
            self.dropdowns.get(id)?.options.clear();
            const { data } = yield commonFetch(id === CommonApiEntities.FLOORS ? { projectId: self.projectId, entity: CommonApiEntities.UNITS, filters: { unitType: "floor" } } : { projectId: self.projectId, entity: id });
            if (id === "floors") {
                self.setDropdown(id, type, data.map(obj => ({ id: obj.floorIdx, name: obj.floorLabel, blockId: obj.blockId })));
            }
            else if (["unitTypes", "units"].includes(id)) {
                // self.setDropdown(id, type, data.filter(obj => !["block", "floor"].includes(id === "unitTypes" ? obj.id : obj.unitType)))
                self.setDropdown(id, type, data)
            }
            else {
                self.setDropdown(id, type, data);
            }
        }
        catch (err) { console.error(err); }
    }),
    setCreateUnitPopup(val?: boolean) {
        if (self.createUnitPopupIsOpen !== !!val) {
            self.createUnitPopupIsOpen = !!val;
            if (!!val) { self.setNewUnit(); }
            else { self.unsetUnit(); }
        }
    },
})).actions(self => ({
    getDropdownValues() {
        self.dropdownFields.forEach(id => {
            const type = getEntityDropdownType(id);
            self.getDropdownValue(id, type);
        });
    },
    getUnitActivities: flow(function* getUnitActivities() {
        self.loading = true;
        self.unitActivities.clear();
        try {
            const { data } = yield commonFetch({ projectId: self.projectId, entity: CommonApiEntities.UNIT_ACTIVITIES, filters: { isDPR  : "true"} });
            const __limit = 10000;
            const list = data.length > __limit ? data.slice(0, __limit).sort(sortBySyncCTFunc) : data.sort(sortBySyncCTFunc);
            self.unitActivities.replace(list.map(({ progress, plannedTotalQuantity, ...rest }) => ({ progress: Number.parseInt(progress) === Number.NaN ? 0 : Number.parseInt(progress), plannedTotalQuantity: Number.parseInt(plannedTotalQuantity) === Number.NaN ? '-' : Number.parseInt(plannedTotalQuantity), ...rest })));
        }
        catch (err) { console.error(err); }
        finally { self.loading = false; }
    }),
    createUnit: flow(function* createUnit() {
        if (!self.newUnit || !self.newUnit.canSubmit) { 
            notifications.show({
                title: 'Information',
                message: 'Fields cannot be blank!',
                color: 'orange'
            });
            return; 
        }
        self.createUnitLoading = true;
        try {
            //TODO: Check what description is
            yield createUnitApi({
                customerId: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId,
                userId: (getRoot(self) as Instance<typeof LateStoreModel>).auth.userId,
                blockId: self.newUnit.blockId,
                unitType: self.newUnit.unitTypeId,
                title: self.newUnit.name,
                description: "lorem ipsum",
                floorIdx: self.newUnit.floorIdx!,
            });
            notifications.show({
                title: 'Success',
                message: 'New unit created!',
                color: 'green'
            })
            if (self.dropdowns.has('units')) {
                (self.dropdowns.get('units') as IUnitDropdown)!.addUnit(self.newUnit.unitSnapshot);
                const unit = self.newUnit;
                self.newUnit = null;
                destroy(unit);
                if (self.createUnitPopupIsOpen) {
                    self.createUnitPopupIsOpen = false;
                }
            }
        }
        catch (err) { console.error(err); }
        finally {
            self.createUnitLoading = false;
        }
    }),
})).actions(self => ({
    createUnitActivity: flow(function* createUnitActivity() {
        if ((!self.createdRow && (self.mode !== "create")) || !self.createdRow?.canSubmit) { 
            notifications.show({
                title: 'Information',
                message: 'Fields cannot be blank!',
                color: 'orange'
            });
            return; 
        }
        try {
            // console.log('self.createdRow', JSON.stringify(self.createdRow))
            // yield Promise.resolve();
            const { data } = yield createUnitActivityAPI({
                customerId: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId,
                userId: (getRoot(self) as Instance<typeof LateStoreModel>).auth.userId,
                // propertyId: (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo.currentProject?.properties[0]?.id,
                propertyId: self.createdRow.propertyId,
                unitId: self.createdRow!.unitId,
                activityTypeId: self.createdRow!.activityTypeId,
                titleSuffix: self.createdRow!.title,
                isDPR: true,
                plannedTotalQuantity: self.createdRow!.plannedTotalQuantity,
                planStart: self.createdRow!.planStart,
                planEnd: self.createdRow!.planEnd,
            });
            notifications.show({
                title: 'Success',
                message: 'New activity created!',
                color: 'green'
            });
            // console.log('before calling ua fetch',data)
            yield self.getUnitActivities(); 
            // const statusId = (self.createdRow?.planStart || self.createdRow?.planEnd) ? ActivityStatuses.PLANNED as string : ActivityStatuses.CONFIGURED as string;
            // self.unitActivities.unshift({ ...getSnapshot(self.createdRow!), id: data?.activityId, isEditing: false, statusId } as IUnitActivity);
            // TODO: Remove when APIs are implemented
            // const statusId = (self.createdRow?.planStart || self.createdRow?.planEnd) ? ActivityStatuses.PLANNED as string : ActivityStatuses.CONFIGURED as string;
            // self.unitActivities.unshift({ ...getSnapshot(self.createdRow!), id: data?.activityId, isEditing: false, statusId } as IUnitActivity);
        }
        catch (err) { console.error(err); }
        self.setMode("read", {});
    }),
    setFeatureConfigs: flow(function* setFeatureConfigs() {
        self.features = {}
        try {
            const { data } = yield getFeatureConfig2({
                tenant: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId,
                // feature: 'DPR',
                payload: { projectIdList: [(getRoot(self) as Instance<typeof LateStoreModel>).projectInfo?.currentProject?.id], featureName: 'DPR' }
            })

            const { data : { data : workingCalendar} } = yield getDropdownOption({ 
                customerId: (getRoot(self) as Instance<typeof LateStoreModel>).auth.customerId,
                projectId: self.projectId,
                variableType: 'workingCalender'
             })

            const currentProject = (getRoot(self) as Instance<typeof LateStoreModel>).projectInfo?.currentProject?.id;
            const dprFeaturesForCurrentProject = data[currentProject];
            
            if(dprFeaturesForCurrentProject) {
                self.features = {...dprFeaturesForCurrentProject, dayOffs: (workingCalendar ?? {})};
                localStorage.setItem('dprFeatures', JSON.stringify({...dprFeaturesForCurrentProject, dayOffs: (workingCalendar ?? {})}))
            }
        }
        catch (err) { console.error(err); }
    }),
    clearFeatures() {
        self.features = {}
    }
}));

export const processUAForCreate = ({ id, title, unitId, activityTypeId, statusId, planEnd, planStart, progress }: IUnitActivity) => ({
    id, title, unitId, activityTypeId, statusId, planEnd, planStart, progress,
});
export const processMantineRowToUA = (row: MRT_Row<UnitActivityDetails>) => row.original;

export interface IUnitActivity extends Instance<typeof UnitActivity> { };
export interface IPlanningModule extends Instance<typeof PlanningModule> { };
export interface IUnitCreate extends Instance<typeof UnitCreate> { };
interface IGeneralDropdown extends Instance<typeof GeneralDropdown> { };
interface IActivityDropdown extends Instance<typeof ActivityDropdown> { };
interface IUnitDropdown extends Instance<typeof UnitDropdown> { };
export type IDropdown = Instance<typeof Dropdown>;
export interface IUnit extends Instance<typeof Unit> { };
interface IFloorDropdown extends Instance<typeof FloorDropdown> { };
