import React, { memo, useState, Dispatch, SetStateAction } from 'react';
import { observer } from 'mobx-react-lite';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import reportStyles from './ReportsQStyles'
import { IDownload, IDownloadPdf } from '../../models/Download';
import { DownloadStatuses } from '../../models/enums';
import Share from '../Share/Share';
import refreshButton from '../../assets/images/refresh.svg'
import visibleDownload from '../../assets/images/visibleDownload.svg'
import invisibleDownload from '../../assets/images/invisibleDownload.svg'
import ready from '../../assets/images/Ready.svg'
import pending from '../../assets/images/Pending.svg'
import inVisibleShare from '../../assets/images/inVisibleShare.svg'
import visibleShare from '../../assets/images/visibleShare.svg'

export interface IReport {
  classes: Record<string, string>;
  report: IDownload;
  setReport: Dispatch<SetStateAction<IDownloadPdf | null>>;
};

export interface IReportsQ {
  data: IDownload[];
  poll(): void;
  clear(): void;
};

const useStyles = makeStyles(theme => ({
  root: { minWidth: 0 }
}));

export const Report = observer(({ classes, report, setReport }: IReport) => (
  <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", backgroundColor: "rgb(250,250,250)", whiteSpace: "nowrap", flexDirection: "column" }}>
    <div style={{ margin: "12px 0px", width: "95%" }}>
      <span style={{ padding: "0px 4px" }}>📁</span>
      <span style={{ lineBreak: "anywhere", whiteSpace: "pre-line" }}>{report.fileName}{report.extension}</span></div>
    <div style={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-around" }}>
      <img src={(report.status === DownloadStatuses.SUCCESS) || (report.status === DownloadStatuses.DONE) ? visibleDownload : invisibleDownload}
        onClick={() => { (report.status === DownloadStatuses.SUCCESS || report.status === DownloadStatuses.DONE) && report.getPdf(); }} height={11} style={{ cursor: (report.status === DownloadStatuses.SUCCESS || report.status === DownloadStatuses.DONE) ? "pointer" : "unset" }} />
      {report.type === 'pdf' &&
        <div className={classes.pdfDiv} onClick={() => { if([DownloadStatuses.DONE, DownloadStatuses.SUCCESS].includes(report.status)) setReport(report as IDownloadPdf); }} style={{ cursor: [DownloadStatuses.DONE, DownloadStatuses.SUCCESS].includes(report.status) ? "pointer" : "unset" }}>
          <img src={[DownloadStatuses.DONE, DownloadStatuses.SUCCESS].includes(report.status) ? visibleShare : inVisibleShare} height={11} />
          <span style={{ fontSize: "12px", color: ![DownloadStatuses.DONE, DownloadStatuses.SUCCESS].includes(report.status) ? "#8080805e" : "rgb(123 121 121)" }}>SHARE️</span>
        </div>
      }
      <div className={classes.statusDiv}>
        <img src={[DownloadStatuses.DONE, DownloadStatuses.SUCCESS].includes(report.status) ? ready : pending} height={11} style={{ marginRight: "2px" }} />
        <span style={{ fontSize: "12px", color: [DownloadStatuses.DONE, DownloadStatuses.SUCCESS].includes(report.status) ? "rgb(123 121 121)" : "#9e9e9e" }}> {report.status === DownloadStatuses.DONE ? "DOWNLOADED" :
          report.status === DownloadStatuses.SUCCESS ? "READY" :
            report.status
        }</span>
      </div>
    </div>
  </div >
));

const ReportsQ = memo(({ data, poll, clear }: IReportsQ) => {
  const [report, setReport] = useState<IDownloadPdf | null>(null);
  const classes = reportStyles();
  return (
    <div className={classes.root} >
      {data.length ? <><div className={classes.exportData}>
        <p>Exported data </p>
      </div>
        <div className={classes.exportedTable}>
          <Share report={report} isOpen={!!report} onClose={() => { setReport(null); }} />
          <div className={classes.refreshClearDiv}>
            <div onClick={() => { clear(); }} className={classes.refreshClearButtons}>Clear Old</div>
            <div className={classes.refreshClearButtons}>
              <img src={refreshButton} alt="refresh" height={10} style={{ marginRight: "4px" }} />
              <div onClick={() => { poll(); }}>Refresh</div></div>
          </div>
          <div style={{
            display: "flex",
            flexDirection: 'column',
            flexGrow: 0,
            flexShrink: 1,
            overflow: "scroll"
          }} >
            {data.map((rep, index) => (
              <> <Report classes={classes} key={rep.fileName} report={rep} setReport={setReport} />
                {index < data.length - 1 && <div style={{ width: "100%", height: "2px", backgroundColor: "#0b0a0a8f", margin: "10px 0px", alignSelf: "center" }}></div>} </>))}
          </div>
        </div></> : null}
    </div>
  );
});

export default ReportsQ;
