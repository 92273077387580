import { Group, Loader, Stack, Text } from '@mantine/core';
import { Dropzone } from '@mantine/dropzone';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { IBulkImport } from '../../models/Ticket';
import { notifications } from '@mantine/notifications';
import { IconPhoto, IconUpload, IconX } from '@tabler/icons-react';

const MAX_SIZE = 25 * 1024 ** 2;

const DropUploadZone = ({ model, label, caption, disabled, actionType }: { model: IBulkImport; label: string; caption: string, disabled: boolean, actionType: string }) => {
    return (
      <Stack w="100%" spacing="xs">
        <Group position="apart"
         align="center" >
          <Text
            fw={600}
            // my="0.5em"
            mb={'0.1em'}
            fz="0.8525em"
            c="#000"
            opacity="1"
          >{label}
            <span style={{ color: "red", marginLeft: "0.25em" }}>*</span>
          </Text>
          <Text
            fw={400}
            // my="0.5em"
            mb={'0.5em'}
            fz="0.6525em"
            c="#585858"
            opacity="0.8"
          >{caption}
          </Text>
        </Group>
        <Dropzone
          onDrop={(files) => {
            // console.log('accepted files', files, 'size', files[0].size, 'MAX_SIZE', MAX_SIZE);
            if (files[0].size < MAX_SIZE) { 
              if(actionType === "create") {
                model.makeBulkImportRequest(files[0])
              } else {
                model.makeBulkImportRequestForTasks(files[0])
              }
            }
          }}
          onReject={(files) => {
            console.log('rejected files', files);
            notifications.show({ title: 'Failed!', message: `Maximum file upload size is 25 MB.`, color: 'red', icon: <IconX /> })
          }}
          name={label}
          activateOnClick
          w="100%"
          styles={{
            inner: { pointerEvents: "all", cursor: disabled ? "not-allowed" : "pointer" },
            root: disabled ? {
              backgroundColor: "#eaeaea",
              borderColor: "#585858",
              cursor: 'not-allowed',
              border: '0.1em dashed #ececec',
              borderRadius: 0,
              '&:hover': {
                backgroundColor: "#eaeaea",
                color: "#888888",
                cursor: 'not-allowed',
              },
            } : {
              borderRadius: 0,
              border: '0.1em dashed #005eff',
            }
          }}
          maxSize={MAX_SIZE}
          // disabled={model.disabled}
          disabled={disabled}
        >
          {model.loading ?
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Loader size={'xs'} />
            </div> : (
              <Group position="center" spacing="xl" style={{ pointerEvents: 'none', alignSelf: 'baseline', }}>
                <Dropzone.Accept>
                  <IconUpload
                    size="1.2em"
                    stroke={1.5}
                  />
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <IconX
                    size="1.2em"
                    stroke={1.5}
                  />
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <IconPhoto size="1.2em" stroke={1.5} />
                </Dropzone.Idle>
                <div>
                  <Text size="xs" inline>
                    {disabled ? "Select a Request Type to upload data. Alternatively, you can download the template." : "Drag files here or click to select files"}
                  </Text>
                </div>
              </Group>)
          }
        </Dropzone>
      </Stack >
    );
  };

export default observer(DropUploadZone);