import React, { forwardRef } from "react";
import { Text } from "@mantine/core";

// Define the type for props
interface SelectItemProps extends React.ComponentPropsWithoutRef<"div"> {
  label: string;
  caption: string;
  disabled?: boolean;
}

// Forward ref for proper handling
const SelectItem = forwardRef<HTMLDivElement, SelectItemProps>(
  ({ label, caption, disabled, ...others }, ref) => (
    <div ref={ref} {...others}
    style={{
        opacity: disabled ? 0.5 : 1, // Reduce opacity for disabled items
        color: disabled ? "gray" : "black", // Change text color
        pointerEvents: disabled ? "none" : "auto", // Prevent interactions
        cursor: disabled ? "not-allowed" : "pointer", // Show correct cursor
        padding: "8px 12px", // Ensure proper padding
      }}
    >
      <Text size="sm" weight={400}>
        {label}
      </Text>
      <Text size="xs" color="dimmed">
        {caption}
      </Text>
    </div>
  )
);

// Provide display name for debugging
SelectItem.displayName = "SelectItem";

export default SelectItem;
