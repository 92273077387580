import React, { memo, useEffect, useRef, useMemo, Dispatch, ReducerAction } from 'react';
import { NumberInput } from '@mantine/core';
import RichTextGenericOutput from '../richText/RichTextGenericOutput';
import { IFormField } from '../../models/Ticket';

export interface IExpressionInputProps {
  dispatch: Dispatch<ReducerAction<any>>;
  state: any;
  label: string;
  type: string;
  field: string;
};

const parseValue = (val: string) => val.replace(/[,]+/g, "")
const formatValue = (value: string) => value.length ? new Intl.NumberFormat('en-IN').format(Number.parseFloat(value)) : value;
const ExpressionInput = ({ label, state, field, type, dispatch }: IExpressionInputProps) => {
  /* const currentState = useMemo(() => (field.isLiveExpression && field.calculate) ? field.calculate(state) : state[field.id], [state]); */
  const prevState = useRef<any>(state);
  useEffect(() => {
    if (type === "live") {
      if (!Number.isNaN(state) && state !== prevState.current) {
        dispatch({ type: 'REPLACE_GENERIC', payload: { input: state, type: "decimal", field } });
        prevState.current = state;
      }
    }
  }, [state]);
  if (type === "live") {
    return (
      <NumberInput
        label={label}
        stepHoldDelay={500}
        stepHoldInterval={100}
        value={Number.isNaN(Number.parseFloat(state)) ? 0 : Number.parseFloat(state)}
        disabled
        onWheel={(e) => e.currentTarget.blur()}
        sx={{
          ["& .mantine-NumberInput-label"]: { fontSize: '0.8525em', color: '#585858', fontWeight: 600, '&[data-disabled]': { backgroundColor: '#FFF', border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' } },
          ["& .mantine-NumberInput-input"]: { marginTop: '0.5em', marginBottom: '0.5em' }
        }}
        parser={parseValue}
        hideControls
        formatter={(value) => formatValue(value)}
      />
    );
  }
  return (
    <RichTextGenericOutput content={state} label="" />
  );
};
export default memo(ExpressionInput);
