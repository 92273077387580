import React, { useEffect, useReducer, useState } from 'react'
import { MultiSelect, MultiSelectValueProps, Tooltip } from '@mantine/core';
import { IChan, putAsync } from 'csp-with-ts';
import { observer } from 'mobx-react-lite';
import { IconCalendar, IconCategory, IconClock, IconFlag, IconFlagFilled, IconTag, IconUser } from '@tabler/icons-react';
import { TicketStatusCategories } from "../../../models/enums";
import DateTimeInput from '../../../components/FormInputs/DateTimeInput';
import Assignees from '../../../components/assignees/Assignees';
import useStyles, { IStatusStyle } from './CoreFieldsStyles';
import { ITicket } from '../../../models/Ticket';
import FormDropdown from '../../../components/filter/FormDropdown';
import { convertToISO } from '../../../utils/utils';
import Tag from '../../../components/requests/cells/Tag';
// import ToggleWatcher from '../../../components/toggleWatcher/ToggleWatcher';
import GenericPopover from '../../GenericPopover';
import moment from 'moment';
import arrowIcon from '../../../assets/images/menuNavigation/Arrow-default.svg';
import { requestPrioritiesColors } from '../../../utils/constants';
import { useStore } from '../../../models/ProvideModel';

/**Initial sate */
const initialState = ({ coreFields, ...ticket }) => {
    var ret = { delta: {} };
    for (const [id, { isEditable }] of Object.entries<{ [K: string]: any; }>(coreFields)) {
        if (isEditable) {
            ret[id] = ticket[id];
        }
    }
    return ret;
};

/**Reducer */
function reducer(state: { [K: string]: any; }, action: { type: string; payload: { field: string; input: string; type: string }; }) {
    return {
        ...state, [action.payload.field]: action.payload.type === "date" ? action.payload.input == "" ? action.payload.input : convertToISO(action.payload.input) : action.payload.input,
        delta: { field: action.payload.field, value: action.payload.type === "date" ? action.payload.input == "" ? action.payload.input : convertToISO(action.payload.input) : action.payload.input }
    } as { [K: string]: any; };
}
interface IRequestPopupCore {
    ticket: ITicket | undefined;
    priorities: { id: string; name: string; }[];
    coreFields: { [K: string]: any; };
    ch: IChan;
    statuses: { id: string; name: string;[K: string]: string; }[];
    tags: string[];
    userId: string;
    categories: string[];
};

const TargetDiv = ({ child, icon, classes, statusBg, prioBg, disabled }: { child: any, icon: any, classes: any, statusBg?: boolean, prioBg?: boolean, disabled: boolean }) => {
    return (
        <div
            className={`${classes.container} ${statusBg ? classes.statusBg : prioBg ? classes.prioBg : ''}`}
            style={{
                height: '24px',
                boxShadow: `${disabled ? 'none' : '0 2px 4px rgba(0, 0, 0, 0.1)'}`,
                cursor: `${disabled ? 'default' : 'pointer'}`
            }}>
            <div className={classes.icon}>{icon}</div>
            <div className={classes.child}>{child}</div>
        </div>
    )
}

function RequestPopupCore({ ticket, priorities, coreFields, ch, statuses, tags, userId, categories }: IRequestPopupCore) {
    const [state, dispatch] = useReducer(reducer, { ...ticket, coreFields }, initialState);
    const [hideCoreFields, setHideCoreFields] = useState(false)
    const statusStyle: IStatusStyle = { status: (ticket?.statusCategory as TicketStatusCategories) || TicketStatusCategories.INVALID, hideCoreFields }
    const classes = useStyles(statusStyle);
    const [minimizeCoreFields, setMinimizeCoreFields] = useState(true)
    const store = useStore()
    useEffect(() => {
        putAsync(ch, { ...state.delta });
    }, [state]);
    const showOrHideSelf = () => {
        setHideCoreFields(prev => prev = !prev)
    };

    return (
        <div className={classes.root}>
            <div className={classes.main}>
                <div className={!store.ticket?.switchedUI ? `${classes.coreItem}` : `${classes.coreItemBasis}`} style={{ display: coreFields["processStatus"].hidden ? 'none' : 'flex' }}>
                    {(coreFields["processStatus"].hidden && !coreFields["processStatus"].isMandatory) ? null :
                        <GenericPopover
                            label={coreFields['processStatus'].isEditable ? `Set ${coreFields['processStatus'].name}` : `${coreFields['processStatus'].name}`}
                            target={
                                <TargetDiv
                                    child={coreFields['processStatus'].isEditable ? `${ticket?.statusTitle?.slice(0, 1)?.toUpperCase() || ""}${ticket?.statusTitle?.slice(1) || ""}` || state['processStatus'] || (ticket && ticket.status) || "" : `${ticket?.statusTitle?.slice(0, 1)?.toUpperCase() || ""}${ticket?.statusTitle?.slice(1) || ""}`}
                                    icon={<div className={classes.status}></div>}
                                    classes={classes}
                                    statusBg={true}
                                    disabled={!coreFields['processStatus'].isEditable}
                                />
                            }
                            dropdown={
                                coreFields['processStatus'].isEditable ?
                                    <FormDropdown
                                        field={'processStatus'}
                                        dispatch={dispatch}
                                        state={state['processStatus'] || (ticket && ticket.status) || ""}
                                        label={coreFields['processStatus'].name}
                                        disabled={!coreFields['processStatus'].isEditable}
                                        options={statuses.slice(1)}
                                        description={coreFields['processStatus'].description}
                                        required={false}
                                        size={'xs'}
                                        icon={<IconFlag width={'1em'} height={'1em'} />}
                                        coreField={true}
                                    /> : null
                            }
                            disableDropdown={!coreFields['processStatus'].isEditable}
                        />

                    }
                </div>
                <div className={!store.ticket?.switchedUI ? `${classes.coreItem}` : `${classes.coreItemBasis}`} style={{ display: coreFields["priority"].hidden ? 'none' : 'flex' }}>
                    {(coreFields["priority"].hidden && !coreFields["priority"].isMandatory) ? null :
                        <GenericPopover
                            label={coreFields['priority'].name}
                            target={
                                <TargetDiv
                                    child={`${ticket?.priorityTitle?.slice(0, 1)?.toUpperCase() || ""}${ticket?.priorityTitle?.slice(1) || ""}` || state['priority'] || (ticket && ticket['priority']) || ""}
                                    icon={<IconFlagFilled width={'1em'} height={'1em'} color={requestPrioritiesColors[ticket?.priority || coreFields['priority'].name].bg} style={{ color: requestPrioritiesColors[ticket?.priority || coreFields['priority'].name].bg, borderColor: '#000' }} />}
                                    classes={classes}
                                    disabled={!coreFields['priority'].isEditable}
                                    prioBg
                                />
                            }
                            dropdown={
                                <FormDropdown
                                    field={'priority'}
                                    dispatch={dispatch}
                                    state={state['priority'] || (ticket && ticket['priority']) || ""}
                                    label={coreFields['priority'].name}
                                    disabled={!coreFields['priority'].isEditable}
                                    options={priorities}
                                    description={""}
                                    required={false}
                                    size={'xs'}
                                    icon={<IconFlag width={'1em'} height={'1em'} />}
                                    coreField={true}
                                />
                            }
                            disableDropdown={!coreFields['priority'].isEditable}
                        />
                    }
                </div>
                <div className={!store.ticket?.switchedUI ? `${classes.coreItem}` : `${classes.coreItemBasis}`} style={{ display: (coreFields["startDate"].hidden) ? 'none' : 'flex' }}>
                    {(coreFields["startDate"].hidden && !coreFields["startDate"].isMandatory) ? null :
                        <GenericPopover
                            label={coreFields['startDate'].name}
                            target={
                                <TargetDiv
                                    child={(state['startDate'] && moment(state['startDate']).format('MMM DD, YYYY')) || state['startDate'] || (ticket && ticket['startDate'] && moment(ticket['startDate']).format('MMM DD, YYYY')) || (ticket && ticket['startDate']?.toString()) || '-Not set-'}
                                    icon={<IconCalendar width={'1em'} height={'1em'} />}
                                    classes={classes}
                                    disabled={!coreFields['startDate'].isEditable}
                                />
                            }
                            dropdown={
                                <DateTimeInput
                                    field={'startDate'}
                                    dispatch={dispatch}
                                    state={state['startDate'] || (ticket && ticket['startDate']?.toString()) || ""}
                                    type='date'
                                    label={coreFields['startDate'].name}
                                    size={'xs'}
                                    isDueDate={true}
                                    disabled={!coreFields['startDate'].isEditable}
                                    icon={<IconCalendar width={'1em'} height={'1em'} />}
                                    coreField={true}
                                    setAutoFocus={true}
                                />
                            }
                            disableDropdown={!coreFields['startDate'].isEditable}
                        />
                    }
                </div>
                <div className={!store.ticket?.switchedUI ? `${classes.coreItem}` : `${classes.coreItemBasis}`} style={{ display: (coreFields["dueDate"].hidden) ? 'none' : 'flex' }}>
                    {(coreFields["dueDate"].hidden && !coreFields["dueDate"].isMandatory) ? null :
                        <GenericPopover
                            label={coreFields['dueDate'].name}
                            target={
                                <TargetDiv
                                    child={(state['dueDate'] && moment(state['dueDate']).format('MMM DD, YYYY')) || state['dueDate'] || (ticket && ticket['dueDate'] && moment(ticket['dueDate']).format('MMM DD, YYYY')) || (ticket && ticket['dueDate']?.toString()) || '-Not set-'}
                                    icon={<IconClock width={'1em'} height={'1em'} />}
                                    classes={classes}
                                    disabled={!coreFields['dueDate'].isEditable}
                                />
                            }
                            dropdown={
                                <DateTimeInput
                                    field={'dueDate'}
                                    dispatch={dispatch}
                                    state={state['dueDate'] || (ticket && ticket['dueDate']?.toString()) || ''}
                                    type='date'
                                    label={coreFields['dueDate'].name}
                                    size={'xs'}
                                    isDueDate={true}
                                    disabled={!coreFields['dueDate'].isEditable}
                                    icon={<IconClock width={'1em'} height={'1em'} />}
                                    coreField={true}
                                    setAutoFocus={true}
                                />
                            }
                            disableDropdown={!coreFields['dueDate'].isEditable}
                        />
                    }
                </div>
                <div className={!store.ticket?.switchedUI ? `${classes.coreItem}` : `${classes.coreItemBasis}`}>
                    <GenericPopover
                        label={'Assignees'}
                        target={
                            <TargetDiv
                                child={<Assignees assignees={ticket?.assignee || []} userId={userId} ticket={ticket} ch={ch} />}
                                icon={<IconUser width={'1em'} height={'1em'} />}
                                classes={classes}
                                disabled={ticket && ticket.ticketDefinition.isAdhoc ? false : true}
                            />
                        }
                        disableDropdown={true}
                        disableTooltip={true}
                        dropdown={
                            <Assignees assignees={ticket?.assignee || []} userId={userId} ticket={ticket} ch={ch} />
                        }
                    />
                </div>
                <div className={!store.ticket?.switchedUI ? `${classes.coreItem}` : `${classes.coreItemBasis}`} style={{ display: (coreFields["category"].hidden || !ticket?.category.length || minimizeCoreFields) ? 'none' : 'flex' }}>
                    {((coreFields["category"].hidden && !coreFields["category"].isMandatory) || !ticket?.category.length) ? null :
                        <GenericPopover
                            label={coreFields['category'].name}
                            target={
                                <TargetDiv
                                    child={`${ticket?.category?.slice(0, 1)?.toUpperCase() || ""}${ticket?.category?.slice(1) || ""}` || state['category'] || (ticket && ticket['category']) || ""}
                                    icon={<IconCategory width={'1em'} height={'1em'}  />}
                                    classes={classes}
                                    disabled={!coreFields['category'].isEditable}
                                    prioBg
                                />
                            }
                            dropdown={
                                <FormDropdown
                                    field={'category'}
                                    dispatch={dispatch}
                                    state={state['category'] || (ticket && ticket['category']) || ""}
                                    label={coreFields['category'].name}
                                    disabled={!coreFields['category'].isEditable}
                                    options={categories}
                                    description={""}
                                    required={false}
                                    size={'xs'}
                                    icon={<IconCategory width={'1em'} height={'1em'} />}
                                    coreField={true}
                                />
                            }
                            disableDropdown={!coreFields['priority'].isEditable}
                        />
                    }
                </div>
                {
                    <div className={!store.ticket?.switchedUI ? `${classes.coreItem}` : `${classes.coreItemBasis}`} style={{ display: minimizeCoreFields ? 'none' : 'flex' }}>
                        <GenericPopover
                            label={`${!!ticket?.canEdit ? 'Add Tags' : 'Tags'}`}
                            target={
                                <TargetDiv
                                    child={
                                        ticket?.tags.length ?
                                            (<div style={{ display: 'flex', gap: '6px', flexWrap: 'wrap' }}>
                                                {ticket?.tags.map((tag, index) => (
                                                    <ValueComponent
                                                        key={index}
                                                        label={tag}
                                                        path={ticket?.path}
                                                        canEdit={ticket?.canEdit}
                                                        onRemove={() => { }}
                                                        disabled={ticket?.canEdit ? false : true}
                                                        readOnly={!!ticket?.canEdit ? false : true}
                                                        size={'sm'}
                                                        radius={'sm'}
                                                        variant={''}
                                                    />
                                                ))}
                                            </div>)
                                            : '-No tags-'
                                    }
                                    icon={<IconTag width={'1em'} height={'1em'} />}
                                    classes={classes}
                                    disabled={!!ticket?.canEdit ? false : true}
                                />
                            }
                            dropdown={
                                !!ticket?.canEdit ?
                                    <MultiSelect
                                        data={tags.map(tag => ({
                                            label: tag,
                                            value: tag,
                                            path: ticket.path,
                                            canEdit: ticket.canEdit
                                        }))}
                                        value={ticket?.tags.slice() || []}
                                        onChange={val => {
                                            if (val.length > ticket?.tags.length) {
                                                ticket?.addTagToTicket(val.slice(-1).join(''));
                                            }
                                        }

                                        }
                                        placeholder="Add tags"
                                        nothingFound="Nothing found. Start typing to create new tag."
                                        searchable
                                        creatable
                                        getCreateLabel={(query) => `+ Create tag ${query}`}
                                        onCreate={(query) => query.replace(/\s+/g, '-')}
                                        valueComponent={ValueComponent}
                                        size={'sm'}
                                        sx={{
                                            ["& .mantine-MultiSelect-label"]: { fontSize: '0.7em', color: '#585858', fontWeight: 500 },
                                            ["& .mantine-MultiSelect-input"]: { borderBottom: '0.0525em solid #005eff', borderTop: 0, borderRight: 0, borderLeft: 0, borderRadius: 0, paddingRight: '1.8em', width: '95%', '&[data-disabled]': { backgroundColor: '#FFF', border: 0, opacity: 1, color: '#585858', cursor: 'not-allowed' }, '&[data-with-icon]': { paddingLeft: '1.8em' } },
                                            ["& .mantine-MultiSelect-icon"]: { justifyContent: 'flex-start' }
                                        }}
                                        icon={<IconTag width={'1em'} height={'1em'} />}
                                        autoFocus
                                    />
                                    :
                                    <MultiSelect
                                        data={ticket?.tags.map(tag => ({
                                            label: tag,
                                            value: tag,
                                            path: ticket.path,
                                            canEdit: ticket.canEdit
                                        })) || [{ label: 'notFound', value: `No tag linked`, path: undefined, canEdit: false }]}
                                        value={ticket?.tags.slice() || []}
                                        valueComponent={ValueComponent}
                                        size={'sm'}
                                        disabled={true}
                                        sx={{
                                            ["& .mantine-MultiSelect-label"]: { fontSize: '0.7em', color: '#585858', fontWeight: 500 },
                                            ["& .mantine-MultiSelect-input"]: { borderBottom: '0.0525em solid #005eff', borderTop: 0, borderRight: 0, borderLeft: 0, borderRadius: 0, paddingRight: '1.8em', '&[data-disabled]': { backgroundColor: '#FFF !important', border: 0, opacity: `1 !important`, color: '#585858', cursor: 'default !important' }, '&[data-with-icon]': { paddingLeft: '1.8em' } },
                                            ["& .mantine-MultiSelect-icon"]: { justifyContent: 'flex-start' }
                                        }}
                                        icon={<IconTag width={'1em'} height={'1em'} />}
                                        autoFocus
                                    />
                            }
                            disableDropdown={!(!!ticket?.canEdit)}
                        />
                    </div>
                }
                {!minimizeCoreFields ?
                    <Tooltip label={'See less'}><div className={classes.seeMore} onClick={() => setMinimizeCoreFields(prev => { if (!prev) return true; return prev })}><span style={{ color: '#005eff', fontSize: '0.65em' }}>See Less</span><img src={arrowIcon} style={{ transform: `${!minimizeCoreFields ? "rotate(-270deg)" : "rotate(270deg)"}`, }} alt={`See less`} /></div></Tooltip>
                    : <Tooltip label={'See more'}><div className={classes.seeMore} onClick={() => setMinimizeCoreFields(prev => { if (prev) return false; return prev })}><span style={{ color: '#005eff', fontSize: '0.65em' }}>See More</span><img src={arrowIcon} style={{ transform: `${!minimizeCoreFields ? "rotate(-270deg)" : "rotate(270deg)"}`, }} alt={`See more`} /></div></Tooltip>
                }
                {/* <div style={{ display: 'flex', marginTop: '1.5em', alignItems: 'center' }}> */}
                {/* <div className={classes.coreItem}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: '0.5em !important' }}>
              <span style={{ fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji', fontSize: '0.8525em', fontWeight: 600, color: '#585858', marginTop: '0.5em', marginBottom: '0.5em', letterSpacing: '0.01071em', lineHeight: 1.55 }}>Notify me</span>
              <ToggleWatcher
              toggleState={!!ticket?.watchers.includes(userId)}
              ticket={ticket!}
              />
              </div>
              </div> */}
            </div>
        </div>
    )
}

const ValueComponent = ({ label, path, canEdit, onRemove, ...others }: MultiSelectValueProps & { path: string; canEdit: boolean; }) => (
    <div onClick={e => { e.stopPropagation(); }}>
        <Tag
            tag={label}
            path={path}
            actionable={canEdit}
            onRemove={onRemove}
        ></Tag>
    </div>
);

export default observer(RequestPopupCore);
